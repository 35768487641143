import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISemptomOneriTransList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISemptomOneriTransList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SemptomOneriTransBySemptomOneriId: any[];
  SemptomOneriTransBySemptomOneriId_dummy: any[];
  SemptomOneriTransKontrol: number;
  SemptomOneriTransKontrol_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SemptomOneriTranslationDelete: number;
  SemptomOneriTranslationDelete_dummy: number;
}

export class SemptomOneriTransList_ScreenBase extends React.PureComponent<ISemptomOneriTransList_ScreenProps, any> {
  semptomoneritranslist_622426_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "03fa7cae-a56b-4a3d-8c3c-9017fe5b5a0d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 218538, PropertyName: "value", Value: "Semptom Öneri Translations" },
        { Id: 785690, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 308118, PropertyName: "label", Value: "Translation Ekle" },
        { Id: 914364, PropertyName: "value", Value: "Vücut Bölgesi :" },
        { Id: 622426, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 843165, PropertyName: "title", Value: "Dil" },
        { Id: 789855, PropertyName: "value", Value: "[datafield:lang]" },
        { Id: 237552, PropertyName: "title", Value: "Tanımlamalar" },
        { Id: 138993, PropertyName: "value", Value: "[datafield:tanimlama]" },
        { Id: 199083, PropertyName: "title", Value: "Bakım Önerileri" },
        { Id: 187072, PropertyName: "value", Value: "[datafield:bakimonerisi]" },
        { Id: 232920, PropertyName: "title", Value: "Ne Zaman Aranmalı" },
        { Id: 119677, PropertyName: "value", Value: "[datafield:nezamanaramali]" },
        { Id: 270752, PropertyName: "title", Value: "#" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.semptomoneritranslist_622426_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SemptomOneriTransBySemptomOneriId: [],
      SemptomOneriTransKontrol: 0,
      NAVIGATE: "",
      SemptomOneriTranslationDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("semptomoneritranslist", "semptomoneritranslist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SemptomOneriTransListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("semptomoneritranslist", "semptomoneritranslist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("semptomoneritranslist", "semptomoneritranslist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SemptomOneriTransListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SemptomOneriTransListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      SemptomOneriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriTransList/SemptomOneriTransListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriTransBySemptomOneriId = result?.data.semptomOneriTransBySemptomOneriId;

    formVars.semptomoneritranslist_609571_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomOneriTransBySemptomOneriId?.length > 0
        ? stateVars.SemptomOneriTransBySemptomOneriId[0]?.vucutBolgesi
        : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriTransListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriTransListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.semptomoneritranslist_609571_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransBySemptomOneriId?.length > 0
        ? this.state.SemptomOneriTransBySemptomOneriId[0]?.vucutBolgesi
        : null
    );

    stateVars.dataSource_622426 = this.state.SemptomOneriTransBySemptomOneriId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SemptomOneriTransListComponent_199167_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriTransListComponent_308118_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SemptomOneriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriTransList/SemptomOneriTransListComponent_308118_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriTransKontrol = result?.data.semptomOneriTransKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriTransListComponent_308118_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriTransListComponent_308118_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "CurrentLang", "");
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "WaitFor", "Add");
    KuikaAppManager.addToPageInputVariables(
      "SemptomOneriEkle",
      "SemptomOneriID",
      this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid
    );
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "isVBEditable", false);
    KuikaAppManager.addToPageInputVariables(
      "SemptomOneriEkle",
      "SemptomIDs",
      ReactSystemFunctions.toString(
        this,
        this.state.SemptomOneriTransBySemptomOneriId?.length > 0
          ? this.state.SemptomOneriTransBySemptomOneriId[0]?.semptomIds
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "SemptomOneriTransID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomOneriTransList",
      "SemptomOneriEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "285219",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriTransListComponent_104541_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "CurrentLang",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "WaitFor", "New");
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "SemptomOneriID",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "semptomOneriId")
      );
      KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "isVBEditable", false);
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "SemptomIDs",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "semptomIds")
      );
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "SemptomOneriTransID",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.SemptomOneriTransListComponent_104541_onClick1_,
        "SemptomOneriTransList",
        "SemptomOneriEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "372009",
        null,
        "right",
        null,
        "800px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.SemptomOneriTransListComponent_104541_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  SemptomOneriTransListComponent_104541_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "CurrentLang",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "WaitFor", "Edit");
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "SemptomOneriID",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "semptomOneriId")
      );
      KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "isVBEditable", false);
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "SemptomIDs",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "semptomIds")
      );
      KuikaAppManager.addToPageInputVariables(
        "SemptomOneriEkle",
        "SemptomOneriTransID",
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "SemptomOneriTransList",
        "SemptomOneriEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "707474",
        null,
        "right",
        null,
        "800px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SemptomOneriTransListComponent_319896_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "semptomoneritranslist_622426_value", "id"),
        "Guid"
      ),
      SemptomOneriId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ed35be09_f7fa_4417_76ab_98ca9c975a4d_confirmation",
        this.defaultML,
        "Bu translation silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "SemptomOneriTransList/SemptomOneriTransListComponent_319896_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.SemptomOneriTranslationDelete = result?.data.semptomOneriTranslationDelete;
        stateVars.SemptomOneriTransBySemptomOneriId = result?.data.semptomOneriTransBySemptomOneriId;

        formVars.semptomoneritranslist_609571_value = ReactSystemFunctions.toString(
          this,
          stateVars.SemptomOneriTransBySemptomOneriId?.length > 0
            ? stateVars.SemptomOneriTransBySemptomOneriId[0]?.vucutBolgesi
            : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SemptomOneriTransListComponent_319896_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriTransListComponent_319896_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.semptomoneritranslist_609571_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransBySemptomOneriId?.length > 0
        ? this.state.SemptomOneriTransBySemptomOneriId[0]?.vucutBolgesi
        : null
    );

    stateVars.dataSource_622426 = this.state.SemptomOneriTransBySemptomOneriId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [285219, 372009, 707474] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SemptomOneriTransListPageInit();
    }
    if (diId == 372009) {
      isErrorOccurred = await this.SemptomOneriTransListComponent_104541_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
