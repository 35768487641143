import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHastalikTranslationList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHastalikTranslationList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  HastalikTranslationByHastalikId: any[];
  HastalikTranslationByHastalikId_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  HastalikTranslationDelete: number;
  HastalikTranslationDelete_dummy: number;
}

export class HastalikTranslationList_ScreenBase extends React.PureComponent<IHastalikTranslationList_ScreenProps, any> {
  hastaliktranslationlist_755660_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "b4987f8d-5462-445e-9e96-424c653f8b45",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Hastalıklar Translation Liste" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 718191, PropertyName: "label", Value: "Translation Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 639777, PropertyName: "title", Value: "Dil" },
        { Id: 889056, PropertyName: "value", Value: "[datafield:lang]" },
        { Id: 147953, PropertyName: "title", Value: "Hastalık Adı" },
        { Id: 189965, PropertyName: "value", Value: "[datafield:hastalikad]" },
        { Id: 830114, PropertyName: "title", Value: "#" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.hastaliktranslationlist_755660_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      HastalikTranslationByHastalikId: [],
      NAVIGATE: "",
      HastalikTranslationDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hastaliktranslationlist", "hastaliktranslationlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HastalikTranslationListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hastaliktranslationlist", "hastaliktranslationlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hastaliktranslationlist", "hastaliktranslationlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HastalikTranslationListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HastalikTranslationListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      HastalikId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HastalikID ?? this.props.screenInputs.hastalikid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HastalikTranslationList/HastalikTranslationListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikTranslationByHastalikId = result?.data.hastalikTranslationByHastalikId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikTranslationListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikTranslationListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.HastalikTranslationByHastalikId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HastalikTranslationListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikTranslationListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "HastalikEkle",
      "HastalikID",
      this.props.screenInputs.HastalikID ?? this.props.screenInputs.hastalikid
    );
    KuikaAppManager.addToPageInputVariables("HastalikEkle", "HastalikTranslationID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("HastalikEkle", "WaitFor", "Add");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HastalikTranslationList",
      "HastalikEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "110873",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikTranslationListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "HastalikEkle",
      "HastalikID",
      ReactSystemFunctions.value(this, "hastaliktranslationlist_755660_value", "hastalikID")
    );
    KuikaAppManager.addToPageInputVariables(
      "HastalikEkle",
      "HastalikTranslationID",
      ReactSystemFunctions.value(this, "hastaliktranslationlist_755660_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("HastalikEkle", "WaitFor", "Edit");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HastalikTranslationList",
      "HastalikEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "940369",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikTranslationListComponent_461088_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "hastaliktranslationlist_755660_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "HastalikTranslationList/HastalikTranslationListComponent_461088_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikTranslationDelete = result?.data.hastalikTranslationDelete;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [110873, 940369] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HastalikTranslationListPageInit();
    }
  }
}
