import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { IndirimKoduList_ScreenBase } from "./indirimkodulist-base";

import { IndirimKoduForm_Screen } from "../indirimkoduform/indirimkoduform";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class IndirimKoduList_Screen extends IndirimKoduList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form form={this.props.form} name="basic" initialValues={{ indirimkodulist_416063_value: undefined }}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="indirimkodulist_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="84486"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="996029"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="334340"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="981633"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.IndirimKoduListComponent_981633_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "20pt",
                            color: "rgba(0, 0, 0, 1)"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="923160"
                        value={ReactSystemFunctions.translate(this.ml, 923160, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "13pt",
                            color: "rgba(0, 0, 0, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="indirimkodulist_body"
                style={
                  {
                    backgroundColor: "rgba(243, 245, 249, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="331958"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="44993"
                    xs={15}
                    sm={15}
                    md={15}
                    lg={15}
                    xl={15}
                    xxl={15}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="indirimkodulist_416063_value">
                      <TextInput
                        id="416063"
                        onPressEnter={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.IndirimKoduListComponent_416063_onPressEnter();
                        }}
                        onBlur={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.IndirimKoduListComponent_416063_onBlur();
                        }}
                        placeholder={ReactSystemFunctions.translate(this.ml, 416063, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        autoComplete={true}
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(255, 255, 255, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            maxWidth: "200px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="775216"
                    xs={9}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                    xxl={9}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <Button
                      id="814413"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.IndirimKoduListComponent_814413_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 814413, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 4,
                          borderTopRightRadius: 4,
                          borderBottomRightRadius: 4,
                          borderBottomLeftRadius: 4,
                          backgroundColor: "rgba(3, 88, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>
                </KRow>

                <KRow
                  id="720564"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="769800"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderBottomLeftRadius: 4,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KTable
                      id="399769"
                      kuikaRef={this.indirimkodulist_399769_value_kuikaTableRef}
                      form={this.props.form}
                      dataSource={this.state.IndirimKoduSearch}
                      size="middle"
                      bordered={true}
                      showHeader={true}
                      loading={false}
                      nodatafoundmessage={ReactSystemFunctions.translate(
                        this.ml,
                        399769,
                        "nodatafoundmessage",
                        this.defaultML
                      )}
                      sorter={false}
                      pagination={false}
                      columnChooser={false}
                      filtering={false}
                      searchable={false}
                      striped={false}
                      stripedColor="#F5F7FA"
                      insertRowActive={false}
                      insertRowPosition="top"
                      pageSize={10}
                      showSizeChanger={false}
                      resizableColumns={false}
                      multiSelect={false}
                      tableWidthMode="fit"
                      transformedOnMobileResolution={false}
                      fixedHeader={false}
                      editable={false}
                      globalSearch={false}
                      showNoDataFound={true}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KTableHeader
                        id="500856"
                        hideOnMobileResolution={false}
                        textDirection="Default"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "rgba(155, 155, 155, 1)",
                            lineHeight: "20px"
                          } as any
                        }
                      ></KTableHeader>

                      <KTableRow
                        id="489075"
                        hoverFontColor="red"
                        hoverBgColor="#F5F5F5"
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            height: "30px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KTableColumn
                          id="3046228"
                          title={ReactSystemFunctions.translate(this.ml, 3046228, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="4893075"
                            value="[datafield:kod]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="5610835"
                          title={ReactSystemFunctions.translate(this.ml, 5610835, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="8073331"
                            value="[datafield:iyzcoReferansIndirimli]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="8394979"
                          title={ReactSystemFunctions.translate(this.ml, 8394979, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="8684734"
                            value="[datafield:baslangicTarihi]"
                            formatter="datetime"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="3882377"
                          title={ReactSystemFunctions.translate(this.ml, 3882377, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="1812743"
                            value="[datafield:bitisTarihi]"
                            formatter="datetime"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="792509"
                          title={ReactSystemFunctions.translate(this.ml, 792509, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "120px",
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="893506"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <Icon
                              id="860665"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.IndirimKoduListComponent_860665_onClick();
                              }}
                              showCursorPointer
                              iconName="create"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "24px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="505853"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.IndirimKoduListComponent_505853_onClick();
                              }}
                              showCursorPointer
                              visExField1="[datafield:kod]"
                              visExField2="Default"
                              visExRule="isNotEqualTo"
                              iconName="delete"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "24px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>
                          </HorizontalStack>
                        </KTableColumn>
                      </KTableRow>
                    </KTable>

                    <KPagination
                      id="172599"
                      onChange={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.IndirimKoduListComponent_172599_onChange();
                      }}
                      kuikaRef={this.indirimkodulist_172599_value_kuikaPaginationRef}
                      total={this.state.IndirimKoduCount?.at?.(0)?.count ?? undefined}
                      pageSize={10}
                      defaultCurrent={1}
                      current={1}
                      defaultPageSize={10}
                      disabled={false}
                      hideOnSinglePage={true}
                      showLessItems={false}
                      showQuickJumper={false}
                      showSizeChanger={false}
                      showFirstPageButton={true}
                      showLastPageButton={true}
                      showNumbers={false}
                      showText={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-right",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    ></KPagination>
                  </KCol>
                </KRow>

                <KDrawer
                  id="256479"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible256479: false }, () => {
                      this.callCallbackFunction(256479);
                    });
                  }}
                  visible={this.state.NavVisible256479}
                  width="450px"
                  push={true}
                  children={
                    this.state.NavVisible256479 && (
                      <IndirimKoduForm_Screen
                        onClose={() => {
                          this.setState({ NavVisible256479: false }, () => {
                            this.callCallbackFunction(256479);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="139195"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible139195: false }, () => {
                      this.callCallbackFunction(139195);
                    });
                  }}
                  visible={this.state.NavVisible139195}
                  width="360px"
                  push={true}
                  children={
                    this.state.NavVisible139195 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible139195: false }, () => {
                            this.callCallbackFunction(139195);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="486227"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible486227: false }, () => {
                      this.callCallbackFunction(486227);
                    });
                  }}
                  visible={this.state.NavVisible486227}
                  width="450px"
                  height="vh"
                  push={true}
                  children={
                    this.state.NavVisible486227 && (
                      <IndirimKoduForm_Screen
                        onClose={() => {
                          this.setState({ NavVisible486227: false }, () => {
                            this.callCallbackFunction(486227);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(IndirimKoduList_Screen))))
  )
);
export { tmp as IndirimKoduList_Screen };
//export default tmp;
//export { tmp as IndirimKoduList_Screen };
