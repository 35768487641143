import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHastalikList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHastalikList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  HastalikTranslationSearch: any[];
  HastalikTranslationSearch_dummy: any[];
  HastalikTranslationCount: any[];
  HastalikTranslationCount_dummy: any[];
  DeleteHastalikAll: number;
  DeleteHastalikAll_dummy: number;
}

export class HastalikList_ScreenBase extends React.PureComponent<IHastalikList_ScreenProps, any> {
  hastaliklist_755660_value_kuikaTableRef: React.RefObject<any>;
  hastaliklist_984765_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "0eb12885-7989-4e84-82d0-740eaeb0c77f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Hastalıklar" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 718191, PropertyName: "label", Value: "Yeni Hastalık Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 147953, PropertyName: "title", Value: "Hastalık Adı" },
        { Id: 189965, PropertyName: "value", Value: "[datafield:hastalikad]" },
        { Id: 830114, PropertyName: "title", Value: "#" },
        { Id: 328954, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.hastaliklist_755660_value_kuikaTableRef = React.createRef();
    this.hastaliklist_984765_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      HastalikTranslationSearch: [],
      HastalikTranslationCount: [],
      DeleteHastalikAll: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hastaliklist", "hastaliklist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HastalikListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hastaliklist", "hastaliklist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hastaliklist", "hastaliklist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HastalikListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HastalikListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      keyword_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      keyword_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HastalikList/HastalikListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikTranslationSearch = result?.data.hastalikTranslationSearch;

    stateVars.HastalikTranslationCount = result?.data.hastalikTranslationCount;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.HastalikTranslationCount?.length > 0 ? stateVars.HastalikTranslationCount[0]?.hastalikSayisi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.HastalikTranslationSearch;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    formVars.hastaliklist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HastalikListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HastalikList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180570",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikListComponent_2755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      keyword_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      keyword_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HastalikList/HastalikListComponent_2755_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikTranslationSearch = result?.data.hastalikTranslationSearch;

    stateVars.HastalikTranslationCount = result?.data.hastalikTranslationCount;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.HastalikTranslationCount?.length > 0 ? stateVars.HastalikTranslationCount[0]?.hastalikSayisi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikListComponent_2755_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikListComponent_2755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.HastalikTranslationSearch;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    formVars.hastaliklist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("HastalikEkle", "HastalikID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("HastalikEkle", "HastalikTranslationID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("HastalikEkle", "WaitFor", "New");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HastalikList",
      "HastalikEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "110873",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "HastalikTranslationList",
      "HastalikID",
      ReactSystemFunctions.value(this, "hastaliklist_755660_value", "hastalikID")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HastalikList",
      "HastalikTranslationList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikListComponent_461088_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      HastalikID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "hastaliklist_755660_value", "hastalikID"),
        "Guid"
      ),
      keyword_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      keyword_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HastalikList/HastalikListComponent_461088_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteHastalikAll = result?.data.deleteHastalikAll;
    stateVars.HastalikTranslationSearch = result?.data.hastalikTranslationSearch;

    stateVars.HastalikTranslationCount = result?.data.hastalikTranslationCount;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.HastalikTranslationCount?.length > 0 ? stateVars.HastalikTranslationCount[0]?.hastalikSayisi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikListComponent_461088_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikListComponent_461088_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.HastalikTranslationSearch;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    formVars.hastaliklist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikListComponent_984765_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      keyword_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      keyword_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "hastaliklist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HastalikList/HastalikListComponent_984765_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikTranslationSearch = result?.data.hastalikTranslationSearch;

    stateVars.HastalikTranslationCount = result?.data.hastalikTranslationCount;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.HastalikTranslationCount?.length > 0 ? stateVars.HastalikTranslationCount[0]?.hastalikSayisi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikListComponent_984765_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikListComponent_984765_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.HastalikTranslationSearch;
    formVars.hastaliklist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    formVars.hastaliklist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.HastalikTranslationCount?.length > 0 ? this.state.HastalikTranslationCount[0]?.hastalikSayisi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [180570, 110873] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HastalikListPageInit();
    }
  }
}
