import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanlikAlaniEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanlikAlaniEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  UzmanlikTurSecenek: any[];
  UzmanlikTurSecenek_dummy: any[];
  UzmanlikAlaniLangByCondition: any[];
  UzmanlikAlaniLangByCondition_dummy: any[];
  UzmanlikAlaniTransByLang: any[];
  UzmanlikAlaniTransByLang_dummy: any[];
  UzmanlikAlaniTransById: any[];
  UzmanlikAlaniTransById_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  UzmanlikAlanlariSave: number;
  UzmanlikAlanlariSave_dummy: number;
  UzmanlikAlaniTransSave: number;
  UzmanlikAlaniTransSave_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  GoBack: any;
  GoBack_dummy: any;
  isComp733245Visible: "visible" | "hidden";
  isComp839981Visible: "visible" | "hidden";
  isComp730186Visible: "visible" | "hidden";
}

export class UzmanlikAlaniEkle_ScreenBase extends React.PureComponent<IUzmanlikAlaniEkle_ScreenProps, any> {
  uzmanlikalaniekle_816262_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanlikalaniekle_40879_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "42a6d220-b847-4022-b6a3-6b93e5f28362",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Uzmanlık Alanı" },
        { Id: 429915, PropertyName: "value", Value: "Uzmanlık Türü" },
        { Id: 816262, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 919052, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 404697, PropertyName: "value", Value: "Dil" },
        { Id: 40879, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 742125, PropertyName: "value", Value: "Tanımı" },
        { Id: 856375, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanlikalaniekle_816262_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanlikalaniekle_40879_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      UzmanlikTurSecenek: [],
      UzmanlikAlaniLangByCondition: [],
      UzmanlikAlaniTransByLang: [],
      UzmanlikAlaniTransById: [],
      SetValueOf: "",
      UzmanlikAlanlariSave: 0,
      IfThenElse: false,
      GoBack: "",
      isComp733245Visible: "hidden",
      isComp839981Visible: "hidden",
      isComp730186Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanlikalaniekle", "uzmanlikalaniekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanlikAlaniEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanlikalaniekle", "uzmanlikalaniekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanlikalaniekle", "uzmanlikalaniekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanlikAlaniEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uzmanlikalaniekle_816262_value: this.state.UzmanlikAlaniTransByLang?.at?.(0)?.uzmanlikTur ?? undefined,
      uzmanlikalaniekle_919052_value: this.state.UzmanlikAlaniTransByLang?.at?.(0)?.uzmanlikTur ?? undefined,
      uzmanlikalaniekle_40879_value: this.state.UzmanlikAlaniTransById?.at?.(0)?.lang ?? undefined,
      uzmanlikalaniekle_116134_value: this.state.UzmanlikAlaniTransById?.at?.(0)?.tanim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanlikAlaniEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      WaitFor_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      UzmanlikAlaniId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniID ?? this.props.screenInputs.uzmanlikalaniid,
        "Guid"
      ),
      UzmanlikAlaniId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniID ?? this.props.screenInputs.uzmanlikalaniid,
        "Guid"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniTranslationID ?? this.props.screenInputs.uzmanlikalanitranslationid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanlikAlaniEkle/UzmanlikAlaniEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikTurSecenek = result?.data.uzmanlikTurSecenek;

    formVars.uzmanlikalaniekle_816262_value =
      stateVars.UzmanlikAlaniTransByLang?.length > 0 ? stateVars.UzmanlikAlaniTransByLang[0]?.uzmanlikTur : null;
    formVars.uzmanlikalaniekle_816262_options = stateVars.UzmanlikTurSecenek;
    stateVars.UzmanlikAlaniLangByCondition = result?.data.uzmanlikAlaniLangByCondition;

    formVars.uzmanlikalaniekle_40879_value =
      stateVars.UzmanlikAlaniTransById?.length > 0 ? stateVars.UzmanlikAlaniTransById[0]?.lang : null;
    formVars.uzmanlikalaniekle_40879_options = stateVars.UzmanlikAlaniLangByCondition;
    stateVars.UzmanlikAlaniTransByLang = result?.data.uzmanlikAlaniTransByLang;
    formVars.uzmanlikalaniekle_816262_value =
      stateVars.UzmanlikAlaniTransByLang?.length > 0 ? stateVars.UzmanlikAlaniTransByLang[0]?.uzmanlikTur : null;
    formVars.uzmanlikalaniekle_816262_options = stateVars.UzmanlikTurSecenek;
    formVars.uzmanlikalaniekle_919052_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanlikAlaniTransByLang?.length > 0 ? stateVars.UzmanlikAlaniTransByLang[0]?.uzmanlikTur : null
    );
    stateVars.UzmanlikAlaniTransById = result?.data.uzmanlikAlaniTransById;
    formVars.uzmanlikalaniekle_40879_value =
      stateVars.UzmanlikAlaniTransById?.length > 0 ? stateVars.UzmanlikAlaniTransById[0]?.lang : null;
    formVars.uzmanlikalaniekle_40879_options = stateVars.UzmanlikAlaniLangByCondition;
    formVars.uzmanlikalaniekle_116134_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanlikAlaniTransById?.length > 0 ? stateVars.UzmanlikAlaniTransById[0]?.tanim : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp733245Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.uzmanlikalaniekle_816262_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlaniTransByLang?.length > 0 ? this.state.UzmanlikAlaniTransByLang[0]?.uzmanlikTur : null
    );

    stateVars.dataSource_816262 = this.state.UzmanlikTurSecenek;
    stateVars.dataSource_816262 = this.state.UzmanlikTurSecenek;
    formVars.uzmanlikalaniekle_919052_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlaniTransByLang?.length > 0 ? this.state.UzmanlikAlaniTransByLang[0]?.uzmanlikTur : null
    );

    stateVars.isComp730186Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.uzmanlikalaniekle_40879_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlaniTransById?.length > 0 ? this.state.UzmanlikAlaniTransById[0]?.lang : null
    );

    stateVars.dataSource_40879 = this.state.UzmanlikAlaniLangByCondition;
    stateVars.dataSource_40879 = this.state.UzmanlikAlaniLangByCondition;
    formVars.uzmanlikalaniekle_116134_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlaniTransById?.length > 0 ? this.state.UzmanlikAlaniTransById[0]?.tanim : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanlikAlaniEkleComponent_816262_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "uzmanlikalaniekle_919052_value",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "uzmanlikalaniekle_816262_value", "value", "UzmanlikTurSecenek", "tur", "tur")
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniEkleComponent_856375_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UzmanlikTur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalaniekle_919052_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniID ?? this.props.screenInputs.uzmanlikalaniid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniEkle/UzmanlikAlaniEkleComponent_856375_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlanlariSave = result?.data.uzmanlikAlanlariSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniEkleComponent_856375_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniEkleComponent_856375_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalaniekle_116134_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikAlaniId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniID ?? this.props.screenInputs.uzmanlikalaniid,
        "Guid"
      ),
      UzmanlikAlaniTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniTranslationID ?? this.props.screenInputs.uzmanlikalanitranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniEkle/UzmanlikAlaniEkleComponent_856375_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlaniTransSave = result?.data.uzmanlikAlaniTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  UzmanlikAlaniEkleComponent_856375_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalaniekle_116134_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikAlaniId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniID ?? this.props.screenInputs.uzmanlikalaniid,
        "Guid"
      ),
      UzmanlikAlaniTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanlikAlaniTranslationID ?? this.props.screenInputs.uzmanlikalanitranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalaniekle_40879_value",
            "value",
            "UzmanlikAlaniLangByCondition",
            "kod",
            "kod"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniEkle/UzmanlikAlaniEkleComponent_856375_onClick_else1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlaniTransSave = result?.data.uzmanlikAlaniTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  UzmanlikAlaniEkleComponent_856375_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New")) {
      isErrorOccurred = await this.UzmanlikAlaniEkleComponent_856375_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.UzmanlikAlaniEkleComponent_856375_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
