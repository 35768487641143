import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVucutBolgesiTransList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVucutBolgesiTransList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  VBTranslationByVucutBolgeId: any[];
  VBTranslationByVucutBolgeId_dummy: any[];
  VBTranslationKontrol: number;
  VBTranslationKontrol_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  VBTranslationDeleteById: number;
  VBTranslationDeleteById_dummy: number;
}

export class VucutBolgesiTransList_ScreenBase extends React.PureComponent<IVucutBolgesiTransList_ScreenProps, any> {
  vucutbolgesitranslist_409349_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "9af3f061-9f79-4aca-b9bb-0921ebacdb6d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 218538, PropertyName: "value", Value: "Vücut Bölgesi Translation" },
        { Id: 311084, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 883052, PropertyName: "label", Value: "Translation Ekle" },
        { Id: 409349, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 498198, PropertyName: "title", Value: "Language" },
        { Id: 328146, PropertyName: "value", Value: "[datafield:lang]" },
        { Id: 279267, PropertyName: "title", Value: "Translation" },
        { Id: 826303, PropertyName: "value", Value: "[datafield:tanim]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.vucutbolgesitranslist_409349_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      VBTranslationByVucutBolgeId: [],
      VBTranslationKontrol: 0,
      NAVIGATE: "",
      VBTranslationDeleteById: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesitranslist", "vucutbolgesitranslist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.VucutBolgesiTransListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesitranslist", "vucutbolgesitranslist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesitranslist", "vucutbolgesitranslist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.VucutBolgesiTransListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  VucutBolgesiTransListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VucutBolgesiTransList/VucutBolgesiTransListPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationByVucutBolgeId = result?.data.vbTranslationByVucutBolgeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VucutBolgesiTransListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiTransListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_409349 = this.state.VBTranslationByVucutBolgeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  VucutBolgesiTransListComponent_199167_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiTransListComponent_883052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VucutBolgesiTransList/VucutBolgesiTransListComponent_883052_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationKontrol = result?.data.vbTranslationKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VucutBolgesiTransListComponent_883052_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiTransListComponent_883052_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "VucutBolgesiEkle",
      "VucutBolgeID",
      this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid
    );
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "WaitFor", "Add");
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "VBTranslationID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "CurrentLang", "");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VucutBolgesiTransList",
      "VucutBolgesiEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "145966",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiTransListComponent_595047_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "VucutBolgesiEkle",
        "VucutBolgeID",
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "vucutBolgesiId")
      );
      KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "WaitFor", "New");
      KuikaAppManager.addToPageInputVariables(
        "VucutBolgesiEkle",
        "VBTranslationID",
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "VucutBolgesiEkle",
        "CurrentLang",
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "lang")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.VucutBolgesiTransListComponent_595047_onClick1_,
        "VucutBolgesiTransList",
        "VucutBolgesiEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "271718",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.VucutBolgesiTransListComponent_595047_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  VucutBolgesiTransListComponent_595047_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "VucutBolgesiEkle",
        "VucutBolgeID",
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "vucutBolgesiId")
      );
      KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "WaitFor", "Edit");
      KuikaAppManager.addToPageInputVariables(
        "VucutBolgesiEkle",
        "VBTranslationID",
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "VucutBolgesiEkle",
        "CurrentLang",
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "lang")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "VucutBolgesiTransList",
        "VucutBolgesiEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "728269",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  VucutBolgesiTransListComponent_201870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "vucutbolgesitranslist_409349_value", "id"),
        "Guid"
      ),
      VucutBolgesiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "85220e58_dc4e_0551_4315_9440d0fb911a_confirmation",
        this.defaultML,
        "Bu translation silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "VucutBolgesiTransList/VucutBolgesiTransListComponent_201870_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.VBTranslationDeleteById = result?.data.vbTranslationDeleteById;
        stateVars.VBTranslationByVucutBolgeId = result?.data.vbTranslationByVucutBolgeId;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.VucutBolgesiTransListComponent_201870_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiTransListComponent_201870_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_409349 = this.state.VBTranslationByVucutBolgeId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [145966, 271718, 728269] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.VucutBolgesiTransListPageInit();
    }
    if (diId == 271718) {
      isErrorOccurred = await this.VucutBolgesiTransListComponent_595047_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
