import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanBelgeOnayList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanBelgeOnayList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class UzmanBelgeOnayList_ScreenBase extends React.PureComponent<IUzmanBelgeOnayList_ScreenProps, any> {
  uzmanbelgeonaylist_814341_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanbelgeonaylist_755660_value_kuikaTableRef: React.RefObject<any>;
  uzmanbelgeonaylist_692036_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c006fe08-d184-4a7f-9bc7-c6baeb349e78",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Uzman Belge Onay Listesi" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 814341, PropertyName: "placeholder", Value: "Filtrele" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 455307, PropertyName: "title", Value: "Uzman" },
        { Id: 371259, PropertyName: "value", Value: "Dr. Ali Mavişehir" },
        { Id: 639777, PropertyName: "title", Value: "Belge Adedi" },
        { Id: 889056, PropertyName: "value", Value: "1 adet" },
        { Id: 777129, PropertyName: "title", Value: "Yükleme Tarihi" },
        { Id: 232569, PropertyName: "value", Value: "22.10.2023" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanbelgeonaylist_814341_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanbelgeonaylist_755660_value_kuikaTableRef = React.createRef();
    this.uzmanbelgeonaylist_692036_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanbelgeonaylist", "uzmanbelgeonaylist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanbelgeonaylist", "uzmanbelgeonaylist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanbelgeonaylist", "uzmanbelgeonaylist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
