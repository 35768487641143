import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAsiTranslationList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAsiTranslationList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  AsilarTranslationByAsiId: any[];
  AsilarTranslationByAsiId_dummy: any[];
  AsilarTranslationKontrol: number;
  AsilarTranslationKontrol_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  AsilarTranslationDelete: number;
  AsilarTranslationDelete_dummy: number;
}

export class AsiTranslationList_ScreenBase extends React.PureComponent<IAsiTranslationList_ScreenProps, any> {
  asitranslationlist_409349_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c574f5f1-2abd-4270-88ac-264010da7b04",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 218538, PropertyName: "value", Value: "Aşı Translations" },
        { Id: 311084, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 883052, PropertyName: "label", Value: "Translation Ekle" },
        { Id: 409349, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 498198, PropertyName: "title", Value: "Language" },
        { Id: 328146, PropertyName: "value", Value: "[datafield:lang]" },
        { Id: 279267, PropertyName: "title", Value: "Translation" },
        { Id: 826303, PropertyName: "value", Value: "[datafield:tanim]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.asitranslationlist_409349_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      AsilarTranslationByAsiId: [],
      AsilarTranslationKontrol: 0,
      NAVIGATE: "",
      AsilarTranslationDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("asitranslationlist", "asitranslationlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AsiTranslationListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("asitranslationlist", "asitranslationlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("asitranslationlist", "asitranslationlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AsiTranslationListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AsiTranslationListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      AsiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.AsiID ?? this.props.screenInputs.asiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AsiTranslationList/AsiTranslationListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AsilarTranslationByAsiId = result?.data.asilarTranslationByAsiId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AsiTranslationListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiTranslationListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_409349 = this.state.AsilarTranslationByAsiId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AsiTranslationListComponent_199167_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiTranslationListComponent_883052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AsiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.AsiID ?? this.props.screenInputs.asiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "AsiTranslationList/AsiTranslationListComponent_883052_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AsilarTranslationKontrol = result?.data.asilarTranslationKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AsiTranslationListComponent_883052_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiTranslationListComponent_883052_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AsiEkle",
      "AsiID",
      this.props.screenInputs.AsiID ?? this.props.screenInputs.asiid
    );
    KuikaAppManager.addToPageInputVariables("AsiEkle", "CuurentLang", "");
    KuikaAppManager.addToPageInputVariables("AsiEkle", "WaitFor", "Add");
    KuikaAppManager.addToPageInputVariables("AsiEkle", "AsiTranslationID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AsiTranslationList",
      "AsiEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "732640",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiTranslationListComponent_595047_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "AsiEkle",
        "AsiID",
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "asiId")
      );
      KuikaAppManager.addToPageInputVariables(
        "AsiEkle",
        "CuurentLang",
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("AsiEkle", "WaitFor", "New");
      KuikaAppManager.addToPageInputVariables(
        "AsiEkle",
        "AsiTranslationID",
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.AsiTranslationListComponent_595047_onClick1_,
        "AsiTranslationList",
        "AsiEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "939704",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.AsiTranslationListComponent_595047_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  AsiTranslationListComponent_595047_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "AsiEkle",
        "AsiID",
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "asiId")
      );
      KuikaAppManager.addToPageInputVariables(
        "AsiEkle",
        "CuurentLang",
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("AsiEkle", "WaitFor", "Edit");
      KuikaAppManager.addToPageInputVariables(
        "AsiEkle",
        "AsiTranslationID",
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "AsiTranslationList",
        "AsiEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "255570",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  AsiTranslationListComponent_201870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "asitranslationlist_409349_value", "id"),
        "Guid"
      ),
      AsiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.AsiID ?? this.props.screenInputs.asiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "109e9188_d4cf_3d08_f8fc_79d6ca7442d9_confirmation",
        this.defaultML,
        "Bu translation silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "AsiTranslationList/AsiTranslationListComponent_201870_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.AsilarTranslationDelete = result?.data.asilarTranslationDelete;
        stateVars.AsilarTranslationByAsiId = result?.data.asilarTranslationByAsiId;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.AsiTranslationListComponent_201870_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiTranslationListComponent_201870_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_409349 = this.state.AsilarTranslationByAsiId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [732640, 939704, 255570] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AsiTranslationListPageInit();
    }
    if (diId == 939704) {
      isErrorOccurred = await this.AsiTranslationListComponent_595047_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
