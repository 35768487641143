import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanlikAlaniList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanlikAlaniList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  UzmanlikTurSecenek: any[];
  UzmanlikTurSecenek_dummy: any[];
  UzmanlikAlanlariSearch: any[];
  UzmanlikAlanlariSearch_dummy: any[];
  UzmanlikAlanlariSearchCount: any[];
  UzmanlikAlanlariSearchCount_dummy: any[];
  UzmanlikAlaniSilmeKontrol: number;
  UzmanlikAlaniSilmeKontrol_dummy: number;
  UzmanlikAlanlariDelete: number;
  UzmanlikAlanlariDelete_dummy: number;
}

export class UzmanlikAlaniList_ScreenBase extends React.PureComponent<IUzmanlikAlaniList_ScreenProps, any> {
  uzmanlikalanilist_388297_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanlikalanilist_755660_value_kuikaTableRef: React.RefObject<any>;
  uzmanlikalanilist_984765_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "86b8c9a5-fdee-4eef-9336-7ad17d27fa77",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Uzmanlık Alanları" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 388297, PropertyName: "placeholder", Value: "Uzmanlık Tür Seçiniz..." },
        { Id: 718191, PropertyName: "label", Value: "Yeni Uzmanlık Alanı Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 639777, PropertyName: "title", Value: "Uzmanlık türü" },
        { Id: 889056, PropertyName: "value", Value: "[datafield:uzmanliktur]" },
        { Id: 147953, PropertyName: "title", Value: "Uzmanlık Alanı Tanım" },
        { Id: 189965, PropertyName: "value", Value: "[datafield:uzmanliktanim]" },
        { Id: 830114, PropertyName: "title", Value: "#" },
        { Id: 328954, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanlikalanilist_388297_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanlikalanilist_755660_value_kuikaTableRef = React.createRef();
    this.uzmanlikalanilist_984765_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      UzmanlikTurSecenek: [],
      UzmanlikAlanlariSearch: [],
      UzmanlikAlanlariSearchCount: [],
      UzmanlikAlaniSilmeKontrol: 0,
      UzmanlikAlanlariDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanlikalanilist", "uzmanlikalanilist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanlikAlaniListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanlikalanilist", "uzmanlikalanilist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanlikalanilist", "uzmanlikalanilist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanlikAlaniListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanlikAlaniListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanlikAlaniList/UzmanlikAlaniListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikTurSecenek = result?.data.uzmanlikTurSecenek;

    formVars.uzmanlikalanilist_388297_options = stateVars.UzmanlikTurSecenek;
    stateVars.UzmanlikAlanlariSearch = result?.data.uzmanlikAlanlariSearch;

    stateVars.UzmanlikAlanlariSearchCount = result?.data.uzmanlikAlanlariSearchCount;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanlikAlanlariSearchCount?.length > 0 ? stateVars.UzmanlikAlanlariSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_388297 = this.state.UzmanlikTurSecenek;
    stateVars.dataSource_388297 = this.state.UzmanlikTurSecenek;

    stateVars.dataSource_755660 = this.state.UzmanlikAlanlariSearch;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    formVars.uzmanlikalanilist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanlikAlaniList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "180570",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_2755_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniList/UzmanlikAlaniListComponent_2755_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlanlariSearch = result?.data.uzmanlikAlanlariSearch;

    stateVars.UzmanlikAlanlariSearchCount = result?.data.uzmanlikAlanlariSearchCount;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanlikAlanlariSearchCount?.length > 0 ? stateVars.UzmanlikAlanlariSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniListComponent_2755_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniListComponent_2755_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanlikAlanlariSearch;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    formVars.uzmanlikalanilist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_388297_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniList/UzmanlikAlaniListComponent_388297_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlanlariSearch = result?.data.uzmanlikAlanlariSearch;

    stateVars.UzmanlikAlanlariSearchCount = result?.data.uzmanlikAlanlariSearchCount;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanlikAlanlariSearchCount?.length > 0 ? stateVars.UzmanlikAlanlariSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniListComponent_388297_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniListComponent_388297_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanlikAlanlariSearch;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    formVars.uzmanlikalanilist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UzmanlikAlaniEkle", "UzmanlikAlaniID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "UzmanlikAlaniEkle",
      "UzmanlikAlaniTranslationID",
      Guid.create().toString()
    );
    KuikaAppManager.addToPageInputVariables("UzmanlikAlaniEkle", "WaitFor", "New");
    KuikaAppManager.addToPageInputVariables("UzmanlikAlaniEkle", "CurrentLang", "tr_TR");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanlikAlaniList",
      "UzmanlikAlaniEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "110873",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UzmanlikAlaniTransList",
      "UzmanlikAlaniID",
      ReactSystemFunctions.value(this, "uzmanlikalanilist_755660_value", "uzmanlikAlaniId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanlikAlaniList",
      "UzmanlikAlaniTransList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_461088_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UzmanlikAlaniId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "uzmanlikalanilist_755660_value", "uzmanlikAlaniId"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniList/UzmanlikAlaniListComponent_461088_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlaniSilmeKontrol = result?.data.uzmanlikAlaniSilmeKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniListComponent_461088_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniListComponent_461088_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UzmanlikAlaniId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "uzmanlikalanilist_755660_value", "uzmanlikAlaniId"),
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      )
    };

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "959c9242_d6b1_b2c1_778d_14fbc8f08ed8_confirmation",
        this.defaultML,
        "Bu uzmanlık alanı ve translation bilgileri silinecektir emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "UzmanlikAlaniList/UzmanlikAlaniListComponent_461088_onClick1_",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.UzmanlikAlanlariDelete = result?.data.uzmanlikAlanlariDelete;
        stateVars.UzmanlikAlanlariSearch = result?.data.uzmanlikAlanlariSearch;

        stateVars.UzmanlikAlanlariSearchCount = result?.data.uzmanlikAlanlariSearchCount;
        formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
          this,
          stateVars.UzmanlikAlanlariSearchCount?.length > 0 ? stateVars.UzmanlikAlanlariSearchCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.UzmanlikAlaniListComponent_461088_onClick2_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniListComponent_461088_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanlikAlanlariSearch;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    formVars.uzmanlikalanilist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanlikAlaniListComponent_984765_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_984765_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlikalanilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      UzmanlikTur_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanlikalanilist_388297_value",
            "value",
            "UzmanlikTurSecenek",
            "tur",
            "tur"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanlikAlaniList/UzmanlikAlaniListComponent_984765_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlanlariSearch = result?.data.uzmanlikAlanlariSearch;

    stateVars.UzmanlikAlanlariSearchCount = result?.data.uzmanlikAlanlariSearchCount;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanlikAlanlariSearchCount?.length > 0 ? stateVars.UzmanlikAlanlariSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanlikAlaniListComponent_984765_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanlikAlaniListComponent_984765_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanlikAlanlariSearch;
    formVars.uzmanlikalanilist_49171_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    formVars.uzmanlikalanilist_984765_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanlikAlanlariSearchCount?.length > 0 ? this.state.UzmanlikAlanlariSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [180570, 110873] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UzmanlikAlaniListPageInit();
    }
  }
}
