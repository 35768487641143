import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISemptomOneriList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISemptomOneriList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SemptomOneriList: any[];
  SemptomOneriList_dummy: any[];
  SemptomOneriListCount: any[];
  SemptomOneriListCount_dummy: any[];
  SemptomOneriDelete: number;
  SemptomOneriDelete_dummy: number;
}

export class SemptomOneriList_ScreenBase extends React.PureComponent<ISemptomOneriList_ScreenProps, any> {
  semptomonerilist_423262_value_kuikaTableRef: React.RefObject<any>;
  semptomonerilist_40606_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c16a82d1-39f5-41a3-9f4a-11c9eeff4f0d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 460315, PropertyName: "value", Value: "Semptomlara Göre Öneriler" },
        { Id: 99575, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 727721, PropertyName: "label", Value: "Profil" },
        { Id: 363060, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 523282, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 206178, PropertyName: "label", Value: "Yeni Öneri Ekle" },
        { Id: 423262, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 789566, PropertyName: "title", Value: "Bölge" },
        { Id: 665474, PropertyName: "value", Value: "[datafield:vucutbolgesi]" },
        { Id: 778970, PropertyName: "title", Value: "Semptomlar" },
        { Id: 7718, PropertyName: "value", Value: "[datafield:semptomnames]" },
        { Id: 805800, PropertyName: "title", Value: "Tanımlamalar" },
        { Id: 539459, PropertyName: "value", Value: "[datafield:tanimlama]" },
        { Id: 512916, PropertyName: "title", Value: "Bakım Önerileri" },
        { Id: 562891, PropertyName: "value", Value: "[datafield:bakimonerisi]" },
        { Id: 868687, PropertyName: "title", Value: "Ne zaman alınmalı?" },
        { Id: 615936, PropertyName: "value", Value: "[datafield:nezamanaramali]" },
        { Id: 988510, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.semptomonerilist_423262_value_kuikaTableRef = React.createRef();
    this.semptomonerilist_40606_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      SemptomOneriList: [],
      SemptomOneriListCount: [],
      SemptomOneriDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("semptomonerilist", "semptomonerilist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SemptomOneriListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("semptomonerilist", "semptomonerilist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("semptomonerilist", "semptomonerilist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SemptomOneriListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SemptomOneriListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomOneriList/SemptomOneriListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriList = result?.data.semptomOneriList;

    stateVars.SemptomOneriListCount = result?.data.semptomOneriListCount;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomOneriListCount?.length > 0 ? stateVars.SemptomOneriListCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.SemptomOneriList;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    formVars.semptomonerilist_40606_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SemptomOneriListComponent_716309_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomOneriList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "534970",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriListComponent_523282_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriList/SemptomOneriListComponent_523282_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriList = result?.data.semptomOneriList;

    stateVars.SemptomOneriListCount = result?.data.semptomOneriListCount;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomOneriListCount?.length > 0 ? stateVars.SemptomOneriListCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriListComponent_523282_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriListComponent_523282_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.SemptomOneriList;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    formVars.semptomonerilist_40606_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriListComponent_206178_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "CurrentLang", "tr_TR");
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "WaitFor", "New");
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "SemptomOneriID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "isVBEditable", true);
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "SemptomOneriTransID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("SemptomOneriEkle", "SemptomIDs", "");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomOneriList",
      "SemptomOneriEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "107215",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriListComponent_647460_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SemptomOneriTransList",
      "SemptomOneriID",
      ReactSystemFunctions.value(this, "semptomonerilist_423262_value", "semptomOneriId")
    );
    KuikaAppManager.addToPageInputVariables(
      "SemptomOneriTransList",
      "VucutBolgesi",
      ReactSystemFunctions.value(this, "semptomonerilist_423262_value", "vucutBolgesi")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomOneriList",
      "SemptomOneriTransList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriListComponent_13366_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SemptomOneriId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "semptomonerilist_423262_value", "semptomOneriId"),
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "7188b059_f55a_cf06_2721_4ba84ea6f70c_confirmation",
        this.defaultML,
        "Bu semptom önerisi ve ona ait translation bilgileri silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "SemptomOneriList/SemptomOneriListComponent_13366_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.SemptomOneriDelete = result?.data.semptomOneriDelete;
        stateVars.SemptomOneriList = result?.data.semptomOneriList;

        stateVars.SemptomOneriListCount = result?.data.semptomOneriListCount;
        formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
          this,
          stateVars.SemptomOneriListCount?.length > 0 ? stateVars.SemptomOneriListCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SemptomOneriListComponent_13366_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriListComponent_13366_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.SemptomOneriList;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    formVars.semptomonerilist_40606_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriListComponent_40606_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomonerilist_40606_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriList/SemptomOneriListComponent_40606_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriList = result?.data.semptomOneriList;

    stateVars.SemptomOneriListCount = result?.data.semptomOneriListCount;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomOneriListCount?.length > 0 ? stateVars.SemptomOneriListCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriListComponent_40606_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriListComponent_40606_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.SemptomOneriList;
    formVars.semptomonerilist_383227_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    formVars.semptomonerilist_40606_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomOneriListCount?.length > 0 ? this.state.SemptomOneriListCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [534970, 107215] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SemptomOneriListPageInit();
    }
  }
}
