import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDanisanProfil_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDanisanProfil_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  KullaniciProfil: any[];
  KullaniciProfil_dummy: any[];
  KullaniciUyelikByKullaniciId: any[];
  KullaniciUyelikByKullaniciId_dummy: any[];
  KullaniciAileBireyiByKullaniciId: any[];
  KullaniciAileBireyiByKullaniciId_dummy: any[];
  UyelikPlaniUpdate: number;
  UyelikPlaniUpdate_dummy: number;
  KullaniciAktifPasif: number;
  KullaniciAktifPasif_dummy: number;
  isComp894282Visible: "visible" | "hidden";
}

export class DanisanProfil_ScreenBase extends React.PureComponent<IDanisanProfil_ScreenProps, any> {
  danisanprofil_834137_value_kuikaTableRef: React.RefObject<any>;
  danisanprofil_636754_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "504dc3d5-4174-42de-93e8-283b93d1156a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 727074, PropertyName: "value", Value: "Danışan Profil" },
        { Id: 986905, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 178530, PropertyName: "label", Value: "Profil" },
        { Id: 728311, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 131857, PropertyName: "value", Value: "Üyelik Tipi:" },
        { Id: 890458, PropertyName: "value", Value: "Email" },
        { Id: 415051, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 713887, PropertyName: "value", Value: "Adres" },
        { Id: 990053, PropertyName: "value", Value: "Danışanı pasif/aktif yap" },
        { Id: 878402, PropertyName: "value", Value: "Üyelik Tipi" },
        { Id: 834137, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 41328, PropertyName: "title", Value: "Aktif" },
        { Id: 405780, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 168114, PropertyName: "value", Value: "[datafield:uyeliktipi]" },
        { Id: 961022, PropertyName: "title", Value: "Üyelik Başlangıç" },
        { Id: 479028, PropertyName: "value", Value: "[datafield:uyelikbaslangictarihi]" },
        { Id: 338305, PropertyName: "title", Value: "Üyelik Bitiş" },
        { Id: 330906, PropertyName: "value", Value: "[datafield:uyelikbitistarihi]" },
        { Id: 389795, PropertyName: "value", Value: "Aile Bireyleri" },
        { Id: 636754, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 638633, PropertyName: "title", Value: "Adı Soyadı" },
        { Id: 671047, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 930007, PropertyName: "title", Value: "Doğum Tarihi" },
        { Id: 346603, PropertyName: "value", Value: "[datafield:dogumtarihi]" },
        { Id: 344681, PropertyName: "title", Value: "Yakınlık" },
        { Id: 359209, PropertyName: "value", Value: "[datafield:yakinlik]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.danisanprofil_834137_value_kuikaTableRef = React.createRef();
    this.danisanprofil_636754_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      KullaniciProfil: [],
      KullaniciUyelikByKullaniciId: [],
      KullaniciAileBireyiByKullaniciId: [],
      UyelikPlaniUpdate: 0,
      KullaniciAktifPasif: 0,
      isComp894282Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("danisanprofil", "danisanprofil");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DanisanProfilPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("danisanprofil", "danisanprofil");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("danisanprofil", "danisanprofil");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DanisanProfilPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      danisanprofil_669505_value: this.state.KullaniciProfil?.at?.(0)?.profileFotoLink ?? undefined,
      danisanprofil_33472_value: this.state.KullaniciProfil?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DanisanProfilPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      KullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      ),
      KullaniciId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      ),
      KullaniciId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanProfil/DanisanProfilPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciProfil = result?.data.kullaniciProfil;
    formVars.danisanprofil_669505_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.profileFotoLink : null
    );
    formVars.danisanprofil_301708_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.adSoyad : null
    );
    formVars.danisanprofil_815679_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.unvan : null
    );
    formVars.danisanprofil_871239_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.uyelikTipi : null
    );
    formVars.danisanprofil_311747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0
        ? stateVars.KullaniciProfil[stateVars.KullaniciProfil.length - 1].username
        : null
    );
    formVars.danisanprofil_902409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.telefon : null
    );
    formVars.danisanprofil_497561_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.adres : null
    );
    formVars.danisanprofil_33472_value = ReactSystemFunctions.value(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.isActive : null
    );
    stateVars.KullaniciUyelikByKullaniciId = result?.data.kullaniciUyelikByKullaniciId;

    stateVars.KullaniciAileBireyiByKullaniciId = result?.data.kullaniciAileBireyiByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanProfilPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanProfilPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.danisanprofil_669505_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.profileFotoLink : null
    );

    formVars.danisanprofil_301708_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.adSoyad : null
    );

    formVars.danisanprofil_815679_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.unvan : null
    );

    formVars.danisanprofil_871239_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uyelikTipi : null
    );

    formVars.danisanprofil_311747_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0
        ? this.state.KullaniciProfil[this.state.KullaniciProfil.length - 1].username
        : null
    );

    formVars.danisanprofil_902409_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.telefon : null
    );

    formVars.danisanprofil_497561_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.adres : null
    );

    formVars.danisanprofil_33472_value = ReactSystemFunctions.value(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.isActive : null
    );

    stateVars.dataSource_834137 = this.state.KullaniciUyelikByKullaniciId;

    stateVars.dataSource_636754 = this.state.KullaniciAileBireyiByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DanisanProfilComponent_991319_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanProfilComponent_871239_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikPlanId_0: ReactSystemFunctions.convertToTypeByName("dd5cbe98-27f9-4c8b-aa31-b71a709d636f", "Guid"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uyelikPlanId : null
        ),
        "db9a7bd7-60c6-467e-816b-1ca2186ba45d"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "DanisanProfil/DanisanProfilComponent_871239_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UyelikPlaniUpdate = result?.data.uyelikPlaniUpdate;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.DanisanProfilComponent_871239_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.DanisanProfilComponent_871239_onClick1_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanProfilComponent_871239_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikPlanId_0: ReactSystemFunctions.convertToTypeByName("db9a7bd7-60c6-467e-816b-1ca2186ba45d", "Guid"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uyelikPlanId : null
        ),
        "dd5cbe98-27f9-4c8b-aa31-b71a709d636f"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "DanisanProfil/DanisanProfilComponent_871239_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.UyelikPlaniUpdate = result?.data.uyelikPlaniUpdate;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.DanisanProfilComponent_871239_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.DanisanProfilComponent_871239_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanProfilComponent_871239_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      KullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DanisanProfil/DanisanProfilComponent_871239_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciProfil = result?.data.kullaniciProfil;
    formVars.danisanprofil_669505_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.profileFotoLink : null
    );
    formVars.danisanprofil_301708_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.adSoyad : null
    );
    formVars.danisanprofil_815679_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.unvan : null
    );
    formVars.danisanprofil_871239_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.uyelikTipi : null
    );
    formVars.danisanprofil_311747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0
        ? stateVars.KullaniciProfil[stateVars.KullaniciProfil.length - 1].username
        : null
    );
    formVars.danisanprofil_902409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.telefon : null
    );
    formVars.danisanprofil_497561_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.adres : null
    );
    formVars.danisanprofil_33472_value = ReactSystemFunctions.value(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.isActive : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanProfilComponent_871239_onClick3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanProfilComponent_871239_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.danisanprofil_669505_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.profileFotoLink : null
    );

    formVars.danisanprofil_301708_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.adSoyad : null
    );

    formVars.danisanprofil_815679_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.unvan : null
    );

    formVars.danisanprofil_871239_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uyelikTipi : null
    );

    formVars.danisanprofil_311747_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0
        ? this.state.KullaniciProfil[this.state.KullaniciProfil.length - 1].username
        : null
    );

    formVars.danisanprofil_902409_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.telefon : null
    );

    formVars.danisanprofil_497561_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.adres : null
    );

    formVars.danisanprofil_33472_value = ReactSystemFunctions.value(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.isActive : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanProfilComponent_33472_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanprofil_33472_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KullaniciID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DanisanProfil/DanisanProfilComponent_33472_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciAktifPasif = result?.data.kullaniciAktifPasif;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
