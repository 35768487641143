import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISemptomOneriEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISemptomOneriEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  VBTranslationByLang: any[];
  VBTranslationByLang_dummy: any[];
  SemptomOneriLangByCondition: any[];
  SemptomOneriLangByCondition_dummy: any[];
  SemptomOneriTransById: any[];
  SemptomOneriTransById_dummy: any[];
  SemptomTransByVucutBolgesiId: any[];
  SemptomTransByVucutBolgesiId_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  SemptomOneriSave: number;
  SemptomOneriSave_dummy: number;
  SemptomOneriTransSave: number;
  SemptomOneriTransSave_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp399032Visible: "visible" | "hidden";
  isComp439596Visible: "visible" | "hidden";
  isComp435603Visible: "visible" | "hidden";
}

export class SemptomOneriEkle_ScreenBase extends React.PureComponent<ISemptomOneriEkle_ScreenProps, any> {
  semptomoneriekle_241431_value_kuikaSelectBoxRef: React.RefObject<any>;
  semptomoneriekle_144214_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "fc25bd6e-e8f4-4d40-a0b3-a3b77f3181a5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 151318, PropertyName: "value", Value: "Öneri Translation" },
        { Id: 430640, PropertyName: "value", Value: "Vücut Bölgesi" },
        { Id: 241431, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 671041, PropertyName: "value", Value: "Semptomlar" },
        { Id: 427110, PropertyName: "value", Value: "Dil" },
        { Id: 144214, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 398519, PropertyName: "value", Value: "Tanımlamalar" },
        { Id: 46482, PropertyName: "value", Value: "Bakım Önerileri" },
        { Id: 899548, PropertyName: "value", Value: "Ne Zaman Aranmalı" },
        { Id: 192050, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.semptomoneriekle_241431_value_kuikaSelectBoxRef = React.createRef();
    this.semptomoneriekle_144214_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      VBTranslationByLang: [],
      SemptomOneriLangByCondition: [],
      SemptomOneriTransById: [],
      SemptomTransByVucutBolgesiId: [],
      SetValueOf: "",
      SemptomOneriSave: 0,
      IfThenElse: false,
      isComp399032Visible: "hidden",
      isComp439596Visible: "hidden",
      isComp435603Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("semptomoneriekle", "semptomoneriekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SemptomOneriEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("semptomoneriekle", "semptomoneriekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("semptomoneriekle", "semptomoneriekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SemptomOneriEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      semptomoneriekle_241431_value: this.state.SemptomOneriTransById?.at?.(0)?.vucutBolgesiId ?? undefined,
      semptomoneriekle_474213_value: this.props.screenInputs.SemptomIDs ?? this.props.screenInputs.semptomids,
      semptomoneriekle_144214_value: this.state.SemptomOneriTransById?.at?.(0)?.lang ?? undefined,
      semptomoneriekle_297519_value: this.state.SemptomOneriTransById?.at?.(0)?.tanimlama ?? undefined,
      semptomoneriekle_295706_value: this.state.SemptomOneriTransById?.at?.(0)?.bakimOnerisi ?? undefined,
      semptomoneriekle_469783_value: this.state.SemptomOneriTransById?.at?.(0)?.neZamanAramali ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SemptomOneriEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      WaitFor_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      SemptomOneriId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriTransID ?? this.props.screenInputs.semptomoneritransid,
        "Guid"
      ),
      Lang_3: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomOneriEkle/SemptomOneriEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationByLang = result?.data.vbTranslationByLang;

    formVars.semptomoneriekle_241431_value =
      stateVars.SemptomOneriTransById?.length > 0 ? stateVars.SemptomOneriTransById[0]?.vucutBolgesiId : null;
    formVars.semptomoneriekle_241431_options = stateVars.VBTranslationByLang;
    stateVars.SemptomOneriLangByCondition = result?.data.semptomOneriLangByCondition;

    formVars.semptomoneriekle_144214_value =
      stateVars.SemptomOneriTransById?.length > 0 ? stateVars.SemptomOneriTransById[0]?.lang : null;
    formVars.semptomoneriekle_144214_options = stateVars.SemptomOneriLangByCondition;
    stateVars.SemptomOneriTransById = result?.data.semptomOneriTransById;
    formVars.semptomoneriekle_241431_value =
      stateVars.SemptomOneriTransById?.length > 0 ? stateVars.SemptomOneriTransById[0]?.vucutBolgesiId : null;
    formVars.semptomoneriekle_241431_options = stateVars.VBTranslationByLang;
    formVars.semptomoneriekle_144214_value =
      stateVars.SemptomOneriTransById?.length > 0 ? stateVars.SemptomOneriTransById[0]?.lang : null;
    formVars.semptomoneriekle_144214_options = stateVars.SemptomOneriLangByCondition;
    stateVars.SemptomTransByVucutBolgesiId = result?.data.semptomTransByVucutBolgesiId;

    formVars.semptomoneriekle_474213_options = stateVars.SemptomTransByVucutBolgesiId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp399032Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.semptomoneriekle_241431_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransById?.length > 0 ? this.state.SemptomOneriTransById[0]?.vucutBolgesiId : null
    );

    stateVars.dataSource_241431 = this.state.VBTranslationByLang;
    stateVars.dataSource_241431 = this.state.VBTranslationByLang;
    stateVars.isComp439596Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_474213 = this.state.SemptomTransByVucutBolgesiId;
    stateVars.dataSource_474213 = this.state.SemptomTransByVucutBolgesiId;
    stateVars.isComp435603Visible =
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New") ===
      true
        ? "visible"
        : "hidden";
    formVars.semptomoneriekle_144214_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransById?.length > 0 ? this.state.SemptomOneriTransById[0]?.lang : null
    );

    stateVars.dataSource_144214 = this.state.SemptomOneriLangByCondition;
    stateVars.dataSource_144214 = this.state.SemptomOneriLangByCondition;
    formVars.semptomoneriekle_297519_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransById?.length > 0 ? this.state.SemptomOneriTransById[0]?.tanimlama : null
    );

    formVars.semptomoneriekle_295706_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransById?.length > 0 ? this.state.SemptomOneriTransById[0]?.bakimOnerisi : null
    );

    formVars.semptomoneriekle_469783_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomOneriTransById?.length > 0 ? this.state.SemptomOneriTransById[0]?.neZamanAramali : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SemptomOneriEkleComponent_198855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriEkleComponent_241431_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "semptomoneriekle_474213_value", null, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriEkleComponent_241431_onChange1_();
    });

    return isErrorOccurred;
  };
  SemptomOneriEkleComponent_241431_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "semptomoneriekle_241431_value",
            "value",
            "VBTranslationByLang",
            "vucutBolgesiId",
            "vucutBolgesiId"
          )
        ),
        "Guid"
      ),
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriEkle/SemptomOneriEkleComponent_241431_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomTransByVucutBolgesiId = result?.data.semptomTransByVucutBolgesiId;

    formVars.semptomoneriekle_474213_options = stateVars.SemptomTransByVucutBolgesiId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriEkleComponent_241431_onChange2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriEkleComponent_241431_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_474213 = this.state.SemptomTransByVucutBolgesiId;
    stateVars.dataSource_474213 = this.state.SemptomTransByVucutBolgesiId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomOneriEkleComponent_192050_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriEkle/SemptomOneriEkleComponent_192050_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriSave = result?.data.semptomOneriSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomOneriEkleComponent_192050_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomOneriEkleComponent_192050_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      Tanimlama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomoneriekle_297519_value", "value", "", "", "")
        ),
        "string"
      ),
      BakimOnerisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomoneriekle_295706_value", "value", "", "", "")
        ),
        "string"
      ),
      NeZamanAramali_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomoneriekle_469783_value", "value", "", "", "")
        ),
        "string"
      ),
      SemptomIDs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "semptomoneriekle_474213_value",
            "value",
            "SemptomTransByVucutBolgesiId",
            "semptomId",
            "semptomId"
          )
        ),
        "string"
      ),
      SemptomOneriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      ),
      SemptomOneriTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriTransID ?? this.props.screenInputs.semptomoneritransid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriEkle/SemptomOneriEkleComponent_192050_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriTransSave = result?.data.semptomOneriTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SemptomOneriEkleComponent_192050_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "semptomoneriekle_144214_value",
            "value",
            "SemptomOneriLangByCondition",
            "kod",
            "kod"
          )
        ),
        "string"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      Tanimlama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomoneriekle_297519_value", "value", "", "", "")
        ),
        "string"
      ),
      BakimOnerisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomoneriekle_295706_value", "value", "", "", "")
        ),
        "string"
      ),
      NeZamanAramali_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomoneriekle_469783_value", "value", "", "", "")
        ),
        "string"
      ),
      SemptomIDs_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomIDs ?? this.props.screenInputs.semptomids,
        "string"
      ),
      SemptomOneriId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriID ?? this.props.screenInputs.semptomoneriid,
        "Guid"
      ),
      SemptomOneriTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomOneriTransID ?? this.props.screenInputs.semptomoneritransid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomOneriEkle/SemptomOneriEkleComponent_192050_onClick_else1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomOneriTransSave = result?.data.semptomOneriTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SemptomOneriEkleComponent_192050_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New")) {
      isErrorOccurred = await this.SemptomOneriEkleComponent_192050_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.SemptomOneriEkleComponent_192050_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
