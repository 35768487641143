import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITaramaTestiList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITaramaTestiList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  TaramaTestleriSearch: any[];
  TaramaTestleriSearch_dummy: any[];
  TaramaTestleriSearchCount: any[];
  TaramaTestleriSearchCount_dummy: any[];
  TaramaTestleriDelete: number;
  TaramaTestleriDelete_dummy: number;
}

export class TaramaTestiList_ScreenBase extends React.PureComponent<ITaramaTestiList_ScreenProps, any> {
  taramatestilist_755660_value_kuikaTableRef: React.RefObject<any>;
  taramatestilist_692036_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "b7182746-b0de-41ec-8b5d-7b129176c434",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Tarama Testleri" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 718191, PropertyName: "label", Value: "Yeni Tarama Testi Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 455307, PropertyName: "title", Value: "Test Adı" },
        { Id: 371259, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 736103, PropertyName: "title", Value: "Uygulanma Zamanı" },
        { Id: 416866, PropertyName: "value", Value: "[datafield:uygulanmazamani]" },
        { Id: 913311, PropertyName: "title", Value: "Opsiyon" },
        { Id: 393500, PropertyName: "value", Value: "[datafield:opsiyonaciklama]" },
        { Id: 637648, PropertyName: "title", Value: "Tekrar Bilgisi" },
        { Id: 628704, PropertyName: "value", Value: "[datafield:tekrarbilgisi]" },
        { Id: 396593, PropertyName: "title", Value: "Yapan Kurum" },
        { Id: 521859, PropertyName: "value", Value: "[datafield:yapankurum]" },
        { Id: 830114, PropertyName: "title", Value: "#" },
        { Id: 544738, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.taramatestilist_755660_value_kuikaTableRef = React.createRef();
    this.taramatestilist_692036_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      TaramaTestleriSearch: [],
      TaramaTestleriSearchCount: [],
      TaramaTestleriDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("taramatestilist", "taramatestilist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TaramaTestiListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("taramatestilist", "taramatestilist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("taramatestilist", "taramatestilist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TaramaTestiListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TaramaTestiListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TaramaTestiList/TaramaTestiListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestleriSearch = result?.data.taramaTestleriSearch;

    stateVars.TaramaTestleriSearchCount = result?.data.taramaTestleriSearchCount;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaramaTestleriSearchCount?.length > 0 ? stateVars.TaramaTestleriSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.TaramaTestleriSearch;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    formVars.taramatestilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TaramaTestiListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TaramaTestiList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "84872",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiListComponent_2755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiList/TaramaTestiListComponent_2755_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestleriSearch = result?.data.taramaTestleriSearch;

    stateVars.TaramaTestleriSearchCount = result?.data.taramaTestleriSearchCount;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaramaTestleriSearchCount?.length > 0 ? stateVars.TaramaTestleriSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiListComponent_2755_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiListComponent_2755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.TaramaTestleriSearch;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    formVars.taramatestilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "CuurentLang", "tr_TR");
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "WaitFor", "New");
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "TaramaTestID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "TaramaTestiTranslationID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TaramaTestiList",
      "TaramaTestiEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "909462",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TaramaTestiTranslation",
      "TaramaTestID",
      ReactSystemFunctions.value(this, "taramatestilist_755660_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TaramaTestiList",
      "TaramaTestiTranslation",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiListComponent_203570_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TaramaTestId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "taramatestilist_755660_value", "id"),
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ae091901_060e_f5cb_a2b3_b2e614800846_confirmation",
        this.defaultML,
        "Bu tarama testi ve translation bilgileri silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "TaramaTestiList/TaramaTestiListComponent_203570_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.TaramaTestleriDelete = result?.data.taramaTestleriDelete;
        stateVars.TaramaTestleriSearch = result?.data.taramaTestleriSearch;

        stateVars.TaramaTestleriSearchCount = result?.data.taramaTestleriSearchCount;
        formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
          this,
          stateVars.TaramaTestleriSearchCount?.length > 0 ? stateVars.TaramaTestleriSearchCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.TaramaTestiListComponent_203570_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiListComponent_203570_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.TaramaTestleriSearch;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    formVars.taramatestilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiListComponent_692036_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestilist_2755_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiList/TaramaTestiListComponent_692036_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestleriSearch = result?.data.taramaTestleriSearch;

    stateVars.TaramaTestleriSearchCount = result?.data.taramaTestleriSearchCount;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaramaTestleriSearchCount?.length > 0 ? stateVars.TaramaTestleriSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiListComponent_692036_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiListComponent_692036_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.TaramaTestleriSearch;
    formVars.taramatestilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    formVars.taramatestilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.TaramaTestleriSearchCount?.length > 0 ? this.state.TaramaTestleriSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [84872, 909462] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TaramaTestiListPageInit();
    }
  }
}
