import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanProfil_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanProfil_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  KullaniciProfil: any[];
  KullaniciProfil_dummy: any[];
  KullaniciBelgeByKullaniciId: any[];
  KullaniciBelgeByKullaniciId_dummy: any[];
  KullaniciAktifPasif: number;
  KullaniciAktifPasif_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
}

export class UzmanProfil_ScreenBase extends React.PureComponent<IUzmanProfil_ScreenProps, any> {
  uzmanprofil_241259_value_kuikaLocationPickerComponentRef: React.RefObject<any>;
  uzmanprofil_834137_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "e56c1d14-539c-4404-b3a2-498cad8da566",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 727074, PropertyName: "value", Value: "Profil" },
        { Id: 986905, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 178530, PropertyName: "label", Value: "Profil" },
        { Id: 728311, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 890458, PropertyName: "value", Value: "Email" },
        { Id: 415051, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 713887, PropertyName: "value", Value: "Adres" },
        { Id: 497561, PropertyName: "value", Value: "Erzene Mah. 777 Sok. No:14 Kat:4 Daire:8" },
        { Id: 990053, PropertyName: "value", Value: "Uzmanı pasif/aktif yap" },
        { Id: 669242, PropertyName: "value", Value: "Lokasyon" },
        { Id: 358064, PropertyName: "value", Value: "Uzmanlık Bilgileri" },
        { Id: 834137, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 41328, PropertyName: "title", Value: "Title" },
        { Id: 405780, PropertyName: "title", Value: "Title" },
        { Id: 168114, PropertyName: "value", Value: "[datafield:belgeadi]" },
        { Id: 961022, PropertyName: "title", Value: "Title" },
        { Id: 479028, PropertyName: "value", Value: "[datafield:belgeturu]" },
        { Id: 387494, PropertyName: "title", Value: "Title" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanprofil_241259_value_kuikaLocationPickerComponentRef = React.createRef();
    this.uzmanprofil_834137_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      KullaniciProfil: [],
      KullaniciBelgeByKullaniciId: [],
      KullaniciAktifPasif: 0,
      NAVIGATE: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanprofil", "uzmanprofil");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanProfilPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanprofil", "uzmanprofil");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanprofil", "uzmanprofil");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanProfilPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uzmanprofil_669505_value: this.state.KullaniciProfil?.at?.(0)?.profileFotoLink ?? undefined,
      uzmanprofil_33472_value: this.state.KullaniciProfil?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanProfilPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      KullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      ),
      KullaniciId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanProfil/UzmanProfilPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciProfil = result?.data.kullaniciProfil;
    formVars.uzmanprofil_669505_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.profileFotoLink : null
    );
    formVars.uzmanprofil_301708_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.unvanAdSoyad : null
    );
    formVars.uzmanprofil_815679_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.uzmanlikTanim : null
    );
    formVars.uzmanprofil_311747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0
        ? stateVars.KullaniciProfil[stateVars.KullaniciProfil.length - 1].username
        : null
    );
    formVars.uzmanprofil_902409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.telefon : null
    );
    formVars.uzmanprofil_33472_value = ReactSystemFunctions.value(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.isActive : null
    );
    formVars.uzmanprofil_241259_latitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.latitude : null
    );
    formVars.uzmanprofil_241259_longitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.longitude : null
    );
    stateVars.KullaniciBelgeByKullaniciId = result?.data.kullaniciBelgeByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanProfilPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanProfilPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uzmanprofil_669505_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.profileFotoLink : null
    );

    formVars.uzmanprofil_301708_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.unvanAdSoyad : null
    );

    formVars.uzmanprofil_815679_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uzmanlikTanim : null
    );

    formVars.uzmanprofil_311747_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0
        ? this.state.KullaniciProfil[this.state.KullaniciProfil.length - 1].username
        : null
    );

    formVars.uzmanprofil_902409_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.telefon : null
    );

    formVars.uzmanprofil_33472_value = ReactSystemFunctions.value(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.isActive : null
    );

    var value_241259 = {} as any;
    value_241259.latitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.latitude : null
    );
    value_241259.longitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.longitude : null
    );

    formVars.uzmanprofil_241259_value = value_241259;
    var value_241259 = {} as any;
    value_241259.latitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.latitude : null
    );
    value_241259.longitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.longitude : null
    );

    formVars.uzmanprofil_241259_value = value_241259;

    stateVars.dataSource_834137 = this.state.KullaniciBelgeByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanProfilComponent_991319_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanProfilComponent_33472_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanprofil_33472_value", "value", "", "", "")
        ),
        "boolean"
      ),
      KullaniciID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanProfil/UzmanProfilComponent_33472_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciAktifPasif = result?.data.kullaniciAktifPasif;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  UzmanProfilComponent_768478_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UzmanBelgeOnayView",
      "KullaniciBelgeID",
      ReactSystemFunctions.value(this, "uzmanprofil_834137_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanProfil",
      "UzmanBelgeOnayView",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "502035",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [502035] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UzmanProfilPageInit();
    }
  }
}
