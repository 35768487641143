import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISales_Dashboard_With_Left_Menu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISales_Dashboard_With_Left_Menu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class Sales_Dashboard_With_Left_Menu_ScreenBase extends React.PureComponent<
  ISales_Dashboard_With_Left_Menu_ScreenProps,
  any
> {
  sales_dashboard_with_left_menu_211246_value_kuikaSelectBoxRef: React.RefObject<any>;
  sales_dashboard_with_left_menu_907702_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "b23716d1-2a29-4cab-bc5c-7a5c442098bf",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 555450, PropertyName: "value", Value: "Dashboard" },
        { Id: 328969, PropertyName: "value", Value: "Managment" },
        { Id: 295156, PropertyName: "value", Value: "Document" },
        { Id: 650721, PropertyName: "value", Value: "Contact" },
        { Id: 562149, PropertyName: "value", Value: "Prospect" },
        { Id: 797188, PropertyName: "value", Value: "Workflow" },
        { Id: 848792, PropertyName: "value", Value: "Connection" },
        { Id: 431502, PropertyName: "value", Value: "Chat Integration" },
        { Id: 845382, PropertyName: "value", Value: "Marketing" },
        { Id: 120585, PropertyName: "value", Value: "Email Integration" },
        { Id: 43612, PropertyName: "value", Value: "Customer" },
        { Id: 649157, PropertyName: "value", Value: "Transaction" },
        { Id: 129688, PropertyName: "value", Value: "Maintenance" },
        { Id: 521286, PropertyName: "value", Value: "Sales Dashboard" },
        { Id: 245284, PropertyName: "label", Value: "My profile" },
        { Id: 973824, PropertyName: "label", Value: "Settings" },
        { Id: 134527, PropertyName: "label", Value: "Logout" },
        { Id: 938866, PropertyName: "placeholder", Value: "Select Month..." },
        { Id: 211246, PropertyName: "placeholder", Value: "All" },
        { Id: 907702, PropertyName: "placeholder", Value: "All" },
        { Id: 608542, PropertyName: "value", Value: "Total Account" },
        { Id: 292239, PropertyName: "value", Value: "2,104" },
        { Id: 462512, PropertyName: "value", Value: "20 %" },
        { Id: 781855, PropertyName: "value", Value: "vs previous 30 days" },
        { Id: 704790, PropertyName: "value", Value: "Orders per Month" },
        { Id: 625575, PropertyName: "value", Value: "37" },
        { Id: 944678, PropertyName: "value", Value: "15" },
        { Id: 489012, PropertyName: "value", Value: "vs previous 30 days" },
        { Id: 372902, PropertyName: "value", Value: "Average Contract" },
        { Id: 153543, PropertyName: "value", Value: "1,553" },
        { Id: 153281, PropertyName: "value", Value: "7,3 %" },
        { Id: 362187, PropertyName: "value", Value: "vs previous 30 days" },
        { Id: 765013, PropertyName: "value", Value: "Growth Rate" },
        { Id: 496668, PropertyName: "value", Value: "8.29%" },
        { Id: 376642, PropertyName: "value", Value: "1,3 %" },
        { Id: 743271, PropertyName: "value", Value: "vs previous 30 days" },
        { Id: 302383, PropertyName: "value", Value: "Sales Growth by Market Segment" },
        { Id: 496498, PropertyName: "value", Value: "Sales per Rep" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.sales_dashboard_with_left_menu_211246_value_kuikaSelectBoxRef = React.createRef();
    this.sales_dashboard_with_left_menu_907702_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sales_dashboard_with_left_menu", "sales_dashboard_with_left_menu");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("sales_dashboard_with_left_menu", "sales_dashboard_with_left_menu");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("sales_dashboard_with_left_menu", "sales_dashboard_with_left_menu");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
