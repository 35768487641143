import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanProfilEdit_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanProfilEdit_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  Languages: any[];
  Languages_dummy: any[];
  Kurumlar: any[];
  Kurumlar_dummy: any[];
  SecenekUzmanUnvanlar: any[];
  SecenekUzmanUnvanlar_dummy: any[];
  UzmanlikAlanlari: any[];
  UzmanlikAlanlari_dummy: any[];
  KullaniciProfil: any[];
  KullaniciProfil_dummy: any[];
  KullaniciBelgeByKullaniciId: any[];
  KullaniciBelgeByKullaniciId_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  KullaniciBelgeDelete: number;
  KullaniciBelgeDelete_dummy: number;
  KullaniciProfilUpdate: number;
  KullaniciProfilUpdate_dummy: number;
  isComp941776Enabled: "enabled" | "disabled";
}

export class UzmanProfilEdit_ScreenBase extends React.PureComponent<IUzmanProfilEdit_ScreenProps, any> {
  uzmanprofiledit_237312_value_kuikaDateRef: React.RefObject<any>;
  uzmanprofiledit_274867_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanprofiledit_516047_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanprofiledit_430979_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanprofiledit_398151_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanprofiledit_141964_value_kuikaLocationPickerComponentRef: React.RefObject<any>;
  uzmanprofiledit_834137_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6f6599b0-0054-4e23-b255-cc5b69c61b37",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 727074, PropertyName: "value", Value: "Profilim" },
        { Id: 986905, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 178530, PropertyName: "label", Value: "Profil" },
        { Id: 728311, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 815679, PropertyName: "value", Value: "Yükle" },
        { Id: 890458, PropertyName: "value", Value: "Ad" },
        { Id: 304303, PropertyName: "value", Value: "Soyad" },
        { Id: 726496, PropertyName: "value", Value: "DogumTarihi" },
        { Id: 237312, PropertyName: "placeholder", Value: "Tarih Seçiniz..." },
        { Id: 137641, PropertyName: "value", Value: "Email" },
        { Id: 392145, PropertyName: "value", Value: "Kurum" },
        { Id: 274867, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 787525, PropertyName: "value", Value: "Ünvan" },
        { Id: 516047, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 932054, PropertyName: "value", Value: "Uzmanlık Alanı" },
        { Id: 430979, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 415051, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 713887, PropertyName: "value", Value: "Adres" },
        { Id: 195468, PropertyName: "value", Value: "Dil Tercihi" },
        { Id: 398151, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 211588, PropertyName: "value", Value: "Lokasyon" },
        { Id: 878402, PropertyName: "value", Value: "Uzmanlık Belgeleri" },
        { Id: 602025, PropertyName: "value", Value: "Belge Ekle" },
        { Id: 834137, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 387494, PropertyName: "title", Value: "Title" },
        { Id: 405780, PropertyName: "title", Value: "Title" },
        { Id: 518245, PropertyName: "value", Value: "[datafield:belgeadi]" },
        { Id: 256658, PropertyName: "title", Value: "Title" },
        { Id: 751337, PropertyName: "label", Value: "Geri" },
        { Id: 409507, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanprofiledit_237312_value_kuikaDateRef = React.createRef();
    this.uzmanprofiledit_274867_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanprofiledit_516047_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanprofiledit_430979_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanprofiledit_398151_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanprofiledit_141964_value_kuikaLocationPickerComponentRef = React.createRef();
    this.uzmanprofiledit_834137_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      Languages: [],
      Kurumlar: [],
      SecenekUzmanUnvanlar: [],
      UzmanlikAlanlari: [],
      KullaniciProfil: [],
      KullaniciBelgeByKullaniciId: [],
      ChangeEnabledOf: "",
      NAVIGATE: "",
      KullaniciBelgeDelete: 0,
      KullaniciProfilUpdate: 0,
      isComp941776Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanprofiledit", "uzmanprofiledit");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanProfilEditPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanprofiledit", "uzmanprofiledit");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanprofiledit", "uzmanprofiledit");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanProfilEditPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uzmanprofiledit_763451_value: this.state.KullaniciProfil?.at?.(0)?.ad ?? undefined,
      uzmanprofiledit_280183_value: this.state.KullaniciProfil?.at?.(0)?.soyad ?? undefined,
      uzmanprofiledit_237312_value: this.state.KullaniciProfil?.at?.(-1)?.dogumTarihi ?? undefined,
      uzmanprofiledit_941776_value: this.state.KullaniciProfil?.at?.(0)?.username ?? undefined,
      uzmanprofiledit_274867_value: this.state.KullaniciProfil?.at?.(0)?.kurumId ?? undefined,
      uzmanprofiledit_516047_value: this.state.KullaniciProfil?.at?.(0)?.unvan ?? undefined,
      uzmanprofiledit_430979_value: this.state.KullaniciProfil?.at?.(0)?.uzmanlikAlaniId ?? undefined,
      uzmanprofiledit_848638_value: this.state.KullaniciProfil?.at?.(0)?.telefon ?? undefined,
      uzmanprofiledit_673334_value: this.state.KullaniciProfil?.at?.(0)?.adres ?? undefined,
      uzmanprofiledit_398151_value: this.state.KullaniciProfil?.at?.(0)?.dilSecimi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanProfilEditPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      Lang_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      Lang_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      KullaniciId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      ),
      KullaniciId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanProfilEdit/UzmanProfilEditPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Languages = result?.data.languages;

    formVars.uzmanprofiledit_398151_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.dilSecimi : null;
    formVars.uzmanprofiledit_398151_options = stateVars.Languages;
    stateVars.Kurumlar = result?.data.kurumlar;

    formVars.uzmanprofiledit_274867_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.kurumId : null;
    formVars.uzmanprofiledit_274867_options = stateVars.Kurumlar;
    stateVars.SecenekUzmanUnvanlar = result?.data.secenekUzmanUnvanlar;

    formVars.uzmanprofiledit_516047_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.unvan : null;
    formVars.uzmanprofiledit_516047_options = stateVars.SecenekUzmanUnvanlar;
    stateVars.UzmanlikAlanlari = result?.data.uzmanlikAlanlari;

    formVars.uzmanprofiledit_430979_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.uzmanlikAlaniId : null;
    formVars.uzmanprofiledit_430979_options = stateVars.UzmanlikAlanlari;
    stateVars.KullaniciProfil = result?.data.kullaniciProfil;
    formVars.uzmanprofiledit_763451_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.ad : null
    );
    formVars.uzmanprofiledit_280183_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.soyad : null
    );
    formVars.uzmanprofiledit_237312_value = ReactSystemFunctions.value(
      this,
      stateVars.KullaniciProfil?.length > 0
        ? stateVars.KullaniciProfil[stateVars.KullaniciProfil.length - 1].dogumTarihi
        : null
    );
    formVars.uzmanprofiledit_941776_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.username : null
    );
    formVars.uzmanprofiledit_274867_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.kurumId : null;
    formVars.uzmanprofiledit_274867_options = stateVars.Kurumlar;
    formVars.uzmanprofiledit_516047_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.unvan : null;
    formVars.uzmanprofiledit_516047_options = stateVars.SecenekUzmanUnvanlar;
    formVars.uzmanprofiledit_430979_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.uzmanlikAlaniId : null;
    formVars.uzmanprofiledit_430979_options = stateVars.UzmanlikAlanlari;
    formVars.uzmanprofiledit_848638_value = ReactSystemFunctions.toInteger(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.telefon : null
    );
    formVars.uzmanprofiledit_673334_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.adres : null
    );
    formVars.uzmanprofiledit_398151_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.dilSecimi : null;
    formVars.uzmanprofiledit_398151_options = stateVars.Languages;
    formVars.uzmanprofiledit_141964_latitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.latitude : null
    );
    formVars.uzmanprofiledit_141964_longitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.longitude : null
    );
    stateVars.KullaniciBelgeByKullaniciId = result?.data.kullaniciBelgeByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanProfilEditPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanProfilEditPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp941776Enabled", "disabled");
    formVars.uzmanprofiledit_763451_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.ad : null
    );

    formVars.uzmanprofiledit_280183_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.soyad : null
    );

    formVars.uzmanprofiledit_237312_value = ReactSystemFunctions.value(
      this,
      this.state.KullaniciProfil?.length > 0
        ? this.state.KullaniciProfil[this.state.KullaniciProfil.length - 1].dogumTarihi
        : null
    );

    formVars.uzmanprofiledit_941776_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.username : null
    );

    formVars.uzmanprofiledit_274867_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.kurumId : null
    );

    stateVars.dataSource_274867 = this.state.Kurumlar;
    stateVars.dataSource_274867 = this.state.Kurumlar;
    formVars.uzmanprofiledit_516047_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.unvan : null
    );

    stateVars.dataSource_516047 = this.state.SecenekUzmanUnvanlar;
    stateVars.dataSource_516047 = this.state.SecenekUzmanUnvanlar;
    formVars.uzmanprofiledit_430979_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uzmanlikAlaniId : null
    );

    stateVars.dataSource_430979 = this.state.UzmanlikAlanlari;
    stateVars.dataSource_430979 = this.state.UzmanlikAlanlari;
    formVars.uzmanprofiledit_848638_value = ReactSystemFunctions.toInteger(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.telefon : null
    );

    formVars.uzmanprofiledit_673334_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.adres : null
    );

    formVars.uzmanprofiledit_398151_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.dilSecimi : null
    );

    stateVars.dataSource_398151 = this.state.Languages;
    stateVars.dataSource_398151 = this.state.Languages;
    var value_141964 = {} as any;
    value_141964.latitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.latitude : null
    );
    value_141964.longitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.longitude : null
    );

    formVars.uzmanprofiledit_141964_value = value_141964;
    var value_141964 = {} as any;
    value_141964.latitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.latitude : null
    );
    value_141964.longitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.longitude : null
    );

    formVars.uzmanprofiledit_141964_value = value_141964;

    stateVars.dataSource_834137 = this.state.KullaniciBelgeByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanProfilEditComponent_991319_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanProfilEditComponent_602025_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BelgeEkle",
      "UzmanID",
      this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid
    );
    KuikaAppManager.addToPageInputVariables(
      "BelgeEkle",
      "CurrentLang",
      this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang
    );
    KuikaAppManager.addToPageInputVariables("BelgeEkle", "BelgeID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanProfilEdit",
      "BelgeEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "60143",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanProfilEditComponent_252522_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BelgeEkle",
      "UzmanID",
      this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid
    );
    KuikaAppManager.addToPageInputVariables(
      "BelgeEkle",
      "CurrentLang",
      this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang
    );
    KuikaAppManager.addToPageInputVariables(
      "BelgeEkle",
      "BelgeID",
      ReactSystemFunctions.value(this, "uzmanprofiledit_834137_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanProfilEdit",
      "BelgeEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "846265",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanProfilEditComponent_653317_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "uzmanprofiledit_834137_value", "id"),
        "Guid"
      ),
      KullaniciId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c01787ff_84b2_8005_03cf_1db7df458d0b_confirmation",
        this.defaultML,
        "Bu belge silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "UzmanProfilEdit/UzmanProfilEditComponent_653317_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.KullaniciBelgeDelete = result?.data.kullaniciBelgeDelete;
        stateVars.KullaniciBelgeByKullaniciId = result?.data.kullaniciBelgeByKullaniciId;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.UzmanProfilEditComponent_653317_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanProfilEditComponent_653317_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_834137 = this.state.KullaniciBelgeByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanProfilEditComponent_751337_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanProfilEditComponent_409507_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_673334_value", "value", "", "", "")
        ),
        "string"
      ),
      DilSecimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_398151_value", "value", "Languages", "kod", "kod")
        ),
        "string"
      ),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_274867_value", "value", "Kurumlar", "id", "id")
        ),
        "Guid"
      ),
      latitude_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_141964_value", "Latitude", "", "", "")
        ),
        "string"
      ),
      longitude_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_141964_value", "Longitude", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_848638_value", "value", "", "", "")
        ),
        "string"
      ),
      Unvan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanprofiledit_516047_value",
            "value",
            "SecenekUzmanUnvanlar",
            "kisaltma",
            "kisaltma"
          )
        ),
        "string"
      ),
      Username_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UzmanlikAlaniId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "uzmanprofiledit_430979_value",
            "value",
            "UzmanlikAlanlari",
            "uzmanlikAlaniId",
            "uzmanlikAlaniId"
          )
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      ),
      Ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_763451_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_280183_value", "value", "", "", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanprofiledit_237312_value", "value", "", "", "")
        ),
        "Date"
      ),
      KullaniciId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      KullaniciId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanProfilEdit/UzmanProfilEditComponent_409507_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciProfilUpdate = result?.data.kullaniciProfilUpdate;
    stateVars.KullaniciBelgeByKullaniciId = result?.data.kullaniciBelgeByKullaniciId;

    stateVars.KullaniciProfil = result?.data.kullaniciProfil;
    formVars.uzmanprofiledit_763451_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.ad : null
    );
    formVars.uzmanprofiledit_280183_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.soyad : null
    );
    formVars.uzmanprofiledit_237312_value = ReactSystemFunctions.value(
      this,
      stateVars.KullaniciProfil?.length > 0
        ? stateVars.KullaniciProfil[stateVars.KullaniciProfil.length - 1].dogumTarihi
        : null
    );
    formVars.uzmanprofiledit_941776_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.username : null
    );
    formVars.uzmanprofiledit_274867_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.kurumId : null;
    formVars.uzmanprofiledit_516047_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.unvan : null;
    formVars.uzmanprofiledit_430979_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.uzmanlikAlaniId : null;
    formVars.uzmanprofiledit_848638_value = ReactSystemFunctions.toInteger(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.telefon : null
    );
    formVars.uzmanprofiledit_673334_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.adres : null
    );
    formVars.uzmanprofiledit_398151_value =
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.dilSecimi : null;
    formVars.uzmanprofiledit_141964_latitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.latitude : null
    );
    formVars.uzmanprofiledit_141964_longitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.KullaniciProfil?.length > 0 ? stateVars.KullaniciProfil[0]?.longitude : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanProfilEditComponent_409507_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanProfilEditComponent_409507_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uzmanprofiledit_763451_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.ad : null
    );

    formVars.uzmanprofiledit_280183_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.soyad : null
    );

    formVars.uzmanprofiledit_237312_value = ReactSystemFunctions.value(
      this,
      this.state.KullaniciProfil?.length > 0
        ? this.state.KullaniciProfil[this.state.KullaniciProfil.length - 1].dogumTarihi
        : null
    );

    formVars.uzmanprofiledit_941776_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.username : null
    );

    formVars.uzmanprofiledit_274867_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.kurumId : null
    );

    formVars.uzmanprofiledit_516047_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.unvan : null
    );

    formVars.uzmanprofiledit_430979_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.uzmanlikAlaniId : null
    );

    formVars.uzmanprofiledit_848638_value = ReactSystemFunctions.toInteger(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.telefon : null
    );

    formVars.uzmanprofiledit_673334_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.adres : null
    );

    formVars.uzmanprofiledit_398151_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.dilSecimi : null
    );

    var value_141964 = {} as any;
    value_141964.latitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.latitude : null
    );
    value_141964.longitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.longitude : null
    );

    formVars.uzmanprofiledit_141964_value = value_141964;
    var value_141964 = {} as any;
    value_141964.latitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.latitude : null
    );
    value_141964.longitude = ReactSystemFunctions.toDecimal(
      this,
      this.state.KullaniciProfil?.length > 0 ? this.state.KullaniciProfil[0]?.longitude : null
    );

    formVars.uzmanprofiledit_141964_value = value_141964;

    stateVars.dataSource_834137 = this.state.KullaniciBelgeByKullaniciId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [60143, 846265] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UzmanProfilEditPageInit();
    }
  }
}
