import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  UzmanSearch: any[];
  UzmanSearch_dummy: any[];
  UzmanSearchCount: any[];
  UzmanSearchCount_dummy: any[];
}

export class UzmanList_ScreenBase extends React.PureComponent<IUzmanList_ScreenProps, any> {
  uzmanlist_814341_value_kuikaSelectBoxRef: React.RefObject<any>;
  uzmanlist_755660_value_kuikaTableRef: React.RefObject<any>;
  uzmanlist_692036_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "98783667-5f06-4e57-828a-25d724891f3e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Uzman Listesi" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 814341, PropertyName: "placeholder", Value: "Filtrele" },
        { Id: 718191, PropertyName: "label", Value: "Yeni Uzman Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 455307, PropertyName: "title", Value: "Aktif" },
        { Id: 958529, PropertyName: "title", Value: "Uzman" },
        { Id: 5169, PropertyName: "value", Value: "[datafield:unvanadsoyad]" },
        { Id: 639777, PropertyName: "title", Value: "Uzmanlık Alanı" },
        { Id: 889056, PropertyName: "value", Value: "[datafield:uzmanliktanim]" },
        { Id: 736103, PropertyName: "title", Value: "Telefon" },
        { Id: 416866, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 662815, PropertyName: "title", Value: "Eposta" },
        { Id: 814345, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 777129, PropertyName: "title", Value: "Eklenme Tarihi" },
        { Id: 232569, PropertyName: "value", Value: "[datafield:eklenmetarihi]" },
        { Id: 416892, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanlist_814341_value_kuikaSelectBoxRef = React.createRef();
    this.uzmanlist_755660_value_kuikaTableRef = React.createRef();
    this.uzmanlist_692036_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      UzmanSearch: [],
      UzmanSearchCount: [],
      dataSource_814341: [
        { key: "true", text: "Aktif" },
        { key: "false", text: "Pasif" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanlist", "uzmanlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanlist", "uzmanlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanlist", "uzmanlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanList/UzmanListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanSearch = result?.data.uzmanSearch;

    stateVars.UzmanSearchCount = result?.data.uzmanSearchCount;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanSearchCount?.length > 0 ? stateVars.UzmanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanSearch;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    formVars.uzmanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "250739",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanListComponent_2755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanList/UzmanListComponent_2755_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanSearch = result?.data.uzmanSearch;

    stateVars.UzmanSearchCount = result?.data.uzmanSearchCount;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanSearchCount?.length > 0 ? stateVars.UzmanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanListComponent_2755_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanListComponent_2755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanSearch;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    formVars.uzmanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanListComponent_814341_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanList/UzmanListComponent_814341_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanSearch = result?.data.uzmanSearch;

    stateVars.UzmanSearchCount = result?.data.uzmanSearchCount;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanSearchCount?.length > 0 ? stateVars.UzmanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanListComponent_814341_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanListComponent_814341_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanSearch;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    formVars.uzmanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UzmanEkle", "UzmanID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("UzmanEkle", "CurrentLang", "tr_TR");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanList",
      "UzmanEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "539832",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UzmanProfil",
      "UzmanID",
      ReactSystemFunctions.value(this, "uzmanlist_755660_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "UzmanProfil",
      "CurrentLang",
      ReactSystemFunctions.value(this, "uzmanlist_755660_value", "dilSecimi")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UzmanList",
      "UzmanProfil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanListComponent_692036_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "uzmanlist_2755_value", "value", "", "", "")),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "uzmanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanList/UzmanListComponent_692036_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanSearch = result?.data.uzmanSearch;

    stateVars.UzmanSearchCount = result?.data.uzmanSearchCount;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.UzmanSearchCount?.length > 0 ? stateVars.UzmanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanListComponent_692036_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanListComponent_692036_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.UzmanSearch;
    formVars.uzmanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    formVars.uzmanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.UzmanSearchCount?.length > 0 ? this.state.UzmanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [250739, 539832] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UzmanListPageInit();
    }
  }
}
