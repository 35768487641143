import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISemptomTransList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISemptomTransList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SemptomTransBySemptomId: any[];
  SemptomTransBySemptomId_dummy: any[];
  SemptomTransKontrol: number;
  SemptomTransKontrol_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SemptomTransDelete: number;
  SemptomTransDelete_dummy: number;
}

export class SemptomTransList_ScreenBase extends React.PureComponent<ISemptomTransList_ScreenProps, any> {
  semptomtranslist_409349_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "a53bcb95-f36f-4a79-a366-8a00e3dda85b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 218538, PropertyName: "value", Value: "Semptom Translation" },
        { Id: 311084, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 883052, PropertyName: "label", Value: "Translation Ekle" },
        { Id: 107534, PropertyName: "value", Value: "Vücut Bölgesi :" },
        { Id: 409349, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 498198, PropertyName: "title", Value: "Language" },
        { Id: 328146, PropertyName: "value", Value: "[datafield:lang]" },
        { Id: 279267, PropertyName: "title", Value: "Translation" },
        { Id: 826303, PropertyName: "value", Value: "[datafield:tanim]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.semptomtranslist_409349_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SemptomTransBySemptomId: [],
      SemptomTransKontrol: 0,
      NAVIGATE: "",
      SemptomTransDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("semptomtranslist", "semptomtranslist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SemptomTransListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("semptomtranslist", "semptomtranslist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("semptomtranslist", "semptomtranslist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SemptomTransListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SemptomTransListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      SemptomId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomTransList/SemptomTransListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomTransBySemptomId = result?.data.semptomTransBySemptomId;

    formVars.semptomtranslist_458637_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomTransBySemptomId?.length > 0 ? stateVars.SemptomTransBySemptomId[0]?.vucutBolgesi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomTransListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomTransListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.semptomtranslist_458637_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomTransBySemptomId?.length > 0 ? this.state.SemptomTransBySemptomId[0]?.vucutBolgesi : null
    );

    stateVars.dataSource_409349 = this.state.SemptomTransBySemptomId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SemptomTransListComponent_199167_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomTransListComponent_883052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SemptomId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomTransList/SemptomTransListComponent_883052_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomTransKontrol = result?.data.semptomTransKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomTransListComponent_883052_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomTransListComponent_883052_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "CurrentLang", "");
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "WaitFor", "Add");
    KuikaAppManager.addToPageInputVariables(
      "SemptomEkle",
      "SemptomID",
      this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid
    );
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "SemptomTranslationID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "isVBEditable", false);
    KuikaAppManager.addToPageInputVariables(
      "SemptomEkle",
      "VucutBolgeID",
      this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomTransList",
      "SemptomEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "145966",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomTransListComponent_595047_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "lang"), "tr_TR")
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "SemptomEkle",
        "CurrentLang",
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("SemptomEkle", "WaitFor", "New");
      KuikaAppManager.addToPageInputVariables(
        "SemptomEkle",
        "SemptomID",
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "semptomId")
      );
      KuikaAppManager.addToPageInputVariables(
        "SemptomEkle",
        "SemptomTranslationID",
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "id")
      );
      KuikaAppManager.addToPageInputVariables("SemptomEkle", "isVBEditable", false);
      KuikaAppManager.addToPageInputVariables("SemptomEkle", "VucutBolgeID", null);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.SemptomTransListComponent_595047_onClick1_,
        "SemptomTransList",
        "SemptomEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "271718",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.SemptomTransListComponent_595047_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  SemptomTransListComponent_595047_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "SemptomEkle",
        "CurrentLang",
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("SemptomEkle", "WaitFor", "Edit");
      KuikaAppManager.addToPageInputVariables(
        "SemptomEkle",
        "SemptomID",
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "semptomId")
      );
      KuikaAppManager.addToPageInputVariables(
        "SemptomEkle",
        "SemptomTranslationID",
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "id")
      );
      KuikaAppManager.addToPageInputVariables("SemptomEkle", "isVBEditable", false);
      KuikaAppManager.addToPageInputVariables("SemptomEkle", "VucutBolgeID", null);

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "SemptomTransList",
        "SemptomEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "728269",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SemptomTransListComponent_201870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "semptomtranslist_409349_value", "id"),
        "Guid"
      ),
      SemptomId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "61ca41ca_065a_9441_03b8_fa654d2051dd_confirmation",
        this.defaultML,
        "Bu translation silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "SemptomTransList/SemptomTransListComponent_201870_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.SemptomTransDelete = result?.data.semptomTransDelete;
        stateVars.SemptomTransBySemptomId = result?.data.semptomTransBySemptomId;

        formVars.semptomtranslist_458637_value = ReactSystemFunctions.toString(
          this,
          stateVars.SemptomTransBySemptomId?.length > 0 ? stateVars.SemptomTransBySemptomId[0]?.vucutBolgesi : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SemptomTransListComponent_201870_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomTransListComponent_201870_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.semptomtranslist_458637_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomTransBySemptomId?.length > 0 ? this.state.SemptomTransBySemptomId[0]?.vucutBolgesi : null
    );

    stateVars.dataSource_409349 = this.state.SemptomTransBySemptomId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [145966, 271718, 728269] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SemptomTransListPageInit();
    }
    if (diId == 271718) {
      isErrorOccurred = await this.SemptomTransListComponent_595047_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
