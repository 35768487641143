import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITaramaTestiTranslation_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITaramaTestiTranslation_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  TaramaTestleriTransByTaramaTestId: any[];
  TaramaTestleriTransByTaramaTestId_dummy: any[];
  TaramaTestleriTransKontrol: number;
  TaramaTestleriTransKontrol_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  TaramaTestleriTransDelete: number;
  TaramaTestleriTransDelete_dummy: number;
}

export class TaramaTestiTranslation_ScreenBase extends React.PureComponent<ITaramaTestiTranslation_ScreenProps, any> {
  taramatestitranslation_409349_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "4419fa8f-ee79-4c34-8651-7c5591c12c49",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 218538, PropertyName: "value", Value: "Aşı Translations" },
        { Id: 311084, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 883052, PropertyName: "label", Value: "Translation Ekle" },
        { Id: 409349, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 498198, PropertyName: "title", Value: "Language" },
        { Id: 328146, PropertyName: "value", Value: "[datafield:lang]" },
        { Id: 279267, PropertyName: "title", Value: "Translation" },
        { Id: 826303, PropertyName: "value", Value: "[datafield:tanim]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.taramatestitranslation_409349_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      TaramaTestleriTransByTaramaTestId: [],
      TaramaTestleriTransKontrol: 0,
      NAVIGATE: "",
      TaramaTestleriTransDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("taramatestitranslation", "taramatestitranslation");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TaramaTestiTranslationPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("taramatestitranslation", "taramatestitranslation");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("taramatestitranslation", "taramatestitranslation");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TaramaTestiTranslationPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TaramaTestiTranslationPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      TaramaTestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiTranslation/TaramaTestiTranslationPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestleriTransByTaramaTestId = result?.data.taramaTestleriTransByTaramaTestId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiTranslationPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiTranslationPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_409349 = this.state.TaramaTestleriTransByTaramaTestId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TaramaTestiTranslationComponent_199167_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiTranslationComponent_883052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TaramaTestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiTranslation/TaramaTestiTranslationComponent_883052_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestleriTransKontrol = result?.data.taramaTestleriTransKontrol;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiTranslationComponent_883052_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiTranslationComponent_883052_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "CuurentLang", "");
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "WaitFor", "Add");
    KuikaAppManager.addToPageInputVariables(
      "TaramaTestiEkle",
      "TaramaTestID",
      this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid
    );
    KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "TaramaTestiTranslationID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TaramaTestiTranslation",
      "TaramaTestiEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "732640",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiTranslationComponent_595047_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "TaramaTestiEkle",
        "CuurentLang",
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "WaitFor", "New");
      KuikaAppManager.addToPageInputVariables(
        "TaramaTestiEkle",
        "TaramaTestID",
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "taramaTestId")
      );
      KuikaAppManager.addToPageInputVariables(
        "TaramaTestiEkle",
        "TaramaTestiTranslationID",
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.TaramaTestiTranslationComponent_595047_onClick1_,
        "TaramaTestiTranslation",
        "TaramaTestiEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "939704",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.TaramaTestiTranslationComponent_595047_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  TaramaTestiTranslationComponent_595047_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "lang"),
        "tr_TR"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "TaramaTestiEkle",
        "CuurentLang",
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "lang")
      );
      KuikaAppManager.addToPageInputVariables("TaramaTestiEkle", "WaitFor", "Edit");
      KuikaAppManager.addToPageInputVariables(
        "TaramaTestiEkle",
        "TaramaTestID",
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "taramaTestId")
      );
      KuikaAppManager.addToPageInputVariables(
        "TaramaTestiEkle",
        "TaramaTestiTranslationID",
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "id")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "TaramaTestiTranslation",
        "TaramaTestiEkle",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "255570",
        null,
        "right",
        null,
        "380px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TaramaTestiTranslationComponent_201870_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "taramatestitranslation_409349_value", "id"),
        "Guid"
      ),
      TaramaTestId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "86573ee2_3367_2bdc_3687_4a392d195981_confirmation",
        this.defaultML,
        "Bu translation silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "TaramaTestiTranslation/TaramaTestiTranslationComponent_201870_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.TaramaTestleriTransDelete = result?.data.taramaTestleriTransDelete;
        stateVars.TaramaTestleriTransByTaramaTestId = result?.data.taramaTestleriTransByTaramaTestId;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.TaramaTestiTranslationComponent_201870_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiTranslationComponent_201870_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_409349 = this.state.TaramaTestleriTransByTaramaTestId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [732640, 939704, 255570] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TaramaTestiTranslationPageInit();
    }
    if (diId == 939704) {
      isErrorOccurred = await this.TaramaTestiTranslationComponent_595047_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
