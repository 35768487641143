import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDanisanEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDanisanEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  Languages: any[];
  Languages_dummy: any[];
  UyelikPlanByTanim: any[];
  UyelikPlanByTanim_dummy: any[];
  DanisanEkle: number;
  DanisanEkle_dummy: number;
  CreateUser: string;
  CreateUser_dummy: string;
  UpdateKUserLanguage: number;
  UpdateKUserLanguage_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
}

export class DanisanEkle_ScreenBase extends React.PureComponent<IDanisanEkle_ScreenProps, any> {
  danisanekle_632663_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "92c3f4fc-0f38-46b9-bfac-45cccabf4268",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Danışan" },
        { Id: 429915, PropertyName: "value", Value: "Ad" },
        { Id: 222573, PropertyName: "value", Value: "Soyad" },
        { Id: 430215, PropertyName: "value", Value: "Email" },
        { Id: 61543, PropertyName: "value", Value: "Dil Seçimi" },
        { Id: 632663, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 856375, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.danisanekle_632663_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      Languages: [],
      UyelikPlanByTanim: [],
      DanisanEkle: 0,
      CreateUser: "",
      UpdateKUserLanguage: 0,
      Notify: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("danisanekle", "danisanekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DanisanEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("danisanekle", "danisanekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("danisanekle", "danisanekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DanisanEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DanisanEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanEkle/DanisanEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Languages = result?.data.languages;

    formVars.danisanekle_632663_options = stateVars.Languages;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_632663 = this.state.Languages;
    stateVars.dataSource_632663 = this.state.Languages;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DanisanEkleComponent_395018_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanEkleComponent_856375_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_913843_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "danisanekle_913843_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_74554_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_74554_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_519196_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_519196_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tanim_0: ReactSystemFunctions.convertToTypeByName("Freemium", "string"),
      DanisanId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DanisanID ?? this.props.screenInputs.danisanid,
        "Guid"
      ),
      Username_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        "string"
      ),
      DilSecimi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_632663_value", "value", "Languages", "kod", "kod")
        ),
        "string"
      ),
      IsActive_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Ad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanekle_74554_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanekle_519196_value", "value", "", "", "")
        ),
        "string"
      ),
      Yakinlik_1: ReactSystemFunctions.convertToTypeByName("Kendisi", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanEkle/DanisanEkleComponent_856375_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlanByTanim = result?.data.uyelikPlanByTanim;
    stateVars.DanisanEkle = result?.data.danisanEkle;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanEkleComponent_856375_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanEkleComponent_856375_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_913843_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "danisanekle_913843_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_74554_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_74554_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_519196_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_519196_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.CreateUser = await ReactSystemFunctions.createUser(
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
      ),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "danisanekle_74554_value", "value", "", "", "")
      ),
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "danisanekle_519196_value", "value", "", "", "")
      ),
      false,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanEkleComponent_856375_onClick2_();
    });

    return isErrorOccurred;
  };
  DanisanEkleComponent_856375_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "danisanekle_913843_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "danisanekle_913843_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "danisanekle_632663_value", "value", "Languages", "kod", "kod")
        ),
        "string"
      ),
      Username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanekle_913843_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanEkle/DanisanEkleComponent_856375_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateKUserLanguage = result?.data.updateKUserLanguage;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanEkleComponent_856375_onClick3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanEkleComponent_856375_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f8177166_26e9_8f68_6796_48c73a13973d_notify",
        this.defaultML,
        "Yeni bir danışan eklediniz. Verdiğiniz mail adresine bilgilendirme yapılmıştır."
      ),
      "default",
      "bottom-right",
      0
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
