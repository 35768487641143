import { Form, Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KContentMenu,
  KContentMenuItem,
  KContentMenuPanel,
  KDrawer,
  KPagination,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  TextInput
} from "../../components/web-components";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SemptomOneriList_ScreenBase } from "./semptomonerilist-base";

import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { SemptomOneriEkle_Screen } from "../semptomoneriekle/semptomoneriekle";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class SemptomOneriList_Screen extends SemptomOneriList_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              semptomonerilist_981733_value: "c3563b0b-3fcb-4cb7-dd9d-08dbb761beb2",
              semptomonerilist_523282_value: undefined
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="semptomonerilist_header"
                style={
                  {
                    borderBottomRightRadius: 12,
                    borderBottomLeftRadius: 12,
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(16, 64, 216, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(68, 68, 68, 1)"
                  } as any
                }
              >
                <KRow
                  id="853317"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="94379"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="260378"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="716309"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SemptomOneriListComponent_716309_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "24px",
                            color: "rgba(255, 255, 255, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="460315"
                        value={ReactSystemFunctions.translate(this.ml, 460315, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "18px",
                            color: "rgba(255, 255, 255, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="103614"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KContentMenu
                      id="172563"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KContentMenuPanel
                        id="579890"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="588696"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="semptomonerilist_981733_value">
                            <Image
                              id="981733"
                              zoomOnClick={false}
                              imageFit="fill"
                              style={
                                {
                                  borderTopLeftRadius: 200,
                                  borderTopRightRadius: 200,
                                  borderBottomRightRadius: 200,
                                  borderBottomLeftRadius: 200,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  width: "32px",
                                  height: "32px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            ></Image>
                          </Form.Item>

                          <Label
                            id="99575"
                            value={ReactSystemFunctions.translate(this.ml, 99575, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(255, 255, 255, 1)"
                              } as any
                            }
                          ></Label>

                          <Icon
                            id="135061"
                            iconName="keyboard_arrow_down"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "24px",
                                color: "rgba(255, 255, 255, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </HorizontalStack>
                      </KContentMenuPanel>

                      <KContentMenuItem
                        id="727721"
                        label={ReactSystemFunctions.translate(this.ml, 727721, "label", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      ></KContentMenuItem>

                      <KContentMenuItem
                        id="363060"
                        label={ReactSystemFunctions.translate(this.ml, 363060, "label", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      ></KContentMenuItem>
                    </KContentMenu>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="semptomonerilist_body"
                style={
                  {
                    backgroundColor: "rgba(243, 245, 249, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="809262"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="253290"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="540204"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="6127"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)"
                          } as any
                        }
                      >
                        <KRow
                          id="734783"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="388344"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="semptomonerilist_523282_value">
                              <TextInput
                                id="523282"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.SemptomOneriListComponent_523282_onChange();
                                }}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  523282,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    width: "240px",
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="924672"
                            xs={16}
                            sm={16}
                            md={16}
                            lg={16}
                            xl={16}
                            xxl={16}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <Button
                              id="206178"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SemptomOneriListComponent_206178_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 206178, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 16,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(54, 112, 246, 1)",
                                  lineHeight: "22px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>

                        <KRow
                          id="924499"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="40691"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KTable
                              id="423262"
                              kuikaRef={this.semptomonerilist_423262_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.SemptomOneriList}
                              size="middle"
                              bordered={true}
                              showHeader={true}
                              loading={false}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                423262,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              sorter={false}
                              pagination={false}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="345323"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(155, 155, 155, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="33403"
                                hoverFontColor="red"
                                hoverBgColor="#F5F5F5"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="789566"
                                  title={ReactSystemFunctions.translate(this.ml, 789566, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "10%",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="665474"
                                    value="[datafield:vucutBolgesi]"
                                    maxLine={1}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="778970"
                                  title={ReactSystemFunctions.translate(this.ml, 778970, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "20%",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="7718"
                                    value="[datafield:semptomNames]"
                                    maxLine={1}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="805800"
                                  title={ReactSystemFunctions.translate(this.ml, 805800, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "20%",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="539459"
                                    value="[datafield:tanimlama]"
                                    maxLine={1}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="512916"
                                  title={ReactSystemFunctions.translate(this.ml, 512916, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "20%",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="562891"
                                    value="[datafield:bakimOnerisi]"
                                    maxLine={1}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="868687"
                                  title={ReactSystemFunctions.translate(this.ml, 868687, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "20%",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="615936"
                                    value="[datafield:neZamanAramali]"
                                    maxLine={1}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="695429"
                                  title={ReactSystemFunctions.translate(this.ml, 695429, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      width: "10%",
                                      alignItems: "center",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="227965"
                                    direction="horizontal"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="647460"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.SemptomOneriListComponent_647460_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="remove_red_eye"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "24px",
                                          color: "rgba(24, 144, 255, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="13366"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.SemptomOneriListComponent_13366_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="delete_outline"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "24px",
                                          color: "rgba(245, 34, 45, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </HorizontalStack>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>
                        </KRow>

                        <KRow
                          id="357329"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 8,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="622628"
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="727708"
                              direction="horizontal"
                              size={6}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="988510"
                                value={ReactSystemFunctions.translate(this.ml, 988510, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="383227"
                                value={this.state.SemptomOneriListCount?.at?.(0)?.count ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </KCol>

                          <KCol
                            id="86403"
                            xs={18}
                            sm={18}
                            md={18}
                            lg={18}
                            xl={18}
                            xxl={18}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)"
                              } as any
                            }
                          >
                            <KPagination
                              id="40606"
                              onChange={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SemptomOneriListComponent_40606_onChange();
                              }}
                              kuikaRef={this.semptomonerilist_40606_value_kuikaPaginationRef}
                              total={this.state.SemptomOneriListCount?.at?.(0)?.count ?? undefined}
                              pageSize={15}
                              defaultCurrent={1}
                              current={1}
                              defaultPageSize={10}
                              disabled={false}
                              hideOnSinglePage={true}
                              showLessItems={false}
                              showQuickJumper={false}
                              showSizeChanger={false}
                              showFirstPageButton={false}
                              showLastPageButton={false}
                              showNumbers={true}
                              showText={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0
                                } as any
                              }
                            ></KPagination>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="107215"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible107215: false }, () => {
                      this.callCallbackFunction(107215);
                    });
                  }}
                  visible={this.state.NavVisible107215}
                  width="800px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible107215 && (
                      <SemptomOneriEkle_Screen
                        onClose={() => {
                          this.setState({ NavVisible107215: false }, () => {
                            this.callCallbackFunction(107215);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="534970"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible534970: false }, () => {
                      this.callCallbackFunction(534970);
                    });
                  }}
                  visible={this.state.NavVisible534970}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible534970 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible534970: false }, () => {
                            this.callCallbackFunction(534970);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SemptomOneriList_Screen))))
  )
);
export { tmp as SemptomOneriList_Screen };
//export default tmp;
//export { tmp as SemptomOneriList_Screen };
