const screenInputData = {
  UzmanlikAlaniTransList: [{"name":"UzmanlikAlaniID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SemptomTransList: [{"name":"SemptomID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"VucutBolgeID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TaramaTestiTranslation: [{"name":"TaramaTestID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	VucutBolgesiEkle: [{"name":"VucutBolgeID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"VBTranslationID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	BelgeEkle: [{"name":"UzmanID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"BelgeID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	HastalikEkle: [{"name":"HastalikID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"HastalikTranslationID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null}],
	SemptomEkle: [{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"SemptomID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SemptomTranslationID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"isVBEditable","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"VucutBolgeID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DanisanProfil: [{"name":"DanisanID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	AsiEkle: [{"name":"AsiID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CuurentLang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"AsiTranslationID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	UzmanEkle: [{"name":"UzmanID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	VucutBolgesiTransList: [{"name":"VucutBolgeID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	UzmanProfilEdit: [{"name":"UzmanID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	PlanEkle: [{"name":"PlanId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	UzmanProfil: [{"name":"UzmanID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	SemptomOneriTransList: [{"name":"SemptomOneriID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"VucutBolgesi","type":"String","cardinality":1,"isExpandedPanel":null}],
	UzmanlikAlaniEkle: [{"name":"UzmanlikAlaniID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"UzmanlikAlaniTranslationID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	SemptomOneriEkle: [{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"SemptomOneriTransID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"SemptomOneriID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"isVBEditable","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"SemptomIDs","type":"String","cardinality":1,"isExpandedPanel":null}],
	UzmanBelgeOnayView: [{"name":"KullaniciBelgeID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AsiTranslationList: [{"name":"AsiID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DanisanEkle: [{"name":"DanisanID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CurrentLang","type":"String","cardinality":1,"isExpandedPanel":null}],
	HastalikTranslationList: [{"name":"HastalikID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TaramaTestiEkle: [{"name":"CuurentLang","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"WaitFor","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"TaramaTestID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"TaramaTestiTranslationID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	IndirimKoduForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
