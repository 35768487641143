import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBelgeEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBelgeEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SelectBelgeTuru: any[];
  SelectBelgeTuru_dummy: any[];
  KullaniciBelgeById: any[];
  KullaniciBelgeById_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  UploadFile: any;
  UploadFile_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  KullaniciBelgeSave: number;
  KullaniciBelgeSave_dummy: number;
  isComp382651Visible: "visible" | "hidden";
  isComp168268Visible: "visible" | "hidden";
  isComp382651Enabled: "enabled" | "disabled";
}

export class BelgeEkle_ScreenBase extends React.PureComponent<IBelgeEkle_ScreenProps, any> {
  belgeekle_885582_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "e17b7eaf-4600-4612-846a-dbf979f78320",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Belge Yükle" },
        { Id: 429915, PropertyName: "value", Value: "Belge Adı" },
        { Id: 298886, PropertyName: "value", Value: "Belge Türü" },
        { Id: 46320, PropertyName: "value", Value: "Belge Yükle" },
        { Id: 161149, PropertyName: "value", Value: "Yüklenen Dosya Adı" },
        { Id: 856375, PropertyName: "label", Value: "Kaydet" },
        { Id: 168268, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.belgeekle_885582_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SelectBelgeTuru: [],
      KullaniciBelgeById: [],
      ChangeEnabledOf: "",
      UploadFile: "",
      SetValueOf: "",
      ChangeVisibilityOf: "",
      KullaniciBelgeSave: 0,
      isComp382651Visible: "visible",
      isComp168268Visible: "hidden",
      isComp382651Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("belgeekle", "belgeekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BelgeEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("belgeekle", "belgeekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("belgeekle", "belgeekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BelgeEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      belgeekle_74554_value: this.state.KullaniciBelgeById?.at?.(0)?.belgeAdi ?? undefined,
      belgeekle_885582_value: this.state.KullaniciBelgeById?.at?.(0)?.belgeTuru ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  BelgeEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BelgeID ?? this.props.screenInputs.belgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BelgeEkle/BelgeEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectBelgeTuru = result?.data.selectBelgeTuru;

    formVars.belgeekle_885582_value =
      stateVars.KullaniciBelgeById?.length > 0 ? stateVars.KullaniciBelgeById[0]?.belgeTuru : null;
    formVars.belgeekle_885582_options = stateVars.SelectBelgeTuru;
    stateVars.KullaniciBelgeById = result?.data.kullaniciBelgeById;
    formVars.belgeekle_74554_value = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciBelgeById?.length > 0 ? stateVars.KullaniciBelgeById[0]?.belgeAdi : null
    );
    formVars.belgeekle_885582_value =
      stateVars.KullaniciBelgeById?.length > 0 ? stateVars.KullaniciBelgeById[0]?.belgeTuru : null;
    formVars.belgeekle_885582_options = stateVars.SelectBelgeTuru;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.BelgeEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BelgeEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp382651Enabled", "disabled");
    formVars.belgeekle_74554_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciBelgeById?.length > 0 ? this.state.KullaniciBelgeById[0]?.belgeAdi : null
    );

    formVars.belgeekle_885582_value = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciBelgeById?.length > 0 ? this.state.KullaniciBelgeById[0]?.belgeTuru : null
    );

    stateVars.dataSource_885582 = this.state.SelectBelgeTuru;
    stateVars.dataSource_885582 = this.state.SelectBelgeTuru;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BelgeEkleComponent_395018_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BelgeEkleComponent_928682_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "belgeekle_168268_value",
      ReactSystemFunctions.toString(this, stateVars.UploadFile?.data),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "belgeekle_327272_value",
      ReactSystemFunctions.toString(this, stateVars.UploadFile?.name),
      null
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp382651Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BelgeEkleComponent_856375_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "belgeekle_74554_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "belgeekle_74554_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "belgeekle_885582_value", "value", "SelectBelgeTuru", "belgeTuru", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "belgeekle_885582_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Belge_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "belgeekle_168268_value", "value", "", "", "")
        ),
        "string"
      ),
      BelgeAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "belgeekle_74554_value", "value", "", "", "")
        ),
        "string"
      ),
      BelgeTuru_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "belgeekle_885582_value",
            "value",
            "SelectBelgeTuru",
            "belgeTuru",
            "belgeTuru"
          )
        ),
        "string"
      ),
      KullaniciId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.UzmanID ?? this.props.screenInputs.uzmanid,
        "Guid"
      ),
      OnaylandiMi_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.BelgeID ?? this.props.screenInputs.belgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BelgeEkle/BelgeEkleComponent_856375_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciBelgeSave = result?.data.kullaniciBelgeSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.BelgeEkleComponent_856375_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BelgeEkleComponent_856375_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
