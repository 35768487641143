import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IIndirimKoduForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IIndirimKoduForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SelectIndirimKodu: any[];
  SelectIndirimKodu_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
}

export class IndirimKoduForm_ScreenBase extends React.PureComponent<IIndirimKoduForm_ScreenProps, any> {
  indirimkoduform_1548857_value_kuikaDatetimeRef: React.RefObject<any>;
  indirimkoduform_4645901_value_kuikaDatetimeRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "b5e1ceae-ef12-4db6-b678-efeca94e74fa",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Indirim Kodu Form" },
        { Id: 5947528, PropertyName: "value", Value: "İndirim Kodu" },
        { Id: 2849172, PropertyName: "placeholder", Value: "BABY20" },
        { Id: 9400001, PropertyName: "value", Value: "Iyzco Referans Kodu" },
        { Id: 2239760, PropertyName: "placeholder", Value: "İyzco planın referans kodunu yazınız" },
        { Id: 9357066, PropertyName: "value", Value: "Başlangiç Tarihi" },
        { Id: 1548857, PropertyName: "placeholder", Value: "Select Date Time..." },
        { Id: 9374887, PropertyName: "value", Value: "Bitiş Tarihi" },
        { Id: 4645901, PropertyName: "placeholder", Value: "Select Date Time..." },
        { Id: 9544, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.indirimkoduform_1548857_value_kuikaDatetimeRef = React.createRef();
    this.indirimkoduform_4645901_value_kuikaDatetimeRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SelectIndirimKodu: [],
      SaveRecord: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("indirimkoduform", "indirimkoduform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.IndirimKoduFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("indirimkoduform", "indirimkoduform");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("indirimkoduform", "indirimkoduform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.IndirimKoduFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      indirimkoduform_2849172_value: this.state.SelectIndirimKodu?.at?.(0)?.kod ?? undefined,
      indirimkoduform_2239760_value: this.state.SelectIndirimKodu?.at?.(0)?.iyzcoReferansIndirimli ?? undefined,
      indirimkoduform_1548857_value: this.state.SelectIndirimKodu?.at?.(0)?.baslangicTarihi ?? undefined,
      indirimkoduform_4645901_value: this.state.SelectIndirimKodu?.at?.(0)?.bitisTarihi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  IndirimKoduFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "IndirimKoduForm/IndirimKoduFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectIndirimKodu = result?.data.selectIndirimKodu;
    formVars.indirimkoduform_2849172_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectIndirimKodu?.length > 0 ? stateVars.SelectIndirimKodu[0]?.kod : null
    );
    formVars.indirimkoduform_2239760_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectIndirimKodu?.length > 0 ? stateVars.SelectIndirimKodu[0]?.iyzcoReferansIndirimli : null
    );
    formVars.indirimkoduform_1548857_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectIndirimKodu?.length > 0 ? stateVars.SelectIndirimKodu[0]?.baslangicTarihi : null
    );
    formVars.indirimkoduform_4645901_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectIndirimKodu?.length > 0 ? stateVars.SelectIndirimKodu[0]?.bitisTarihi : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.IndirimKoduFormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IndirimKoduFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.indirimkoduform_2849172_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectIndirimKodu?.length > 0 ? this.state.SelectIndirimKodu[0]?.kod : null
    );

    formVars.indirimkoduform_2239760_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectIndirimKodu?.length > 0 ? this.state.SelectIndirimKodu[0]?.iyzcoReferansIndirimli : null
    );

    formVars.indirimkoduform_1548857_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectIndirimKodu?.length > 0 ? this.state.SelectIndirimKodu[0]?.baslangicTarihi : null
    );

    formVars.indirimkoduform_4645901_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectIndirimKodu?.length > 0 ? this.state.SelectIndirimKodu[0]?.bitisTarihi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  IndirimKoduFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  IndirimKoduFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      BaslangicTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "indirimkoduform_1548857_value", "value", "", "", "")
        ),
        "Date"
      ),
      BitisTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "indirimkoduform_4645901_value", "value", "", "", "")
        ),
        "Date"
      ),
      IyzcoReferansIndirimli_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "indirimkoduform_2239760_value", "value", "", "", "")
        ),
        "string"
      ),
      Kod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "indirimkoduform_2849172_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "IndirimKoduForm/IndirimKoduFormComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.IndirimKoduFormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  IndirimKoduFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
