import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IGateWay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IGateWay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
}

export class GateWay_ScreenBase extends React.PureComponent<IGateWay_ScreenProps, any> {
  gateway_533454_value_kuikaGalleryViewRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "d79d1c10-9789-46fe-b4fc-61c5549de2e6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 80478, PropertyName: "value", Value: "GATEWAY" },
        { Id: 215054, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 832010, PropertyName: "label", Value: "Profil" },
        { Id: 456513, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 259033, PropertyName: "value", Value: "Summary Information" },
        { Id: 939063, PropertyName: "value", Value: "6" },
        { Id: 212212, PropertyName: "value", Value: "Online" },
        { Id: 711687, PropertyName: "value", Value: "0" },
        { Id: 42287, PropertyName: "value", Value: "Offline" },
        { Id: 859836, PropertyName: "value", Value: "0" },
        { Id: 324956, PropertyName: "value", Value: "With Alarm" },
        { Id: 589322, PropertyName: "value", Value: "0" },
        { Id: 558448, PropertyName: "value", Value: "Without Device" },
        { Id: 14792, PropertyName: "value", Value: "TempStarList" },
        { Id: 857178, PropertyName: "value", Value: "(6)" },
        { Id: 533454, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 563605, PropertyName: "value", Value: "[datafield:sensorname]" },
        { Id: 395822, PropertyName: "value", Value: "[datafield:lastonlinedate]" },
        { Id: 268029, PropertyName: "value", Value: "11:21:06" },
        { Id: 575292, PropertyName: "value", Value: "Offline" },
        { Id: 515071, PropertyName: "value", Value: "Online" },
        { Id: 123349, PropertyName: "value", Value: "[datafield:macid]" },
        { Id: 629904, PropertyName: "value", Value: "[datafield:batterylevel]" },
        { Id: 356773, PropertyName: "value", Value: "[datafield:signallevel]" },
        { Id: 649318, PropertyName: "value", Value: "[datafield:sensorvalue]" },
        { Id: 620139, PropertyName: "value", Value: "°C" },
        { Id: 49399, PropertyName: "value", Value: "[datafield:createdate]" },
        { Id: 5559, PropertyName: "value", Value: "11:21:06" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.gateway_533454_value_kuikaGalleryViewRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("gateway", "gateway");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("gateway", "gateway");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("gateway", "gateway");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  GateWayComponent_270586_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "GateWay",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "984331",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [984331] as number[];
  }
}
