import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  UzmanlikAlanlari: any[];
  UzmanlikAlanlari_dummy: any[];
}

export class UzmanEkle_ScreenBase extends React.PureComponent<IUzmanEkle_ScreenProps, any> {
  uzmanekle_683243_value_kuikaDateRef: React.RefObject<any>;
  uzmanekle_885582_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "de38abaa-d4c5-486d-a779-47734fe191ec",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Uzman" },
        { Id: 429915, PropertyName: "value", Value: "Ad" },
        { Id: 222573, PropertyName: "value", Value: "Soyad" },
        { Id: 829835, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 430215, PropertyName: "value", Value: "Email" },
        { Id: 714323, PropertyName: "value", Value: "Eklenme Tarihi" },
        { Id: 298886, PropertyName: "value", Value: "Branş" },
        { Id: 46320, PropertyName: "value", Value: "Belge Yükle" },
        { Id: 501957, PropertyName: "label", Value: "Sil" },
        { Id: 856375, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanekle_683243_value_kuikaDateRef = React.createRef();
    this.uzmanekle_885582_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      UzmanlikAlanlari: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanekle", "uzmanekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanekle", "uzmanekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanekle", "uzmanekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      uzmanekle_377603_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanEkle/UzmanEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UzmanlikAlanlari = result?.data.uzmanlikAlanlari;

    formVars.uzmanekle_885582_options = stateVars.UzmanlikAlanlari;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_885582 = this.state.UzmanlikAlanlari;
    stateVars.dataSource_885582 = this.state.UzmanlikAlanlari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanEkleComponent_395018_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
