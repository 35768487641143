import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPlanOzellikler_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPlanOzellikler_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  UyelikPlan: any[];
  UyelikPlan_dummy: any[];
  UyelikPlanByTanim: any[];
  UyelikPlanByTanim_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  UyelikPlanOzelliklerByUyelikPlanId: any[];
  UyelikPlanOzelliklerByUyelikPlanId_dummy: any[];
  UyelikPlanOzelliklerUpdate: number;
  UyelikPlanOzelliklerUpdate_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  isComp963306Visible: "visible" | "hidden";
  isComp708972Visible: "visible" | "hidden";
  isComp165839Visible: "visible" | "hidden";
}

export class PlanOzellikler_ScreenBase extends React.PureComponent<IPlanOzellikler_ScreenProps, any> {
  planozellikler_963306_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "1bc7d7a9-8832-4b8d-bd1f-36920e07ce89",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 912471, PropertyName: "value", Value: "Plan Özellikler" },
        { Id: 165343, PropertyName: "value", Value: "Üyelik Plan" },
        { Id: 963306, PropertyName: "placeholder", Value: "Premium" },
        { Id: 190002, PropertyName: "value", Value: "Aile bireyi ekleme limiti" },
        { Id: 619569, PropertyName: "placeholder", Value: "3" },
        { Id: 389140, PropertyName: "value", Value: "Aşı bildirimi" },
        { Id: 383164, PropertyName: "value", Value: "Aşı kartı görüntüleme" },
        { Id: 957411, PropertyName: "value", Value: "Aşı kaydetme" },
        { Id: 354458, PropertyName: "value", Value: "Tarama testi bildirimi" },
        { Id: 492156, PropertyName: "value", Value: "Tarama testi görüntüleme" },
        { Id: 158808, PropertyName: "value", Value: "Tarama testi kaydetme" },
        { Id: 86791, PropertyName: "value", Value: "Vücut ölçüleri hesaplama" },
        { Id: 653660, PropertyName: "value", Value: "Hedef boy hesaplama" },
        { Id: 677126, PropertyName: "value", Value: "Persentil hesaplama" },
        { Id: 799045, PropertyName: "value", Value: "Premature bebek persentil hesaplama" },
        { Id: 52019, PropertyName: "value", Value: "Büyüme grafiği analiz raporu" },
        { Id: 241589, PropertyName: "value", Value: "Büyüme grafiği problemleri uyarı alma" },
        { Id: 784812, PropertyName: "value", Value: "Emzirme günlüğü" },
        { Id: 858860, PropertyName: "value", Value: "Semptomdan teşhise" },
        { Id: 497613, PropertyName: "value", Value: "Uzmana eşleşme isteği gönderme" },
        { Id: 22238, PropertyName: "value", Value: "Uzmandan randevu talep etme" },
        { Id: 701945, PropertyName: "value", Value: "Puanlama" },
        { Id: 913502, PropertyName: "value", Value: "Tempstar bağlantısı" },
        { Id: 679877, PropertyName: "value", Value: "Hatırlatıcılar" },
        { Id: 671612, PropertyName: "value", Value: "Timeline" },
        { Id: 311422, PropertyName: "value", Value: "Lokasyon bazlı uzman bulma" },
        { Id: 23363, PropertyName: "value", Value: "Lokasyon bazlı hastalık haritası" },
        { Id: 435094, PropertyName: "value", Value: "Uyku timer" },
        { Id: 467902, PropertyName: "value", Value: "Uygulamada dil seçeneği" },
        { Id: 660892, PropertyName: "value", Value: "Tıbbi kayıt ekleme" },
        { Id: 946301, PropertyName: "value", Value: "Ateş düşürücü tavsiyesi" },
        { Id: 471393, PropertyName: "value", Value: "Uzmanlara yıllık ödeme" },
        { Id: 650823, PropertyName: "value", Value: "label" },
        { Id: 738834, PropertyName: "value", Value: "label" },
        { Id: 42381, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.planozellikler_963306_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      UyelikPlan: [],
      UyelikPlanByTanim: [],
      ChangeVisibilityOf: "",
      UyelikPlanOzelliklerByUyelikPlanId: [],
      UyelikPlanOzelliklerUpdate: 0,
      Notify: false,
      isComp963306Visible: "visible",
      isComp708972Visible: "hidden",
      isComp165839Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("planozellikler", "planozellikler");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PlanOzelliklerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("planozellikler", "planozellikler");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("planozellikler", "planozellikler");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PlanOzelliklerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      planozellikler_963306_value: this.state.UyelikPlanByTanim?.at?.(0)?.id ?? undefined,
      planozellikler_619569_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.aileBireyiEklemeLimit ?? undefined,
      planozellikler_354358_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.asiBildirim ?? undefined,
      planozellikler_61115_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.asiKartiGoruntuleme ?? undefined,
      planozellikler_887551_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.asiKaydetme ?? undefined,
      planozellikler_337354_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.taramaTestiBildirim ?? undefined,
      planozellikler_787397_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.taramaTestiGoruntuleme ?? undefined,
      planozellikler_39502_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.taramaTestiKaydetme ?? undefined,
      planozellikler_223120_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.vucutOlculeriEkleme ?? undefined,
      planozellikler_787146_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.hedefBoyHesaplama ?? undefined,
      planozellikler_173224_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.percentileHesaplama ?? undefined,
      planozellikler_488932_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.prematureBebekPercentileHesaplama ?? undefined,
      planozellikler_218381_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.buyumeGrafigiAnalizRaporu ?? undefined,
      planozellikler_421523_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.buyumeGrafigiProblemleriUyariAlma ?? undefined,
      planozellikler_705706_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.emzirmeGunlugu ?? undefined,
      planozellikler_242539_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.semptomdanTeshise ?? undefined,
      planozellikler_433172_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.uzmanaEslesmeIstegiGonderme ?? undefined,
      planozellikler_248365_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.uzmandanRandevuTalepEtme ?? undefined,
      planozellikler_792896_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.puanlama ?? undefined,
      planozellikler_615855_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.tempstarBaglanti ?? undefined,
      planozellikler_385110_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.hatirlaticilar ?? undefined,
      planozellikler_361039_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.timeline ?? undefined,
      planozellikler_163218_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.lokasyonBazliUzmanBulma ?? undefined,
      planozellikler_509951_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.lokasyonBazliHastalikHaritasi ?? undefined,
      planozellikler_358913_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.uykuTimer ?? undefined,
      planozellikler_230352_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.uygulamaDilSecimi ?? undefined,
      planozellikler_8518_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.tibbiKayitEkleme ?? undefined,
      planozellikler_797242_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.atesDusurucuTavsiyesi ?? undefined,
      planozellikler_978417_value:
        this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.uzmanlaraYillikOdeme ?? undefined,
      planozellikler_420164_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.asiBildirim ?? undefined,
      planozellikler_662893_value: this.state.UyelikPlanOzelliklerByUyelikPlanId?.at?.(0)?.asiBildirim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  PlanOzelliklerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Tanim_1: ReactSystemFunctions.convertToTypeByName("Freemium", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PlanOzellikler/PlanOzelliklerPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlan = result?.data.uyelikPlan;

    formVars.planozellikler_963306_value =
      stateVars.UyelikPlanByTanim?.length > 0 ? stateVars.UyelikPlanByTanim[0]?.id : null;
    formVars.planozellikler_963306_options = stateVars.UyelikPlan;
    stateVars.UyelikPlanByTanim = result?.data.uyelikPlanByTanim;
    formVars.planozellikler_963306_value =
      stateVars.UyelikPlanByTanim?.length > 0 ? stateVars.UyelikPlanByTanim[0]?.id : null;
    formVars.planozellikler_963306_options = stateVars.UyelikPlan;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanOzelliklerPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PlanOzelliklerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp963306Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanOzelliklerPageInit2_();
    });

    return isErrorOccurred;
  };
  PlanOzelliklerPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UyelikPlanId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_963306_value", "value", "UyelikPlan", "id", "id")
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PlanOzellikler/PlanOzelliklerPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlanOzelliklerByUyelikPlanId = result?.data.uyelikPlanOzelliklerByUyelikPlanId;
    formVars.planozellikler_619569_value = ReactSystemFunctions.toDecimal(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.aileBireyiEklemeLimit
        : null
    );
    formVars.planozellikler_354358_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );
    formVars.planozellikler_61115_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKartiGoruntuleme
        : null
    );
    formVars.planozellikler_887551_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKaydetme
        : null
    );
    formVars.planozellikler_337354_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiBildirim
        : null
    );
    formVars.planozellikler_787397_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiGoruntuleme
        : null
    );
    formVars.planozellikler_39502_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiKaydetme
        : null
    );
    formVars.planozellikler_223120_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.vucutOlculeriEkleme
        : null
    );
    formVars.planozellikler_787146_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.hedefBoyHesaplama
        : null
    );
    formVars.planozellikler_173224_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.percentileHesaplama
        : null
    );
    formVars.planozellikler_488932_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.prematureBebekPercentileHesaplama
        : null
    );
    formVars.planozellikler_218381_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiAnalizRaporu
        : null
    );
    formVars.planozellikler_421523_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiProblemleriUyariAlma
        : null
    );
    formVars.planozellikler_705706_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.emzirmeGunlugu
        : null
    );
    formVars.planozellikler_242539_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.semptomdanTeshise
        : null
    );
    formVars.planozellikler_433172_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanaEslesmeIstegiGonderme
        : null
    );
    formVars.planozellikler_248365_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmandanRandevuTalepEtme
        : null
    );
    formVars.planozellikler_792896_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.puanlama
        : null
    );
    formVars.planozellikler_615855_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.tempstarBaglanti
        : null
    );
    formVars.planozellikler_385110_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.hatirlaticilar
        : null
    );
    formVars.planozellikler_361039_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.timeline
        : null
    );
    formVars.planozellikler_163218_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliUzmanBulma
        : null
    );
    formVars.planozellikler_509951_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliHastalikHaritasi
        : null
    );
    formVars.planozellikler_358913_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uykuTimer
        : null
    );
    formVars.planozellikler_230352_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uygulamaDilSecimi
        : null
    );
    formVars.planozellikler_8518_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.tibbiKayitEkleme
        : null
    );
    formVars.planozellikler_797242_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.atesDusurucuTavsiyesi
        : null
    );
    formVars.planozellikler_978417_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanlaraYillikOdeme
        : null
    );
    formVars.planozellikler_420164_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );
    formVars.planozellikler_662893_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanOzelliklerPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PlanOzelliklerPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.planozellikler_963306_value = ReactSystemFunctions.toString(
      this,
      this.state.UyelikPlanByTanim?.length > 0 ? this.state.UyelikPlanByTanim[0]?.id : null
    );

    stateVars.dataSource_963306 = this.state.UyelikPlan;
    stateVars.dataSource_963306 = this.state.UyelikPlan;
    formVars.planozellikler_619569_value = ReactSystemFunctions.toDecimal(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.aileBireyiEklemeLimit
        : null
    );

    formVars.planozellikler_354358_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );

    formVars.planozellikler_61115_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKartiGoruntuleme
        : null
    );

    formVars.planozellikler_887551_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKaydetme
        : null
    );

    formVars.planozellikler_337354_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiBildirim
        : null
    );

    formVars.planozellikler_787397_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiGoruntuleme
        : null
    );

    formVars.planozellikler_39502_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiKaydetme
        : null
    );

    formVars.planozellikler_223120_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.vucutOlculeriEkleme
        : null
    );

    formVars.planozellikler_787146_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.hedefBoyHesaplama
        : null
    );

    formVars.planozellikler_173224_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.percentileHesaplama
        : null
    );

    formVars.planozellikler_488932_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.prematureBebekPercentileHesaplama
        : null
    );

    formVars.planozellikler_218381_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiAnalizRaporu
        : null
    );

    formVars.planozellikler_421523_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiProblemleriUyariAlma
        : null
    );

    formVars.planozellikler_705706_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.emzirmeGunlugu
        : null
    );

    formVars.planozellikler_242539_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.semptomdanTeshise
        : null
    );

    formVars.planozellikler_433172_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanaEslesmeIstegiGonderme
        : null
    );

    formVars.planozellikler_248365_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmandanRandevuTalepEtme
        : null
    );

    formVars.planozellikler_792896_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.puanlama
        : null
    );

    formVars.planozellikler_615855_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.tempstarBaglanti
        : null
    );

    formVars.planozellikler_385110_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.hatirlaticilar
        : null
    );

    formVars.planozellikler_361039_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.timeline
        : null
    );

    formVars.planozellikler_163218_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliUzmanBulma
        : null
    );

    formVars.planozellikler_509951_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliHastalikHaritasi
        : null
    );

    formVars.planozellikler_358913_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uykuTimer
        : null
    );

    formVars.planozellikler_230352_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uygulamaDilSecimi
        : null
    );

    formVars.planozellikler_8518_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.tibbiKayitEkleme
        : null
    );

    formVars.planozellikler_797242_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.atesDusurucuTavsiyesi
        : null
    );

    formVars.planozellikler_978417_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanlaraYillikOdeme
        : null
    );

    formVars.planozellikler_420164_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );

    formVars.planozellikler_662893_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PlanOzelliklerComponent_247426_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PlanOzelliklerComponent_963306_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikPlanId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_963306_value", "value", "UyelikPlan", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PlanOzellikler/PlanOzelliklerComponent_963306_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlanOzelliklerByUyelikPlanId = result?.data.uyelikPlanOzelliklerByUyelikPlanId;
    formVars.planozellikler_619569_value = ReactSystemFunctions.toDecimal(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.aileBireyiEklemeLimit
        : null
    );
    formVars.planozellikler_354358_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );
    formVars.planozellikler_61115_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKartiGoruntuleme
        : null
    );
    formVars.planozellikler_887551_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKaydetme
        : null
    );
    formVars.planozellikler_337354_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiBildirim
        : null
    );
    formVars.planozellikler_787397_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiGoruntuleme
        : null
    );
    formVars.planozellikler_39502_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiKaydetme
        : null
    );
    formVars.planozellikler_223120_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.vucutOlculeriEkleme
        : null
    );
    formVars.planozellikler_787146_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.hedefBoyHesaplama
        : null
    );
    formVars.planozellikler_173224_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.percentileHesaplama
        : null
    );
    formVars.planozellikler_488932_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.prematureBebekPercentileHesaplama
        : null
    );
    formVars.planozellikler_218381_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiAnalizRaporu
        : null
    );
    formVars.planozellikler_421523_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiProblemleriUyariAlma
        : null
    );
    formVars.planozellikler_705706_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.emzirmeGunlugu
        : null
    );
    formVars.planozellikler_242539_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.semptomdanTeshise
        : null
    );
    formVars.planozellikler_433172_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanaEslesmeIstegiGonderme
        : null
    );
    formVars.planozellikler_248365_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmandanRandevuTalepEtme
        : null
    );
    formVars.planozellikler_792896_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.puanlama
        : null
    );
    formVars.planozellikler_615855_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.tempstarBaglanti
        : null
    );
    formVars.planozellikler_385110_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.hatirlaticilar
        : null
    );
    formVars.planozellikler_361039_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.timeline
        : null
    );
    formVars.planozellikler_163218_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliUzmanBulma
        : null
    );
    formVars.planozellikler_509951_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliHastalikHaritasi
        : null
    );
    formVars.planozellikler_358913_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uykuTimer
        : null
    );
    formVars.planozellikler_230352_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uygulamaDilSecimi
        : null
    );
    formVars.planozellikler_8518_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.tibbiKayitEkleme
        : null
    );
    formVars.planozellikler_797242_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.atesDusurucuTavsiyesi
        : null
    );
    formVars.planozellikler_978417_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanlaraYillikOdeme
        : null
    );
    formVars.planozellikler_420164_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );
    formVars.planozellikler_662893_value = ReactSystemFunctions.value(
      this,
      stateVars.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? stateVars.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanOzelliklerComponent_963306_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PlanOzelliklerComponent_963306_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.planozellikler_619569_value = ReactSystemFunctions.toDecimal(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.aileBireyiEklemeLimit
        : null
    );

    formVars.planozellikler_354358_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );

    formVars.planozellikler_61115_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKartiGoruntuleme
        : null
    );

    formVars.planozellikler_887551_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiKaydetme
        : null
    );

    formVars.planozellikler_337354_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiBildirim
        : null
    );

    formVars.planozellikler_787397_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiGoruntuleme
        : null
    );

    formVars.planozellikler_39502_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.taramaTestiKaydetme
        : null
    );

    formVars.planozellikler_223120_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.vucutOlculeriEkleme
        : null
    );

    formVars.planozellikler_787146_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.hedefBoyHesaplama
        : null
    );

    formVars.planozellikler_173224_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.percentileHesaplama
        : null
    );

    formVars.planozellikler_488932_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.prematureBebekPercentileHesaplama
        : null
    );

    formVars.planozellikler_218381_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiAnalizRaporu
        : null
    );

    formVars.planozellikler_421523_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.buyumeGrafigiProblemleriUyariAlma
        : null
    );

    formVars.planozellikler_705706_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.emzirmeGunlugu
        : null
    );

    formVars.planozellikler_242539_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.semptomdanTeshise
        : null
    );

    formVars.planozellikler_433172_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanaEslesmeIstegiGonderme
        : null
    );

    formVars.planozellikler_248365_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmandanRandevuTalepEtme
        : null
    );

    formVars.planozellikler_792896_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.puanlama
        : null
    );

    formVars.planozellikler_615855_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.tempstarBaglanti
        : null
    );

    formVars.planozellikler_385110_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.hatirlaticilar
        : null
    );

    formVars.planozellikler_361039_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.timeline
        : null
    );

    formVars.planozellikler_163218_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliUzmanBulma
        : null
    );

    formVars.planozellikler_509951_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.lokasyonBazliHastalikHaritasi
        : null
    );

    formVars.planozellikler_358913_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uykuTimer
        : null
    );

    formVars.planozellikler_230352_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uygulamaDilSecimi
        : null
    );

    formVars.planozellikler_8518_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.tibbiKayitEkleme
        : null
    );

    formVars.planozellikler_797242_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.atesDusurucuTavsiyesi
        : null
    );

    formVars.planozellikler_978417_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.uzmanlaraYillikOdeme
        : null
    );

    formVars.planozellikler_420164_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );

    formVars.planozellikler_662893_value = ReactSystemFunctions.value(
      this,
      this.state.UyelikPlanOzelliklerByUyelikPlanId?.length > 0
        ? this.state.UyelikPlanOzelliklerByUyelikPlanId[0]?.asiBildirim
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PlanOzelliklerComponent_42381_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_619569_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "planozellikler_619569_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AileBireyiEklemeLimit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_619569_value", "value", "", "", "")
        ),
        "number"
      ),
      AsiBildirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_354358_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AsiKartiGoruntuleme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_61115_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AsiKaydetme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_887551_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AtesDusurucuTavsiyesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_797242_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BuyumeGrafigiAnalizRaporu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_218381_value", "value", "", "", "")
        ),
        "boolean"
      ),
      BuyumeGrafigiProblemleriUyariAlma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_421523_value", "value", "", "", "")
        ),
        "boolean"
      ),
      EmzirmeGunlugu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_705706_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Hatirlaticilar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_385110_value", "value", "", "", "")
        ),
        "boolean"
      ),
      HedefBoyHesaplama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_787146_value", "value", "", "", "")
        ),
        "boolean"
      ),
      LokasyonBazliHastalikHaritasi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_509951_value", "value", "", "", "")
        ),
        "boolean"
      ),
      LokasyonBazliUzmanBulma_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_163218_value", "value", "", "", "")
        ),
        "boolean"
      ),
      PercentileHesaplama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_173224_value", "value", "", "", "")
        ),
        "boolean"
      ),
      PrematureBebekPercentileHesaplama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_488932_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Puanlama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_792896_value", "value", "", "", "")
        ),
        "boolean"
      ),
      SemptomdanTeshise_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_242539_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TaramaTestiBildirim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_337354_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TaramaTestiGoruntuleme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_787397_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TaramaTestiKaydetme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_39502_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TempstarBaglanti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_615855_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TibbiKayitEkleme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_8518_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Timeline_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_361039_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UyelikPlanId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_963306_value", "value", "UyelikPlan", "id", "id")
        ),
        "Guid"
      ),
      UygulamaDilSecimi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_230352_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UykuTimer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_358913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UzmanaEslesmeIstegiGonderme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_433172_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UzmandanRandevuTalepEtme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_248365_value", "value", "", "", "")
        ),
        "boolean"
      ),
      UzmanlaraYillikOdeme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_978417_value", "value", "", "", "")
        ),
        "boolean"
      ),
      VucutOlculeriEkleme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "planozellikler_223120_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PlanOzellikler/PlanOzelliklerComponent_42381_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlanOzelliklerUpdate = result?.data.uyelikPlanOzelliklerUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanOzelliklerComponent_42381_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PlanOzelliklerComponent_42381_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5f4c58fc_ead3_d4a4_11f2_84e29571ca21_notify",
        this.defaultML,
        "Değişiklikler seçtiğiniz plana kaydedildi!"
      ),
      "success",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
