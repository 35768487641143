import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IAsiList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IAsiList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  AsilarSearch: any[];
  AsilarSearch_dummy: any[];
  AsilarSearchCount: any[];
  AsilarSearchCount_dummy: any[];
  AsilarDelete: number;
  AsilarDelete_dummy: number;
}

export class AsiList_ScreenBase extends React.PureComponent<IAsiList_ScreenProps, any> {
  asilist_755660_value_kuikaTableRef: React.RefObject<any>;
  asilist_122579_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6556ffbe-4b74-4987-baac-fe18c7dd7ef6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Aşılar" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 718191, PropertyName: "label", Value: "Yeni aşı ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 455307, PropertyName: "title", Value: "Aşı İsmi" },
        { Id: 371259, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 899412, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 736103, PropertyName: "title", Value: "Uygulanma Zamanı (Ay)" },
        { Id: 416866, PropertyName: "value", Value: "[datafield:uygulanmazamani]" },
        { Id: 214008, PropertyName: "title", Value: "Opsiyon" },
        { Id: 936575, PropertyName: "value", Value: "[datafield:opsiyonaciklama]" },
        { Id: 829107, PropertyName: "title", Value: "Tekrar Bilgisi" },
        { Id: 20346, PropertyName: "value", Value: "[datafield:tekrarbilgisi]" },
        { Id: 473754, PropertyName: "title", Value: "Yapan Kurum" },
        { Id: 609325, PropertyName: "value", Value: "[datafield:yapankurum]" },
        { Id: 830114, PropertyName: "title", Value: "#" },
        { Id: 577098, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.asilist_755660_value_kuikaTableRef = React.createRef();
    this.asilist_122579_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      AsilarSearch: [],
      AsilarSearchCount: [],
      AsilarDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("asilist", "asilist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.AsiListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("asilist", "asilist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("asilist", "asilist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.AsiListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  AsiListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AsiList/AsiListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AsilarSearch = result?.data.asilarSearch;

    stateVars.AsilarSearchCount = result?.data.asilarSearchCount;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      stateVars.AsilarSearchCount?.length > 0 ? stateVars.AsilarSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AsiListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.AsilarSearch;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    formVars.asilist_122579_total = ReactSystemFunctions.value(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  AsiListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AsiList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "592568",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiListComponent_2755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AsiList/AsiListComponent_2755_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AsilarSearch = result?.data.asilarSearch;

    stateVars.AsilarSearchCount = result?.data.asilarSearchCount;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      stateVars.AsilarSearchCount?.length > 0 ? stateVars.AsilarSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AsiListComponent_2755_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiListComponent_2755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.AsilarSearch;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    formVars.asilist_122579_total = ReactSystemFunctions.value(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("AsiEkle", "AsiID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("AsiEkle", "CuurentLang", "tr_TR");
    KuikaAppManager.addToPageInputVariables("AsiEkle", "WaitFor", "New");
    KuikaAppManager.addToPageInputVariables("AsiEkle", "AsiTranslationID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AsiList",
      "AsiEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "402101",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AsiTranslationList",
      "AsiID",
      ReactSystemFunctions.value(this, "asilist_755660_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "AsiList",
      "AsiTranslationList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiListComponent_203570_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AsiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "asilist_755660_value", "id"),
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "20f4495c_d3fd_d147_7263_6bef0c489a0d_confirmation",
        this.defaultML,
        "Bu aşı ve translation bilgileri silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "AsiList/AsiListComponent_203570_onClick", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.AsilarDelete = result?.data.asilarDelete;
        stateVars.AsilarSearch = result?.data.asilarSearch;

        stateVars.AsilarSearchCount = result?.data.asilarSearchCount;
        formVars.asilist_173204_value = ReactSystemFunctions.toString(
          this,
          stateVars.AsilarSearchCount?.length > 0 ? stateVars.AsilarSearchCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.AsiListComponent_203570_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiListComponent_203570_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.AsilarSearch;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    formVars.asilist_122579_total = ReactSystemFunctions.value(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  AsiListComponent_122579_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "asilist_122579_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "asilist_2755_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "AsiList/AsiListComponent_122579_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AsilarSearch = result?.data.asilarSearch;

    stateVars.AsilarSearchCount = result?.data.asilarSearchCount;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      stateVars.AsilarSearchCount?.length > 0 ? stateVars.AsilarSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.AsiListComponent_122579_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  AsiListComponent_122579_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.AsilarSearch;
    formVars.asilist_173204_value = ReactSystemFunctions.toString(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    formVars.asilist_122579_total = ReactSystemFunctions.value(
      this,
      this.state.AsilarSearchCount?.length > 0 ? this.state.AsilarSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [592568, 402101] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.AsiListPageInit();
    }
  }
}
