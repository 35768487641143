import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITaramaTestiEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITaramaTestiEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SelectOpsiyon: any[];
  SelectOpsiyon_dummy: any[];
  SelectYapanKurum: any[];
  SelectYapanKurum_dummy: any[];
  TaramaTestiLangByCondition: any[];
  TaramaTestiLangByCondition_dummy: any[];
  TaramaTestleriById: any[];
  TaramaTestleriById_dummy: any[];
  TaramaTestleriSave: number;
  TaramaTestleriSave_dummy: number;
  TaramaTestiTransSave: number;
  TaramaTestiTransSave_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp812613Visible: "visible" | "hidden";
  isComp506519Visible: "visible" | "hidden";
  isComp404592Visible: "visible" | "hidden";
  isComp92565Visible: "visible" | "hidden";
  isComp926613Visible: "visible" | "hidden";
  isComp615348Visible: "visible" | "hidden";
}

export class TaramaTestiEkle_ScreenBase extends React.PureComponent<ITaramaTestiEkle_ScreenProps, any> {
  taramatestiekle_362681_value_kuikaSelectBoxRef: React.RefObject<any>;
  taramatestiekle_629118_value_kuikaSelectBoxRef: React.RefObject<any>;
  taramatestiekle_7945_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "08e743c6-7c82-4299-b872-4b3e06165488",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Tarama Testi" },
        { Id: 267205, PropertyName: "value", Value: "Dil" },
        { Id: 429915, PropertyName: "value", Value: "Test adı" },
        { Id: 955963, PropertyName: "placeholder", Value: "Aşı adı giriniz..." },
        { Id: 298886, PropertyName: "value", Value: "Uygulanma zamanı (ay)" },
        { Id: 746856, PropertyName: "value", Value: "Zaman aralığı" },
        { Id: 477524, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 909425, PropertyName: "value", Value: "Tekrar Bilgisi" },
        { Id: 644282, PropertyName: "placeholder", Value: "Süre belirleyiniz" },
        { Id: 899094, PropertyName: "value", Value: "Opsiyon" },
        { Id: 154968, PropertyName: "value", Value: "Yapan Kurum" },
        { Id: 856375, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.taramatestiekle_362681_value_kuikaSelectBoxRef = React.createRef();
    this.taramatestiekle_629118_value_kuikaSelectBoxRef = React.createRef();
    this.taramatestiekle_7945_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SelectOpsiyon: [],
      SelectYapanKurum: [],
      TaramaTestiLangByCondition: [],
      TaramaTestleriById: [],
      TaramaTestleriSave: 0,
      IfThenElse: false,
      isComp812613Visible: "hidden",
      isComp506519Visible: "hidden",
      isComp404592Visible: "hidden",
      isComp92565Visible: "hidden",
      isComp926613Visible: "hidden",
      isComp615348Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("taramatestiekle", "taramatestiekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TaramaTestiEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("taramatestiekle", "taramatestiekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("taramatestiekle", "taramatestiekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TaramaTestiEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      taramatestiekle_362681_value: this.state.TaramaTestleriById?.at?.(0)?.lang ?? undefined,
      taramatestiekle_955963_value: this.state.TaramaTestleriById?.at?.(0)?.tanim ?? undefined,
      taramatestiekle_608918_value: this.state.TaramaTestleriById?.at?.(0)?.uygulanmaZamani ?? undefined,
      taramatestiekle_477524_value: this.state.TaramaTestleriById?.at?.(0)?.zamanAraligi ?? undefined,
      taramatestiekle_644282_value: this.state.TaramaTestleriById?.at?.(0)?.tekrarBilgisi ?? undefined,
      taramatestiekle_629118_value: this.state.TaramaTestleriById?.at?.(0)?.opsiyon ?? undefined,
      taramatestiekle_7945_value: this.state.TaramaTestleriById?.at?.(0)?.yapanKurum ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  TaramaTestiEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      WaitFor_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      TaramaTestiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      ),
      Lang_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "string"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TaramaTestiEkle/TaramaTestiEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectOpsiyon = result?.data.selectOpsiyon;

    formVars.taramatestiekle_629118_value =
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.opsiyon : null;
    formVars.taramatestiekle_629118_options = stateVars.SelectOpsiyon;
    stateVars.SelectYapanKurum = result?.data.selectYapanKurum;

    formVars.taramatestiekle_7945_value =
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.yapanKurum : null;
    formVars.taramatestiekle_7945_options = stateVars.SelectYapanKurum;
    stateVars.TaramaTestiLangByCondition = result?.data.taramaTestiLangByCondition;

    formVars.taramatestiekle_362681_value =
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.lang : null;
    formVars.taramatestiekle_362681_options = stateVars.TaramaTestiLangByCondition;
    stateVars.TaramaTestleriById = result?.data.taramaTestleriById;
    formVars.taramatestiekle_362681_value =
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.lang : null;
    formVars.taramatestiekle_362681_options = stateVars.TaramaTestiLangByCondition;
    formVars.taramatestiekle_955963_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.tanim : null
    );
    formVars.taramatestiekle_608918_value = ReactSystemFunctions.value(
      this,
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.uygulanmaZamani : null
    );
    formVars.taramatestiekle_477524_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.zamanAraligi : null
    );
    formVars.taramatestiekle_644282_value = ReactSystemFunctions.toString(
      this,
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.tekrarBilgisi : null
    );
    formVars.taramatestiekle_629118_value =
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.opsiyon : null;
    formVars.taramatestiekle_629118_options = stateVars.SelectOpsiyon;
    formVars.taramatestiekle_7945_value =
      stateVars.TaramaTestleriById?.length > 0 ? stateVars.TaramaTestleriById[0]?.yapanKurum : null;
    formVars.taramatestiekle_7945_options = stateVars.SelectYapanKurum;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp812613Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.taramatestiekle_362681_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.lang : null
    );

    stateVars.dataSource_362681 = this.state.TaramaTestiLangByCondition;
    stateVars.dataSource_362681 = this.state.TaramaTestiLangByCondition;
    formVars.taramatestiekle_955963_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.tanim : null
    );

    stateVars.isComp506519Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.taramatestiekle_608918_value = ReactSystemFunctions.value(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.uygulanmaZamani : null
    );

    stateVars.isComp404592Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.taramatestiekle_477524_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.zamanAraligi : null
    );

    stateVars.isComp92565Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.taramatestiekle_644282_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.tekrarBilgisi : null
    );

    stateVars.isComp926613Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.taramatestiekle_629118_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.opsiyon : null
    );

    stateVars.dataSource_629118 = this.state.SelectOpsiyon;
    stateVars.dataSource_629118 = this.state.SelectOpsiyon;
    stateVars.isComp615348Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.taramatestiekle_7945_value = ReactSystemFunctions.toString(
      this,
      this.state.TaramaTestleriById?.length > 0 ? this.state.TaramaTestleriById[0]?.yapanKurum : null
    );

    stateVars.dataSource_7945 = this.state.SelectYapanKurum;
    stateVars.dataSource_7945 = this.state.SelectYapanKurum;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TaramaTestiEkleComponent_395018_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TaramaTestiEkleComponent_856375_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Opsiyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestiekle_629118_value", "value", "SelectOpsiyon", "id", "id")
        ),
        "number"
      ),
      TekrarBilgisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "taramatestiekle_644282_value", "value", "", "", "")
        ),
        "string"
      ),
      UygulanmaZamani_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "taramatestiekle_608918_value", "value", "", "", "")
        ),
        "number"
      ),
      YapanKurum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "taramatestiekle_7945_value",
            "value",
            "SelectYapanKurum",
            "yapanKurum",
            "yapanKurum"
          )
        ),
        "string"
      ),
      ZamanAraligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "taramatestiekle_477524_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiEkle/TaramaTestiEkleComponent_856375_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestleriSave = result?.data.taramaTestleriSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TaramaTestiEkleComponent_856375_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TaramaTestiEkleComponent_856375_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TaramaTestiAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "taramatestiekle_955963_value", "value", "", "", "")
        ),
        "string"
      ),
      TaramaTestiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      ),
      TaramaTestiTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestiTranslationID ?? this.props.screenInputs.taramatestitranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiEkle/TaramaTestiEkleComponent_856375_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestiTransSave = result?.data.taramaTestiTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TaramaTestiEkleComponent_856375_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TaramaTestiAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "taramatestiekle_955963_value", "value", "", "", "")
        ),
        "string"
      ),
      TaramaTestiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestID ?? this.props.screenInputs.taramatestid,
        "Guid"
      ),
      TaramaTestiTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TaramaTestiTranslationID ?? this.props.screenInputs.taramatestitranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CuurentLang ?? this.props.screenInputs.cuurentlang,
        "string"
      ),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "taramatestiekle_362681_value",
            "value",
            "TaramaTestiLangByCondition",
            "kod",
            "kod"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TaramaTestiEkle/TaramaTestiEkleComponent_856375_onClick_else1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TaramaTestiTransSave = result?.data.taramaTestiTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TaramaTestiEkleComponent_856375_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New")) {
      isErrorOccurred = await this.TaramaTestiEkleComponent_856375_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.TaramaTestiEkleComponent_856375_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
