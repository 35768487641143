import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPlanEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPlanEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  UyelikPlan: any[];
  UyelikPlan_dummy: any[];
  UyelikPlanByTanim: any[];
  UyelikPlanByTanim_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  UyelikPlanOzelliklerByUyelikPlanId: any[];
  UyelikPlanOzelliklerByUyelikPlanId_dummy: any[];
}

export class PlanEkle_ScreenBase extends React.PureComponent<IPlanEkle_ScreenProps, any> {
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "9b6b6004-fa3b-4d08-8968-d4f7a66ea48b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 21603, PropertyName: "value", Value: "Üyelik Plan" },
        { Id: 946342, PropertyName: "value", Value: "Plan Adı" },
        { Id: 709732, PropertyName: "value", Value: "Referans kodu" },
        { Id: 42381, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      UyelikPlan: [],
      UyelikPlanByTanim: [],
      Notify: false,
      UyelikPlanOzelliklerByUyelikPlanId: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("planekle", "planekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PlanEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("planekle", "planekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("planekle", "planekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PlanEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  PlanEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Tanim_1: ReactSystemFunctions.convertToTypeByName("Freemium", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PlanEkle/PlanEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlan = result?.data.uyelikPlan;
    stateVars.UyelikPlanByTanim = result?.data.uyelikPlanByTanim;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PlanEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f31aabd6_d9e6_c617_4e54_7fbc1a7dc66e_notify",
        this.defaultML,
        "Üyelik planını filtreleyek, ilgili planın özelliklerini açıp kapatıp kaydedebilirsiniz."
      ),
      "info",
      "bottom-right",
      5
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.PlanEklePageInit2_();
    });

    return isErrorOccurred;
  };
  PlanEklePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UyelikPlanId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PlanId ?? this.props.screenInputs.planid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PlanEkle/PlanEklePageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikPlanOzelliklerByUyelikPlanId = result?.data.uyelikPlanOzelliklerByUyelikPlanId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
