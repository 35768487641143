import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDanisanList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDanisanList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DanisanSearch: any[];
  DanisanSearch_dummy: any[];
  DanisanSearchCount: any[];
  DanisanSearchCount_dummy: any[];
}

export class DanisanList_ScreenBase extends React.PureComponent<IDanisanList_ScreenProps, any> {
  danisanlist_814341_value_kuikaSelectBoxRef: React.RefObject<any>;
  danisanlist_755660_value_kuikaTableRef: React.RefObject<any>;
  danisanlist_692036_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "7ba30fe2-bcc7-4b6a-a66e-0b2cb77ab6a6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Danışan Listesi" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 814341, PropertyName: "placeholder", Value: "Filtrele" },
        { Id: 718191, PropertyName: "label", Value: "Yeni Danışan Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 455307, PropertyName: "title", Value: "Aktif" },
        { Id: 958529, PropertyName: "title", Value: "Danışan" },
        { Id: 5169, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 662815, PropertyName: "title", Value: "Eposta" },
        { Id: 814345, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 736103, PropertyName: "title", Value: "Telefon" },
        { Id: 416866, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 777129, PropertyName: "title", Value: "Eklenme Tarihi" },
        { Id: 232569, PropertyName: "value", Value: "[datafield:eklenmetarihi]" },
        { Id: 945308, PropertyName: "title", Value: "Üyelik Tipi" },
        { Id: 834606, PropertyName: "value", Value: "[datafield:uyeliktipi]" },
        { Id: 416892, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.danisanlist_814341_value_kuikaSelectBoxRef = React.createRef();
    this.danisanlist_755660_value_kuikaTableRef = React.createRef();
    this.danisanlist_692036_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      DanisanSearch: [],
      DanisanSearchCount: [],
      dataSource_814341: [
        { key: "true", text: "Aktif" },
        { key: "false", text: "Pasif" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("danisanlist", "danisanlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DanisanListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("danisanlist", "danisanlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("danisanlist", "danisanlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DanisanListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  DanisanListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanList/DanisanListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DanisanSearch = result?.data.danisanSearch;

    stateVars.DanisanSearchCount = result?.data.danisanSearchCount;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DanisanSearchCount?.length > 0 ? stateVars.DanisanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.DanisanSearch;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    formVars.danisanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DanisanListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DanisanList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "250739",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanListComponent_2755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanList/DanisanListComponent_2755_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DanisanSearch = result?.data.danisanSearch;

    stateVars.DanisanSearchCount = result?.data.danisanSearchCount;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DanisanSearchCount?.length > 0 ? stateVars.DanisanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanListComponent_2755_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanListComponent_2755_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.DanisanSearch;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    formVars.danisanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanListComponent_814341_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanList/DanisanListComponent_814341_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DanisanSearch = result?.data.danisanSearch;

    stateVars.DanisanSearchCount = result?.data.danisanSearchCount;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DanisanSearchCount?.length > 0 ? stateVars.DanisanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanListComponent_814341_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanListComponent_814341_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.DanisanSearch;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    formVars.danisanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("DanisanEkle", "DanisanID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("DanisanEkle", "CurrentLang", "tr_TR");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DanisanList",
      "DanisanEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "539832",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DanisanProfil",
      "DanisanID",
      ReactSystemFunctions.value(this, "danisanlist_755660_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "DanisanProfil",
      "CurrentLang",
      ReactSystemFunctions.value(this, "danisanlist_755660_value", "dilSecimi")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "DanisanList",
      "DanisanProfil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DanisanListComponent_692036_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_2755_value", "value", "", "", "")
        ),
        "string"
      ),
      isActive_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "danisanlist_814341_value", "value", "", "key", "")
        ),
        "boolean"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisanlist_692036_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "DanisanList/DanisanListComponent_692036_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DanisanSearch = result?.data.danisanSearch;

    stateVars.DanisanSearchCount = result?.data.danisanSearchCount;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      stateVars.DanisanSearchCount?.length > 0 ? stateVars.DanisanSearchCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.DanisanListComponent_692036_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanListComponent_692036_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.DanisanSearch;
    formVars.danisanlist_305772_value = ReactSystemFunctions.toString(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    formVars.danisanlist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.DanisanSearchCount?.length > 0 ? this.state.DanisanSearchCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [250739, 539832] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DanisanListPageInit();
    }
  }
}
