import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHastalikEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHastalikEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  HastalikLangByCondition: any[];
  HastalikLangByCondition_dummy: any[];
  SelectHastalikTranslation: any[];
  SelectHastalikTranslation_dummy: any[];
  HastalikSave: number;
  HastalikSave_dummy: number;
  HastalikTranslationSave: number;
  HastalikTranslationSave_dummy: number;
}

export class HastalikEkle_ScreenBase extends React.PureComponent<IHastalikEkle_ScreenProps, any> {
  hastalikekle_40879_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "2692c802-3b6d-4625-a00b-51ccb6452145",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Hastalık Ekle" },
        { Id: 404697, PropertyName: "value", Value: "Dil" },
        { Id: 40879, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 742125, PropertyName: "value", Value: "Hastalık" },
        { Id: 856375, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.hastalikekle_40879_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      HastalikLangByCondition: [],
      SelectHastalikTranslation: [],
      HastalikSave: 0,
      HastalikTranslationSave: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("hastalikekle", "hastalikekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HastalikEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("hastalikekle", "hastalikekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("hastalikekle", "hastalikekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HastalikEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      hastalikekle_40879_value: this.state.SelectHastalikTranslation?.at?.(0)?.lang ?? undefined,
      hastalikekle_116134_value: this.state.SelectHastalikTranslation?.at?.(0)?.hastalikAd ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HastalikEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      HastalikId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HastalikID ?? this.props.screenInputs.hastalikid,
        "Guid"
      ),
      HastalikTranslationId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HastalikTranslationID ?? this.props.screenInputs.hastaliktranslationid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HastalikEkle/HastalikEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikLangByCondition = result?.data.hastalikLangByCondition;

    formVars.hastalikekle_40879_value =
      stateVars.SelectHastalikTranslation?.length > 0 ? stateVars.SelectHastalikTranslation[0]?.lang : null;
    formVars.hastalikekle_40879_options = stateVars.HastalikLangByCondition;
    stateVars.SelectHastalikTranslation = result?.data.selectHastalikTranslation;
    formVars.hastalikekle_40879_value =
      stateVars.SelectHastalikTranslation?.length > 0 ? stateVars.SelectHastalikTranslation[0]?.lang : null;
    formVars.hastalikekle_40879_options = stateVars.HastalikLangByCondition;
    formVars.hastalikekle_116134_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectHastalikTranslation?.length > 0 ? stateVars.SelectHastalikTranslation[0]?.hastalikAd : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.hastalikekle_40879_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectHastalikTranslation?.length > 0 ? this.state.SelectHastalikTranslation[0]?.lang : null
    );

    stateVars.dataSource_40879 = this.state.HastalikLangByCondition;
    stateVars.dataSource_40879 = this.state.HastalikLangByCondition;
    formVars.hastalikekle_116134_value = ReactSystemFunctions.toString(
      this,
      this.state.SelectHastalikTranslation?.length > 0 ? this.state.SelectHastalikTranslation[0]?.hastalikAd : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HastalikEkleComponent_395018_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HastalikEkleComponent_856375_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Tip_0: ReactSystemFunctions.convertToTypeByName("Hastalık", "string"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HastalikID ?? this.props.screenInputs.hastalikid,
        "Guid"
      ),
      HastalikAd_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hastalikekle_116134_value", "value", "", "", "")
        ),
        "string"
      ),
      HastalikID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HastalikID ?? this.props.screenInputs.hastalikid,
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "hastalikekle_40879_value", "value", "HastalikLangByCondition", "kod", "kod")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HastalikTranslationID ?? this.props.screenInputs.hastaliktranslationid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HastalikEkle/HastalikEkleComponent_856375_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HastalikSave = result?.data.hastalikSave;
    stateVars.HastalikTranslationSave = result?.data.hastalikTranslationSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HastalikEkleComponent_856375_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HastalikEkleComponent_856375_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
