import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKullaniciForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKullaniciForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class KullaniciForm_ScreenBase extends React.PureComponent<IKullaniciForm_ScreenProps, any> {
  kullaniciform_683243_value_kuikaDateRef: React.RefObject<any>;
  kullaniciform_885582_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "5065b07a-0173-4db4-95f5-21d2424f53a2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Kullanıcı" },
        { Id: 429915, PropertyName: "value", Value: "Ad" },
        { Id: 222573, PropertyName: "value", Value: "Soyad" },
        { Id: 829835, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 430215, PropertyName: "value", Value: "Email" },
        { Id: 714323, PropertyName: "value", Value: "Tarih" },
        { Id: 298886, PropertyName: "value", Value: "Rolü" },
        { Id: 13543, PropertyName: "label", Value: "Sil" },
        { Id: 125849, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.kullaniciform_683243_value_kuikaDateRef = React.createRef();
    this.kullaniciform_885582_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kullaniciform", "kullaniciform");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kullaniciform_377603_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
