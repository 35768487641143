import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISemptomList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISemptomList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Semptoms: any[];
  Semptoms_dummy: any[];
  SemptomsCount: any[];
  SemptomsCount_dummy: any[];
  SemptomDelete: number;
  SemptomDelete_dummy: number;
}

export class SemptomList_ScreenBase extends React.PureComponent<ISemptomList_ScreenProps, any> {
  semptomlist_423262_value_kuikaTableRef: React.RefObject<any>;
  semptomlist_160062_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "07ec4c20-0d7c-4f26-bc18-e08e9a84bd45",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 460315, PropertyName: "value", Value: "Semptomlar" },
        { Id: 99575, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 727721, PropertyName: "label", Value: "Profil" },
        { Id: 363060, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 523282, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 206178, PropertyName: "label", Value: "Yeni Semptom Ekle" },
        { Id: 423262, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 789566, PropertyName: "title", Value: "Vücut Bölgesi" },
        { Id: 665474, PropertyName: "value", Value: "[datafield:vucutbolgesi]" },
        { Id: 805800, PropertyName: "title", Value: "Semptom" },
        { Id: 539459, PropertyName: "value", Value: "[datafield:semptom]" },
        { Id: 611131, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.semptomlist_423262_value_kuikaTableRef = React.createRef();
    this.semptomlist_160062_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Semptoms: [],
      SemptomsCount: [],
      SemptomDelete: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("semptomlist", "semptomlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SemptomListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("semptomlist", "semptomlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("semptomlist", "semptomlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SemptomListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SemptomListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomList/SemptomListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Semptoms = result?.data.semptoms;

    stateVars.SemptomsCount = result?.data.semptomsCount;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomsCount?.length > 0 ? stateVars.SemptomsCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.Semptoms;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    formVars.semptomlist_160062_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SemptomListComponent_716309_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "148360",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomListComponent_523282_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomList/SemptomListComponent_523282_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Semptoms = result?.data.semptoms;

    stateVars.SemptomsCount = result?.data.semptomsCount;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomsCount?.length > 0 ? stateVars.SemptomsCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomListComponent_523282_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomListComponent_523282_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.Semptoms;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    formVars.semptomlist_160062_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomListComponent_206178_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "CurrentLang", "tr_TR");
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "WaitFor", "New");
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "SemptomID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "SemptomTranslationID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "isVBEditable", true);
    KuikaAppManager.addToPageInputVariables("SemptomEkle", "VucutBolgeID", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomList",
      "SemptomEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "558429",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomListComponent_647460_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "SemptomTransList",
      "SemptomID",
      ReactSystemFunctions.value(this, "semptomlist_423262_value", "semptomId")
    );
    KuikaAppManager.addToPageInputVariables(
      "SemptomTransList",
      "VucutBolgeID",
      ReactSystemFunctions.value(this, "semptomlist_423262_value", "vucutBolgesiId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SemptomList",
      "SemptomTransList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomListComponent_13366_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      SemptomId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "semptomlist_423262_value", "semptomId"),
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4851a3ef_6b64_d07f_eb70_74c7b2f58b03_confirmation",
        this.defaultML,
        "Bu semptom ve translationları silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SemptomList/SemptomListComponent_13366_onClick", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.SemptomDelete = result?.data.semptomDelete;
        stateVars.Semptoms = result?.data.semptoms;

        stateVars.SemptomsCount = result?.data.semptomsCount;
        formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
          this,
          stateVars.SemptomsCount?.length > 0 ? stateVars.SemptomsCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.SemptomListComponent_13366_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomListComponent_13366_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.Semptoms;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    formVars.semptomlist_160062_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomListComponent_160062_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_160062_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      SearchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "semptomlist_523282_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomList/SemptomListComponent_160062_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Semptoms = result?.data.semptoms;

    stateVars.SemptomsCount = result?.data.semptomsCount;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomsCount?.length > 0 ? stateVars.SemptomsCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomListComponent_160062_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomListComponent_160062_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_423262 = this.state.Semptoms;
    formVars.semptomlist_473171_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    formVars.semptomlist_160062_total = ReactSystemFunctions.value(
      this,
      this.state.SemptomsCount?.length > 0 ? this.state.SemptomsCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [148360, 558429] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SemptomListPageInit();
    }
  }
}
