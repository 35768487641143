import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVucutBolgesiEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVucutBolgesiEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  VucutBolgesiLangByCondition: any[];
  VucutBolgesiLangByCondition_dummy: any[];
  VBTranslationById: any[];
  VBTranslationById_dummy: any[];
  VucutBolgesiSave: number;
  VucutBolgesiSave_dummy: number;
  VBTranslationSave: number;
  VBTranslationSave_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp435603Visible: "visible" | "hidden";
}

export class VucutBolgesiEkle_ScreenBase extends React.PureComponent<IVucutBolgesiEkle_ScreenProps, any> {
  vucutbolgesiekle_144214_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "cf924f04-ee2e-439e-9652-ca07e825bc1d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 151318, PropertyName: "value", Value: "Vücut Bölgesi" },
        { Id: 427110, PropertyName: "value", Value: "Dil" },
        { Id: 144214, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 398519, PropertyName: "value", Value: "Tanım" },
        { Id: 355704, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 192050, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.vucutbolgesiekle_144214_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      VucutBolgesiLangByCondition: [],
      VBTranslationById: [],
      VucutBolgesiSave: 0,
      IfThenElse: false,
      isComp435603Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesiekle", "vucutbolgesiekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.VucutBolgesiEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesiekle", "vucutbolgesiekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesiekle", "vucutbolgesiekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.VucutBolgesiEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      vucutbolgesiekle_144214_value: this.state.VBTranslationById?.at?.(0)?.lang ?? undefined,
      vucutbolgesiekle_355704_value: this.state.VBTranslationById?.at?.(0)?.tanim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  VucutBolgesiEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VBTranslationID ?? this.props.screenInputs.vbtranslationid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "VucutBolgesiEkle/VucutBolgesiEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VucutBolgesiLangByCondition = result?.data.vucutBolgesiLangByCondition;

    formVars.vucutbolgesiekle_144214_value =
      stateVars.VBTranslationById?.length > 0 ? stateVars.VBTranslationById[0]?.lang : null;
    formVars.vucutbolgesiekle_144214_options = stateVars.VucutBolgesiLangByCondition;
    stateVars.VBTranslationById = result?.data.vbTranslationById;
    formVars.vucutbolgesiekle_144214_value =
      stateVars.VBTranslationById?.length > 0 ? stateVars.VBTranslationById[0]?.lang : null;
    formVars.vucutbolgesiekle_144214_options = stateVars.VucutBolgesiLangByCondition;
    formVars.vucutbolgesiekle_355704_value = ReactSystemFunctions.toString(
      this,
      stateVars.VBTranslationById?.length > 0 ? stateVars.VBTranslationById[0]?.tanim : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VucutBolgesiEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp435603Visible =
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New") ===
      true
        ? "visible"
        : "hidden";
    formVars.vucutbolgesiekle_144214_value = ReactSystemFunctions.toString(
      this,
      this.state.VBTranslationById?.length > 0 ? this.state.VBTranslationById[0]?.lang : null
    );

    stateVars.dataSource_144214 = this.state.VucutBolgesiLangByCondition;
    stateVars.dataSource_144214 = this.state.VucutBolgesiLangByCondition;
    formVars.vucutbolgesiekle_355704_value = ReactSystemFunctions.toString(
      this,
      this.state.VBTranslationById?.length > 0 ? this.state.VBTranslationById[0]?.tanim : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  VucutBolgesiEkleComponent_198855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiEkleComponent_192050_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VucutBolgesiEkle/VucutBolgesiEkleComponent_192050_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VucutBolgesiSave = result?.data.vucutBolgesiSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VucutBolgesiEkleComponent_192050_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiEkleComponent_192050_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vucutbolgesiekle_355704_value", "value", "", "", "")
        ),
        "string"
      ),
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      ),
      VucutBolgesiTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VBTranslationID ?? this.props.screenInputs.vbtranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VucutBolgesiEkle/VucutBolgesiEkleComponent_192050_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationSave = result?.data.vbTranslationSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  VucutBolgesiEkleComponent_192050_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "vucutbolgesiekle_144214_value",
            "value",
            "VucutBolgesiLangByCondition",
            "kod",
            "kod"
          )
        ),
        "string"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "vucutbolgesiekle_355704_value", "value", "", "", "")
        ),
        "string"
      ),
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      ),
      VucutBolgesiTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VBTranslationID ?? this.props.screenInputs.vbtranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VucutBolgesiEkle/VucutBolgesiEkleComponent_192050_onClick_else1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationSave = result?.data.vbTranslationSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  VucutBolgesiEkleComponent_192050_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New")) {
      isErrorOccurred = await this.VucutBolgesiEkleComponent_192050_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.VucutBolgesiEkleComponent_192050_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
