import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { UzmanlikAlaniTransList_Screen } from "../screens/uzmanlikalanitranslist/uzmanlikalanitranslist";
import { SemptomTransList_Screen } from "../screens/semptomtranslist/semptomtranslist";
import { UzmanlikAlaniList_Screen } from "../screens/uzmanlikalanilist/uzmanlikalanilist";
import { Bildirim_Screen } from "../screens/bildirim/bildirim";
import { SemptomOneriList_Screen } from "../screens/semptomonerilist/semptomonerilist";
import { TaramaTestiTranslation_Screen } from "../screens/taramatestitranslation/taramatestitranslation";
import { SemptonView_Screen } from "../screens/semptonview/semptonview";
import { IndirimKoduList_Screen } from "../screens/indirimkodulist/indirimkodulist";
import { VucutBolgesiEkle_Screen } from "../screens/vucutbolgesiekle/vucutbolgesiekle";
import { KullanicilarList_Screen } from "../screens/kullanicilarlist/kullanicilarlist";
import { BelgeEkle_Screen } from "../screens/belgeekle/belgeekle";
import { HastalikEkle_Screen } from "../screens/hastalikekle/hastalikekle";
import { SemptomEkle_Screen } from "../screens/semptomekle/semptomekle";
import { DanisanProfil_Screen } from "../screens/danisanprofil/danisanprofil";
import { AsiEkle_Screen } from "../screens/asiekle/asiekle";
import { UzmanEkle_Screen } from "../screens/uzmanekle/uzmanekle";
import { VucutBolgesiTransList_Screen } from "../screens/vucutbolgesitranslist/vucutbolgesitranslist";
import { UzmanList_Screen } from "../screens/uzmanlist/uzmanlist";
import { Sales_Dashboard_With_Left_Menu_Screen } from "../screens/sales_dashboard_with_left_menu/sales_dashboard_with_left_menu";
import { UzmanProfilEdit_Screen } from "../screens/uzmanprofiledit/uzmanprofiledit";
import { AsiList_Screen } from "../screens/asilist/asilist";
import { DanisanList_Screen } from "../screens/danisanlist/danisanlist";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { PlanEkle_Screen } from "../screens/planekle/planekle";
import { UzmanProfil_Screen } from "../screens/uzmanprofil/uzmanprofil";
import { HomePage_Screen } from "../screens/homepage/homepage";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { KullaniciForm_Screen } from "../screens/kullaniciform/kullaniciform";
import { PlanOzellikler_Screen } from "../screens/planozellikler/planozellikler";
import { Signup_Screen } from "../screens/signup/signup";
import { SemptomOneriTransList_Screen } from "../screens/semptomoneritranslist/semptomoneritranslist";
import { YedekSemptomOneriTranslationList_Screen } from "../screens/yedeksemptomoneritranslationlist/yedeksemptomoneritranslationlist";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { UzmanlikAlaniEkle_Screen } from "../screens/uzmanlikalaniekle/uzmanlikalaniekle";
import { Signin_Screen } from "../screens/signin/signin";
import { YedekTaramaTestiTranslation_Screen } from "../screens/yedektaramatestitranslation/yedektaramatestitranslation";
import { SemptomOneriEkle_Screen } from "../screens/semptomoneriekle/semptomoneriekle";
import { VucutBolgesiList_Screen } from "../screens/vucutbolgesilist/vucutbolgesilist";
import { SemptomList_Screen } from "../screens/semptomlist/semptomlist";
import { Marketing_Dashboard_With_Left_Menu_Screen } from "../screens/marketing_dashboard_with_left_menu/marketing_dashboard_with_left_menu";
import { UzmanBelgeOnayView_Screen } from "../screens/uzmanbelgeonayview/uzmanbelgeonayview";
import { AsiTranslationList_Screen } from "../screens/asitranslationlist/asitranslationlist";
import { DanisanEkle_Screen } from "../screens/danisanekle/danisanekle";
import { HastalikTranslationList_Screen } from "../screens/hastaliktranslationlist/hastaliktranslationlist";
import { GateWay_Screen } from "../screens/gateway/gateway";
import { TaramaTestiEkle_Screen } from "../screens/taramatestiekle/taramatestiekle";
import { TestScreen_Screen } from "../screens/testscreen/testscreen";
import { YedekAsiTranslationList_Screen } from "../screens/yedekasitranslationlist/yedekasitranslationlist";
import { TaramaTestiList_Screen } from "../screens/taramatestilist/taramatestilist";
import { UzmanBelgeOnayList_Screen } from "../screens/uzmanbelgeonaylist/uzmanbelgeonaylist";
import { HastalikList_Screen } from "../screens/hastaliklist/hastaliklist";
import { IndirimKoduForm_Screen } from "../screens/indirimkoduform/indirimkoduform";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={HomePage_Screen} />
                        <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/uzmanlikalanitranslist" component={UzmanlikAlaniTransList_Screen} />
            <PrivateRoute exact path="/semptomtranslist" component={SemptomTransList_Screen} />
            <PrivateRoute exact path="/uzmanlikalanilist" component={UzmanlikAlaniList_Screen} />
            <PrivateRoute exact path="/bildirim" component={Bildirim_Screen} />
            <PrivateRoute exact path="/semptomonerilist" component={SemptomOneriList_Screen} />
            <PrivateRoute exact path="/taramatestitranslation" component={TaramaTestiTranslation_Screen} />
            <PrivateRoute exact path="/semptonview" component={SemptonView_Screen} />
            <PrivateRoute exact path="/indirimkodulist" component={IndirimKoduList_Screen} />
            <PrivateRoute exact path="/vucutbolgesiekle" component={VucutBolgesiEkle_Screen} />
            <PrivateRoute exact path="/kullanicilarlist" component={KullanicilarList_Screen} />
            <PrivateRoute exact path="/belgeekle" component={BelgeEkle_Screen} />
            <PrivateRoute exact path="/hastalikekle" component={HastalikEkle_Screen} />
            <PrivateRoute exact path="/semptomekle" component={SemptomEkle_Screen} />
            <PrivateRoute exact path="/danisanprofil" component={DanisanProfil_Screen} />
            <PrivateRoute exact path="/asiekle" component={AsiEkle_Screen} />
            <PrivateRoute exact path="/uzmanekle" component={UzmanEkle_Screen} />
            <PrivateRoute exact path="/vucutbolgesitranslist" component={VucutBolgesiTransList_Screen} />
            <PrivateRoute exact path="/uzmanlist" component={UzmanList_Screen} />
            <PrivateRoute exact path="/sales_dashboard_with_left_menu" component={Sales_Dashboard_With_Left_Menu_Screen} />
            <PrivateRoute exact path="/uzmanprofiledit" component={UzmanProfilEdit_Screen} />
            <PrivateRoute exact path="/asilist" component={AsiList_Screen} />
            <PrivateRoute exact path="/danisanlist" component={DanisanList_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/planekle" component={PlanEkle_Screen} />
            <PrivateRoute exact path="/uzmanprofil" component={UzmanProfil_Screen} />
            <PrivateRoute exact path="/homepage" component={HomePage_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <PrivateRoute exact path="/kullaniciform" component={KullaniciForm_Screen} />
            <PrivateRoute exact path="/planozellikler" component={PlanOzellikler_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/semptomoneritranslist" component={SemptomOneriTransList_Screen} />
            <PrivateRoute exact path="/yedeksemptomoneritranslationlist" component={YedekSemptomOneriTranslationList_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/uzmanlikalaniekle" component={UzmanlikAlaniEkle_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/yedektaramatestitranslation" component={YedekTaramaTestiTranslation_Screen} />
            <PrivateRoute exact path="/semptomoneriekle" component={SemptomOneriEkle_Screen} />
            <PrivateRoute exact path="/vucutbolgesilist" component={VucutBolgesiList_Screen} />
            <PrivateRoute exact path="/semptomlist" component={SemptomList_Screen} />
            <PrivateRoute exact path="/marketing_dashboard_with_left_menu" component={Marketing_Dashboard_With_Left_Menu_Screen} />
            <PrivateRoute exact path="/uzmanbelgeonayview" component={UzmanBelgeOnayView_Screen} />
            <PrivateRoute exact path="/asitranslationlist" component={AsiTranslationList_Screen} />
            <PrivateRoute exact path="/danisanekle" component={DanisanEkle_Screen} />
            <PrivateRoute exact path="/hastaliktranslationlist" component={HastalikTranslationList_Screen} />
            <PrivateRoute exact path="/gateway" component={GateWay_Screen} />
            <PrivateRoute exact path="/taramatestiekle" component={TaramaTestiEkle_Screen} />
            <PrivateRoute exact path="/testscreen" component={TestScreen_Screen} />
            <PrivateRoute exact path="/yedekasitranslationlist" component={YedekAsiTranslationList_Screen} />
            <PrivateRoute exact path="/taramatestilist" component={TaramaTestiList_Screen} />
            <PrivateRoute exact path="/uzmanbelgeonaylist" component={UzmanBelgeOnayList_Screen} />
            <PrivateRoute exact path="/hastaliklist" component={HastalikList_Screen} />
            <PrivateRoute exact path="/indirimkoduform" component={IndirimKoduForm_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
