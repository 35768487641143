import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUzmanBelgeOnayView_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUzmanBelgeOnayView_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  KullaniciBelgeById: any[];
  KullaniciBelgeById_dummy: any[];
  KullaniciBelgeOnaylaReddet: number;
  KullaniciBelgeOnaylaReddet_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
}

export class UzmanBelgeOnayView_ScreenBase extends React.PureComponent<IUzmanBelgeOnayView_ScreenProps, any> {
  uzmanbelgeonayview_937529_url_kuikaWebViewRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "1f2433a0-9de5-4a50-b711-aeb6614117dc",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 507666, PropertyName: "value", Value: "Uzman Belge Onay" },
        { Id: 604010, PropertyName: "value", Value: "Uzmanlık Belgesi" },
        { Id: 806845, PropertyName: "label", Value: "Onayla" },
        { Id: 80265, PropertyName: "label", Value: "Reddet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.uzmanbelgeonayview_937529_url_kuikaWebViewRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      KullaniciBelgeById: [],
      KullaniciBelgeOnaylaReddet: 0,
      GoBack: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("uzmanbelgeonayview", "uzmanbelgeonayview");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UzmanBelgeOnayViewPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("uzmanbelgeonayview", "uzmanbelgeonayview");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("uzmanbelgeonayview", "uzmanbelgeonayview");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UzmanBelgeOnayViewPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  UzmanBelgeOnayViewPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KullaniciBelgeID ?? this.props.screenInputs.kullanicibelgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UzmanBelgeOnayView/UzmanBelgeOnayViewPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciBelgeById = result?.data.kullaniciBelgeById;
    formVars.uzmanbelgeonayview_937529_url = ReactSystemFunctions.toString(
      this,
      stateVars.KullaniciBelgeById?.length > 0 ? stateVars.KullaniciBelgeById[0]?.belge : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanBelgeOnayViewPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanBelgeOnayViewPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.uzmanbelgeonayview_937529_url = ReactSystemFunctions.toString(
      this,
      this.state.KullaniciBelgeById?.length > 0 ? this.state.KullaniciBelgeById[0]?.belge : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UzmanBelgeOnayViewComponent_806845_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OnaylandiMi_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KullaniciBelgeID ?? this.props.screenInputs.kullanicibelgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanBelgeOnayView/UzmanBelgeOnayViewComponent_806845_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciBelgeOnaylaReddet = result?.data.kullaniciBelgeOnaylaReddet;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanBelgeOnayViewComponent_806845_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanBelgeOnayViewComponent_806845_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UzmanBelgeOnayViewComponent_80265_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      OnaylandiMi_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KullaniciBelgeID ?? this.props.screenInputs.kullanicibelgeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UzmanBelgeOnayView/UzmanBelgeOnayViewComponent_80265_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciBelgeOnaylaReddet = result?.data.kullaniciBelgeOnaylaReddet;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UzmanBelgeOnayViewComponent_80265_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UzmanBelgeOnayViewComponent_80265_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
