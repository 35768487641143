import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISemptomEkle_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISemptomEkle_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  VBTranslationByLang: any[];
  VBTranslationByLang_dummy: any[];
  SemptomsLangByCondition: any[];
  SemptomsLangByCondition_dummy: any[];
  SemptomTransById: any[];
  SemptomTransById_dummy: any[];
  SemptomSave: number;
  SemptomSave_dummy: number;
  SemptomTransSave: number;
  SemptomTransSave_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp757869Visible: "visible" | "hidden";
  isComp435603Visible: "visible" | "hidden";
}

export class SemptomEkle_ScreenBase extends React.PureComponent<ISemptomEkle_ScreenProps, any> {
  semptomekle_285251_value_kuikaSelectBoxRef: React.RefObject<any>;
  semptomekle_144214_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "dc0b74f8-e77b-4d0e-a39d-cb692166fa18",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 151318, PropertyName: "value", Value: "Semptom" },
        { Id: 150374, PropertyName: "value", Value: "Vücut Bölgesi" },
        { Id: 285251, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 427110, PropertyName: "value", Value: "Dil" },
        { Id: 144214, PropertyName: "placeholder", Value: "Choose one..." },
        { Id: 398519, PropertyName: "value", Value: "Tanım" },
        { Id: 676272, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 192050, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.semptomekle_285251_value_kuikaSelectBoxRef = React.createRef();
    this.semptomekle_144214_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      VBTranslationByLang: [],
      SemptomsLangByCondition: [],
      SemptomTransById: [],
      SemptomSave: 0,
      IfThenElse: false,
      isComp757869Visible: "hidden",
      isComp435603Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("semptomekle", "semptomekle");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SemptomEklePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("semptomekle", "semptomekle");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("semptomekle", "semptomekle");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SemptomEklePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      semptomekle_285251_value: this.state.SemptomTransById?.at?.(0)?.vucutBolgesiId ?? undefined,
      semptomekle_144214_value: this.state.SemptomTransById?.at?.(0)?.lang ?? undefined,
      semptomekle_676272_value: this.state.SemptomTransById?.at?.(0)?.tanim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SemptomEklePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      WaitFor_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      ),
      SemptomId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomTranslationID ?? this.props.screenInputs.semptomtranslationid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomEkle/SemptomEklePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationByLang = result?.data.vbTranslationByLang;

    formVars.semptomekle_285251_value =
      stateVars.SemptomTransById?.length > 0 ? stateVars.SemptomTransById[0]?.vucutBolgesiId : null;
    formVars.semptomekle_285251_options = stateVars.VBTranslationByLang;
    stateVars.SemptomsLangByCondition = result?.data.semptomsLangByCondition;

    formVars.semptomekle_144214_value =
      stateVars.SemptomTransById?.length > 0 ? stateVars.SemptomTransById[0]?.lang : null;
    formVars.semptomekle_144214_options = stateVars.SemptomsLangByCondition;
    stateVars.SemptomTransById = result?.data.semptomTransById;
    formVars.semptomekle_285251_value =
      stateVars.SemptomTransById?.length > 0 ? stateVars.SemptomTransById[0]?.vucutBolgesiId : null;
    formVars.semptomekle_285251_options = stateVars.VBTranslationByLang;
    formVars.semptomekle_144214_value =
      stateVars.SemptomTransById?.length > 0 ? stateVars.SemptomTransById[0]?.lang : null;
    formVars.semptomekle_144214_options = stateVars.SemptomsLangByCondition;
    formVars.semptomekle_676272_value = ReactSystemFunctions.toString(
      this,
      stateVars.SemptomTransById?.length > 0 ? stateVars.SemptomTransById[0]?.tanim : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomEklePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomEklePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp757869Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "tr_TR"
      ) === true
        ? "visible"
        : "hidden";
    formVars.semptomekle_285251_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomTransById?.length > 0 ? this.state.SemptomTransById[0]?.vucutBolgesiId : null
    );

    stateVars.dataSource_285251 = this.state.VBTranslationByLang;
    stateVars.dataSource_285251 = this.state.VBTranslationByLang;
    stateVars.isComp435603Visible =
      ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New") ===
      true
        ? "visible"
        : "hidden";
    formVars.semptomekle_144214_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomTransById?.length > 0 ? this.state.SemptomTransById[0]?.lang : null
    );

    stateVars.dataSource_144214 = this.state.SemptomsLangByCondition;
    stateVars.dataSource_144214 = this.state.SemptomsLangByCondition;
    formVars.semptomekle_676272_value = ReactSystemFunctions.toString(
      this,
      this.state.SemptomTransById?.length > 0 ? this.state.SemptomTransById[0]?.tanim : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SemptomEkleComponent_198855_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SemptomEkleComponent_192050_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SemptomEkle/SemptomEkleComponent_192050_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomSave = result?.data.semptomSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.SemptomEkleComponent_192050_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SemptomEkleComponent_192050_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "semptomekle_676272_value", "value", "", "", "")
        ),
        "string"
      ),
      SemptomId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      ),
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "semptomekle_285251_value",
            "value",
            "VBTranslationByLang",
            "vucutBolgesiId",
            "vucutBolgesiId"
          )
        ),
        "Guid"
      ),
      SemptomTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomTranslationID ?? this.props.screenInputs.semptomtranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomEkle/SemptomEkleComponent_192050_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomTransSave = result?.data.semptomTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SemptomEkleComponent_192050_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CurrentLang_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CurrentLang ?? this.props.screenInputs.currentlang,
        "string"
      ),
      NewLang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "semptomekle_144214_value", "value", "SemptomsLangByCondition", "kod", "kod")
        ),
        "string"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "semptomekle_676272_value", "value", "", "", "")
        ),
        "string"
      ),
      SemptomId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomID ?? this.props.screenInputs.semptomid,
        "Guid"
      ),
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.VucutBolgeID ?? this.props.screenInputs.vucutbolgeid,
        "Guid"
      ),
      SemptomTranslationId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.SemptomTranslationID ?? this.props.screenInputs.semptomtranslationid,
        "Guid"
      ),
      WaitFor_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SemptomEkle/SemptomEkleComponent_192050_onClick_else1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SemptomTransSave = result?.data.semptomTransSave;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SemptomEkleComponent_192050_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.WaitFor ?? this.props.screenInputs.waitfor, "New")) {
      isErrorOccurred = await this.SemptomEkleComponent_192050_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.SemptomEkleComponent_192050_onClick_else1();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
