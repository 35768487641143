import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILeftMenu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILeftMenu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetLanguage: string;
  GetLanguage_dummy: string;
  KullaniciByUsername: any[];
  KullaniciByUsername_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp712863Visible: "visible" | "hidden";
  isComp14901Visible: "visible" | "hidden";
  isComp777960Visible: "visible" | "hidden";
  isComp272302Visible: "visible" | "hidden";
  isComp361130Visible: "visible" | "hidden";
  isComp761851Visible: "visible" | "hidden";
  isComp569987Visible: "visible" | "hidden";
  isComp625065Visible: "visible" | "hidden";
  isComp555815Visible: "visible" | "hidden";
  isComp677878Visible: "visible" | "hidden";
  isComp15800Visible: "visible" | "hidden";
  isComp701344Visible: "visible" | "hidden";
  isComp273257Visible: "visible" | "hidden";
  isComp663260Visible: "visible" | "hidden";
  isComp311836Visible: "visible" | "hidden";
}

export class LeftMenu_ScreenBase extends React.PureComponent<ILeftMenu_ScreenProps, any> {
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "10df969e-7c44-4cf2-af11-a0e72c3543dd",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 501123, PropertyName: "value", Value: "BabyStar Menu" },
        { Id: 950642, PropertyName: "value", Value: "Dashboard" },
        { Id: 649936, PropertyName: "value", Value: "Aşılar" },
        { Id: 567190, PropertyName: "value", Value: "Tarama Testleri" },
        { Id: 483477, PropertyName: "value", Value: "Vücut Bölgesi" },
        { Id: 381584, PropertyName: "value", Value: "İndirim Kodu" },
        { Id: 770798, PropertyName: "value", Value: "Semptomlar" },
        { Id: 363173, PropertyName: "value", Value: "Hastalıklar" },
        { Id: 284531, PropertyName: "value", Value: "Semptomdan Teşhise" },
        { Id: 907285, PropertyName: "value", Value: "Uzmanlık Alanları" },
        { Id: 3044, PropertyName: "value", Value: "Üyelik Planları" },
        { Id: 390103, PropertyName: "value", Value: "Kullanıcılar" },
        { Id: 203129, PropertyName: "value", Value: "Danışanlar" },
        { Id: 552857, PropertyName: "value", Value: "Uzmanlar" },
        { Id: 834013, PropertyName: "value", Value: "Kurumsallar" },
        { Id: 959900, PropertyName: "value", Value: "Gateway Results" },
        { Id: 111634, PropertyName: "value", Value: "Profilim" },
        { Id: 188381, PropertyName: "value", Value: "Logout" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetLanguage: "",
      KullaniciByUsername: [],
      GoBack: "",
      NAVIGATE: "",
      ChangeVisibilityOf: "",
      Logout: false,
      goToSignInActivity: "",
      isComp712863Visible: "hidden",
      isComp14901Visible: "visible",
      isComp777960Visible: "hidden",
      isComp272302Visible: "hidden",
      isComp361130Visible: "hidden",
      isComp761851Visible: "hidden",
      isComp569987Visible: "hidden",
      isComp625065Visible: "hidden",
      isComp555815Visible: "hidden",
      isComp677878Visible: "hidden",
      isComp15800Visible: "hidden",
      isComp701344Visible: "hidden",
      isComp273257Visible: "hidden",
      isComp663260Visible: "hidden",
      isComp311836Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
      return;
    }

    if (true) {
      await this.LeftMenuPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("leftmenu", "leftmenu");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.LeftMenuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LeftMenuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.LeftMenuPageInit1_();
    });

    return isErrorOccurred;
  };
  LeftMenuPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetLanguage),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "LeftMenu/LeftMenuPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciByUsername = result?.data.kullaniciByUsername;
    stateVars.isComp272302Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp361130Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761851Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp569987Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp625065Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp677878Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15800Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701344Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663260Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp311836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciByUsername?.length > 0 ? stateVars.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Uzman"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.LeftMenuPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  LeftMenuPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp272302Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp361130Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp761851Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp569987Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp625065Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp677878Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15800Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701344Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273257Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663260Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Admin"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp311836Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.kullaniciTipi : null
        ),
        "Uzman"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  LeftMenuComponent_620279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_822360_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "HomePage",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_272302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "AsiList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_361130_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "TaramaTestiList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_761851_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "VucutBolgesiList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_569987_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "IndirimKoduList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_476201_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SemptomList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_555815_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "HastalikList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_677878_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "SemptomOneriList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_15800_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UzmanlikAlaniList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_701344_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "PlanOzellikler",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_14901_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp712863Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp14901Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp777960Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_777960_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp712863Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp777960Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp14901Visible", "visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_267585_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "DanisanList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_483346_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UzmanList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_663260_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "GateWay",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_311836_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UzmanProfilEdit",
      "UzmanID",
      ReactSystemFunctions.value(
        this,
        this.state.KullaniciByUsername?.length > 0 ? this.state.KullaniciByUsername[0]?.id : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "UzmanProfilEdit",
      "CurrentLang",
      ReactSystemFunctions.toString(this, this.state.GetLanguage)
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "LeftMenu",
      "UzmanProfilEdit",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LeftMenuComponent_20424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, , , , , , , , , , , , ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.LeftMenuPageInit();
    }
  }
}
