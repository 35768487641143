import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYedekSemptomOneriTranslationList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYedekSemptomOneriTranslationList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class YedekSemptomOneriTranslationList_ScreenBase extends React.PureComponent<
  IYedekSemptomOneriTranslationList_ScreenProps,
  any
> {
  yedeksemptomoneritranslationlist_622426_value_kuikaTableRef: React.RefObject<any>;
  yedeksemptomoneritranslationlist_409349_value_kuikaTableRef: React.RefObject<any>;
  yedeksemptomoneritranslationlist_360380_value_kuikaTableRef: React.RefObject<any>;
  yedeksemptomoneritranslationlist_178141_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "6fe9bf63-eac0-45a7-b63d-d4c91bf9493e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 218538, PropertyName: "value", Value: "Sempton Translation" },
        { Id: 435034, PropertyName: "value", Value: "Tanım Translations" },
        { Id: 622426, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 843165, PropertyName: "title", Value: "Language" },
        { Id: 789855, PropertyName: "value", Value: "TR" },
        { Id: 994912, PropertyName: "title", Value: "Translation" },
        { Id: 994832, PropertyName: "value", Value: "Lorem ipsum dolor sit amet." },
        { Id: 986358, PropertyName: "value", Value: "Sebep Translations" },
        { Id: 409349, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 498198, PropertyName: "title", Value: "Language" },
        { Id: 328146, PropertyName: "value", Value: "TR" },
        { Id: 241272, PropertyName: "title", Value: "Translation" },
        { Id: 565529, PropertyName: "value", Value: "Lorem ipsum dolor sit amet." },
        { Id: 76962, PropertyName: "value", Value: "Bakım Önerisi Translations" },
        { Id: 360380, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 817854, PropertyName: "title", Value: "Language" },
        { Id: 866032, PropertyName: "value", Value: "TR" },
        { Id: 558071, PropertyName: "title", Value: "Translation" },
        { Id: 32740, PropertyName: "value", Value: "Lorem ipsum dolor sit amet." },
        { Id: 787377, PropertyName: "value", Value: "Ne Zaman Aranmalı Translations" },
        { Id: 178141, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 634369, PropertyName: "title", Value: "Language" },
        { Id: 782533, PropertyName: "value", Value: "TR" },
        { Id: 739018, PropertyName: "title", Value: "Translation" },
        { Id: 695877, PropertyName: "value", Value: "Lorem ipsum dolor sit amet." }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.yedeksemptomoneritranslationlist_622426_value_kuikaTableRef = React.createRef();
    this.yedeksemptomoneritranslationlist_409349_value_kuikaTableRef = React.createRef();
    this.yedeksemptomoneritranslationlist_360380_value_kuikaTableRef = React.createRef();
    this.yedeksemptomoneritranslationlist_178141_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yedeksemptomoneritranslationlist", "yedeksemptomoneritranslationlist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("yedeksemptomoneritranslationlist", "yedeksemptomoneritranslationlist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yedeksemptomoneritranslationlist", "yedeksemptomoneritranslationlist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
