import { FormInstance } from "antd/lib/form";
import React from "react";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IYedekTaramaTestiTranslation_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IYedekTaramaTestiTranslation_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
}

export class YedekTaramaTestiTranslation_ScreenBase extends React.PureComponent<
  IYedekTaramaTestiTranslation_ScreenProps,
  any
> {
  yedektaramatestitranslation_12879_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c8d1dce6-2bbb-4ae2-b15d-f8f77a9975a5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 672323, PropertyName: "value", Value: "Tarama Testi Detay" },
        { Id: 76751, PropertyName: "value", Value: "Kan Testi" },
        { Id: 651101, PropertyName: "value", Value: "Uygulama Zamanı" },
        { Id: 802135, PropertyName: "value", Value: "25.10.2023" },
        { Id: 234861, PropertyName: "value", Value: "Translations" },
        { Id: 12879, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 116963, PropertyName: "title", Value: "Language" },
        { Id: 344513, PropertyName: "value", Value: "TR" },
        { Id: 811838, PropertyName: "title", Value: "Translation" },
        { Id: 798812, PropertyName: "value", Value: "Kan testi" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.yedektaramatestitranslation_12879_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("yedektaramatestitranslation", "yedektaramatestitranslation");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
    }

    KuikaAppManager.calculateAndSetBodyHeight("yedektaramatestitranslation", "yedektaramatestitranslation");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("yedektaramatestitranslation", "yedektaramatestitranslation");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
