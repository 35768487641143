import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePage_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePage_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GetLanguage: string;
  GetLanguage_dummy: string;
  KullaniciTopTen: any[];
  KullaniciTopTen_dummy: any[];
  isComp395899Visible: "visible" | "hidden";
}

export class HomePage_ScreenBase extends React.PureComponent<IHomePage_ScreenProps, any> {
  homepage_369064_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "8f2d6eba-ecee-425a-86b2-f315f5e1095c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 704656, PropertyName: "value", Value: "Dashboard" },
        { Id: 708395, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 155055, PropertyName: "label", Value: "Profil" },
        { Id: 747954, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 553760, PropertyName: "value", Value: "Danışanlar" },
        { Id: 858900, PropertyName: "value", Value: "121" },
        { Id: 969855, PropertyName: "value", Value: "Uzmanlar" },
        { Id: 643962, PropertyName: "value", Value: "35" },
        { Id: 594830, PropertyName: "value", Value: "Kurumsallar" },
        { Id: 815313, PropertyName: "value", Value: "11" },
        { Id: 25249, PropertyName: "value", Value: "Son Eklenen Kullanıcılar" },
        { Id: 369064, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 962277, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 730504, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 268197, PropertyName: "title", Value: "Kullanıcı Tipi" },
        { Id: 77993, PropertyName: "value", Value: "Danışan" },
        { Id: 123499, PropertyName: "value", Value: "Uzman" },
        { Id: 395899, PropertyName: "value", Value: "Kurumsal" },
        { Id: 406534, PropertyName: "title", Value: "Email" },
        { Id: 437306, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 848116, PropertyName: "title", Value: "Telefon" },
        { Id: 602350, PropertyName: "value", Value: "[datafield:telefon]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.homepage_369064_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GetLanguage: "",
      KullaniciTopTen: [],
      isComp395899Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.HomePagePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepage", "homepage");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  HomePagePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePagePageInit1_();
    });

    return isErrorOccurred;
  };
  HomePagePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciTopTen = result?.data.kullaniciTopTen;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePagePageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_369064 = this.state.KullaniciTopTen;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  HomePageComponent_325667_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "HomePage",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "562893",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  HomePageComponent_831602_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "homepage_369064_value", "kullaniciTipi"),
        "Uzman"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "UzmanProfil",
        "UzmanID",
        ReactSystemFunctions.value(this, "homepage_369064_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "UzmanProfil",
        "CurrentLang",
        ReactSystemFunctions.toString(this, this.state.GetLanguage)
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.HomePageComponent_831602_onClick1_,
        "HomePage",
        "UzmanProfil",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.HomePageComponent_831602_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  HomePageComponent_831602_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "homepage_369064_value", "kullaniciTipi"),
        "Uzman"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "DanisanProfil",
        "DanisanID",
        ReactSystemFunctions.value(this, "homepage_369064_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "DanisanProfil",
        "CurrentLang",
        ReactSystemFunctions.toString(this, this.state.GetLanguage)
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "HomePage",
        "DanisanProfil",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [562893, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.HomePagePageInit();
    }
  }
}
