import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IVucutBolgesiList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IVucutBolgesiList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  VBTranslationByLang: any[];
  VBTranslationByLang_dummy: any[];
  VBTranslationCount: any[];
  VBTranslationCount_dummy: any[];
  VBTranslationDeleteByVucutBolgesiId: number;
  VBTranslationDeleteByVucutBolgesiId_dummy: number;
}

export class VucutBolgesiList_ScreenBase extends React.PureComponent<IVucutBolgesiList_ScreenProps, any> {
  vucutbolgesilist_755660_value_kuikaTableRef: React.RefObject<any>;
  vucutbolgesilist_692036_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "832bd504-3dca-407c-b898-f22ea4d9f882",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "babc4ff6-aafb-42d3-b9ad-be6958483ba1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 825714, PropertyName: "value", Value: "Vücut Bölgesi" },
        { Id: 180891, PropertyName: "value", Value: "Simay Bornova" },
        { Id: 300165, PropertyName: "label", Value: "Profil" },
        { Id: 521691, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 2755, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 718191, PropertyName: "label", Value: "Yeni Vücut Bölgesi Ekle" },
        { Id: 755660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 455307, PropertyName: "title", Value: "Bölge Adı" },
        { Id: 371259, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 544738, PropertyName: "value", Value: "Toplam:" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.vucutbolgesilist_755660_value_kuikaTableRef = React.createRef();
    this.vucutbolgesilist_692036_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      VBTranslationByLang: [],
      VBTranslationCount: [],
      VBTranslationDeleteByVucutBolgesiId: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesilist", "vucutbolgesilist");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.VucutBolgesiListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesilist", "vucutbolgesilist");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("vucutbolgesilist", "vucutbolgesilist");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.VucutBolgesiListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  VucutBolgesiListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "VucutBolgesiList/VucutBolgesiListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationByLang = result?.data.vbTranslationByLang;

    stateVars.VBTranslationCount = result?.data.vbTranslationCount;
    formVars.vucutbolgesilist_333497_value = ReactSystemFunctions.toString(
      this,
      stateVars.VBTranslationCount?.length > 0 ? stateVars.VBTranslationCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VucutBolgesiListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.VBTranslationByLang;
    formVars.vucutbolgesilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.VBTranslationCount?.length > 0 ? this.state.VBTranslationCount[0]?.count : null
    );

    formVars.vucutbolgesilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.VBTranslationCount?.length > 0 ? this.state.VBTranslationCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  VucutBolgesiListComponent_310897_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VucutBolgesiList",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "343833",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiListComponent_718191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "VucutBolgeID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "WaitFor", "New");
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "VBTranslationID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("VucutBolgesiEkle", "CurrentLang", "tr_TR");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VucutBolgesiList",
      "VucutBolgesiEkle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "909462",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiListComponent_659061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "VucutBolgesiTransList",
      "VucutBolgeID",
      ReactSystemFunctions.value(this, "vucutbolgesilist_755660_value", "vucutBolgesiId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "VucutBolgesiList",
      "VucutBolgesiTransList",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiListComponent_203570_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      VucutBolgesiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "vucutbolgesilist_755660_value", "vucutBolgesiId"),
        "Guid"
      ),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      Lang_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3b3d92af_54c1_08b1_b2c7_2ad22a38ecb6_confirmation",
        this.defaultML,
        "Bu vücut bölgesi ve onun tüm translation bilgileri silinecek emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "VucutBolgesiList/VucutBolgesiListComponent_203570_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.VBTranslationDeleteByVucutBolgesiId = result?.data.vbTranslationDeleteByVucutBolgesiId;
        stateVars.VBTranslationByLang = result?.data.vbTranslationByLang;

        stateVars.VBTranslationCount = result?.data.vbTranslationCount;
        formVars.vucutbolgesilist_333497_value = ReactSystemFunctions.toString(
          this,
          stateVars.VBTranslationCount?.length > 0 ? stateVars.VBTranslationCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.VucutBolgesiListComponent_203570_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiListComponent_203570_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.VBTranslationByLang;
    formVars.vucutbolgesilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.VBTranslationCount?.length > 0 ? this.state.VBTranslationCount[0]?.count : null
    );

    formVars.vucutbolgesilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.VBTranslationCount?.length > 0 ? this.state.VBTranslationCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  VucutBolgesiListComponent_692036_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      Lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "VucutBolgesiList/VucutBolgesiListComponent_692036_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VBTranslationByLang = result?.data.vbTranslationByLang;

    stateVars.VBTranslationCount = result?.data.vbTranslationCount;
    formVars.vucutbolgesilist_333497_value = ReactSystemFunctions.toString(
      this,
      stateVars.VBTranslationCount?.length > 0 ? stateVars.VBTranslationCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.VucutBolgesiListComponent_692036_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  VucutBolgesiListComponent_692036_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_755660 = this.state.VBTranslationByLang;
    formVars.vucutbolgesilist_333497_value = ReactSystemFunctions.toString(
      this,
      this.state.VBTranslationCount?.length > 0 ? this.state.VBTranslationCount[0]?.count : null
    );

    formVars.vucutbolgesilist_692036_total = ReactSystemFunctions.value(
      this,
      this.state.VBTranslationCount?.length > 0 ? this.state.VBTranslationCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [343833, 909462] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.VucutBolgesiListPageInit();
    }
  }
}
